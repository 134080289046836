// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TopBar$CTEM from "../Components/TopBar.bs.js";
import * as Loading$CTEM from "../Components/Loading.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as DataTypes$CTEM from "../DataTypes.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ModalError$CTEM from "../Components/ModalError.bs.js";
import * as ModalDialog$CTEM from "../Components/ModalDialog.bs.js";
import * as ModelHeader$CTEM from "../Components/ModelTemplates/ModelHeader.bs.js";
import * as MaterialUI_Button from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Button.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as RequestUtils$CTEM from "../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as ModelsContext$CTEM from "../Contexts/ModelsContext.bs.js";
import * as RestoreButton$CTEM from "../Components/RestoreButton.bs.js";
import * as CtemAutoComplete$CTEM from "../Components/CtemAutoComplete.bs.js";
import Grid from "@material-ui/core/Grid";
import * as SavedWorksContext$CTEM from "../Contexts/SavedWorksContext.bs.js";
import Button from "@material-ui/core/Button";
import * as CurrencyExchangeRatesContext$CTEM from "../Contexts/CurrencyExchangeRatesContext.bs.js";
import CircularProgress from "@material-ui/core/CircularProgress";

function CreateModelPage(Props) {
  var modelTemplateID = Props.modelTemplateID;
  var match = ModelsContext$CTEM.useModelsResults(undefined);
  var loadModels = match[1];
  var modelsContext = match[0];
  var match$1 = SavedWorksContext$CTEM.useSavedWorksResults(undefined);
  var fetchData = match$1.fetchData;
  var match$2 = CurrencyExchangeRatesContext$CTEM.useExchangeRatesResults(undefined);
  var fetchExchangeRates = match$2.fetchData;
  var exchangeRateResults = match$2.value;
  var match$3 = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* SetSelectedModel */0 :
                return {
                        selectedModel: action._0,
                        selectedCountry: state.selectedCountry,
                        createNewFileResult: state.createNewFileResult
                      };
            case /* SetSelectedCountry */1 :
                return {
                        selectedModel: state.selectedModel,
                        selectedCountry: action._0,
                        createNewFileResult: state.createNewFileResult
                      };
            case /* SetCreateNewFileResults */2 :
                return {
                        selectedModel: state.selectedModel,
                        selectedCountry: state.selectedCountry,
                        createNewFileResult: action._0
                      };
            
          }
        }), {
        selectedModel: undefined,
        selectedCountry: undefined,
        createNewFileResult: /* NotLoaded */0
      });
  var dispatch = match$3[1];
  var state = match$3[0];
  var requestCreateNewFile = function (exchangeRate, model, country) {
    var match = model.modelType;
    var url = UrlPath$CTEM.internalUrl(undefined, match ? UrlPath$CTEM.createWorkAPIPath(false, exchangeRate, model.id, country.code) : UrlPath$CTEM.createWorkAPIPath(undefined, undefined, model.id, country.code));
    RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Post */2, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), url, (function (json) {
            Curry._1(fetchData, true);
            var createdModel = DataTypes$CTEM.Decode.work(json);
            return ReasonReactRouter.push(UrlPath$CTEM.editWorkPath(createdModel.id));
          }), (function (json) {
            return Curry._1(dispatch, {
                        TAG: /* SetCreateNewFileResults */2,
                        _0: {
                          TAG: /* LoadFailed */1,
                          _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                        }
                      });
          }));
    
  };
  var handleCreateImbruvicaNewFile = function (rates, model, country, fetchCurrencyFailed) {
    var exchangeRate = Utils$CTEM.Currency.getRateTargetCurrencyByContryObject(rates, country);
    if (exchangeRate !== undefined) {
      return requestCreateNewFile(exchangeRate, model, country);
    } else {
      return Curry._1(fetchCurrencyFailed, undefined);
    }
  };
  React.useEffect((function () {
          Curry._1(loadModels, undefined);
          if (modelTemplateID === /* IMBRUVICA */1) {
            Curry._2(fetchExchangeRates, undefined, undefined);
          }
          
        }), []);
  React.useEffect((function () {
          var match = state.selectedModel;
          if (match === undefined && typeof modelsContext !== "number" && modelsContext.TAG === /* LoadSuccess */0) {
            var models = modelsContext._0;
            if (Belt_List.length(models) > 0) {
              Curry._1(dispatch, {
                    TAG: /* SetSelectedModel */0,
                    _0: Belt_List.get(Belt_List.keep(models, (function (param) {
                                return param.modelType === modelTemplateID;
                              })), 0)
                  });
            }
            
          }
          
        }), [modelsContext]);
  var subHeaderEl = function (param) {
    if (param.modelType) {
      return React.createElement(React.Fragment, {
                  children: React.createElement("div", {
                        style: {
                          display: "inline-block"
                        }
                      }, React.createElement("h3", undefined, "Illustration of Imbruvica Cost-consequences analysis"))
                });
    } else {
      return React.createElement(React.Fragment, {
                  children: null
                }, React.createElement("div", {
                      style: {
                        display: "inline-block"
                      }
                    }, React.createElement("h3", {
                          style: {
                            color: "#C2592B"
                          }
                        }, "Illustration of Economic Impact When Introducing")), React.createElement("div", {
                      style: {
                        display: "inline-block",
                        marginLeft: "10px"
                      }
                    }, React.createElement("h3", {
                          style: {
                            color: "#60A7CF"
                          }
                        }, "LAIs")), React.createElement("div", {
                      style: {
                        display: "inline-block",
                        marginLeft: "5px"
                      }
                    }, React.createElement(RestoreButton$CTEM.make, {
                          dynamicTooltipPosition: false,
                          tooltipClassName: " tooltip-infos-left",
                          mode: /* Infos */{
                            _0: "LAIs in this model include \n Paliperidone Palmitate 1-monthly (PP1M; Invega Sustenna)\n        and Paliperidone Plamitate 3-monthly (PP3M; Invega Trinza) only."
                          }
                        })));
    }
  };
  var match$4 = state.selectedModel;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (typeof modelsContext === "number" && modelsContext !== 0) {
    exit = 2;
  } else {
    exit$1 = 3;
  }
  if (exit$1 === 3) {
    exit = typeof exchangeRateResults === "number" && exchangeRateResults !== 0 ? 2 : 1;
  }
  switch (exit) {
    case 1 :
        if (match$4 !== undefined) {
          var model = state.selectedModel;
          tmp = React.createElement("div", {
                className: "cover-page" + (
                  modelTemplateID === /* IMBRUVICA */1 ? " cover-page-imb" : ""
                )
              }, React.createElement("div", {
                    className: "template-lai" + (
                      modelTemplateID === /* IMBRUVICA */1 ? " template-imb" : ""
                    )
                  }, React.createElement("div", {
                        className: "slide-content"
                      }, React.createElement("div", {
                            className: "title main-title"
                          }, React.createElement(ModelHeader$CTEM.make, {
                                title: match$4.name
                              })))), React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                        undefined,
                        undefined,
                        Caml_option.some(MaterialUIDataType.AlignItems.center),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        "cover-content-row",
                        Caml_option.some(null),
                        undefined,
                        undefined
                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            "text-center",
                            Caml_option.some(React.createElement("div", {
                                      className: "intro-detail",
                                      style: {
                                        paddingTop: "2%"
                                      }
                                    }, subHeaderEl(match$4), React.createElement("div", {
                                          className: "text-left",
                                          style: {
                                            padding: "0px 85px"
                                          },
                                          dangerouslySetInnerHTML: {
                                            __html: match$4.description
                                          }
                                        }))),
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                          undefined,
                                          undefined,
                                          Caml_option.some(MaterialUIDataType.AlignItems.center),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          Caml_option.some(null),
                                          undefined,
                                          undefined
                                        ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                              undefined,
                                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              Caml_option.some(model !== undefined ? React.createElement(CtemAutoComplete$CTEM.make, {
                                                          label: "Select Country",
                                                          listItem: List.map((function (c) {
                                                                  return c.name;
                                                                }), model.countries),
                                                          onChange: (function (selectedCountry) {
                                                              var c = List.find_opt((function (c) {
                                                                      return c.name === selectedCountry;
                                                                    }), model.countries);
                                                              if (c !== undefined) {
                                                                return Curry._1(dispatch, {
                                                                            TAG: /* SetSelectedCountry */1,
                                                                            _0: c
                                                                          });
                                                              } else {
                                                                return Curry._1(dispatch, {
                                                                            TAG: /* SetSelectedCountry */1,
                                                                            _0: undefined
                                                                          });
                                                              }
                                                            })
                                                        }) : React.createElement(CtemAutoComplete$CTEM.make, {
                                                          label: "Select Country",
                                                          listItem: /* [] */0,
                                                          disabled: true,
                                                          onChange: (function (param) {
                                                              
                                                            })
                                                        })),
                                              undefined,
                                              undefined
                                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                              undefined,
                                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                              undefined,
                                              undefined,
                                              undefined,
                                              "text-center",
                                              Caml_option.some(React.createElement(Button, {
                                                        children: "Start Model",
                                                        className: "btn-solid-color1",
                                                        disabled: Belt_Option.isNone(state.selectedModel) || Belt_Option.isNone(state.selectedCountry),
                                                        variant: MaterialUI_Button.Variant.contained,
                                                        onClick: (function (param) {
                                                            var match = state.selectedModel;
                                                            var match$1 = state.selectedCountry;
                                                            if (match !== undefined && match$1 !== undefined) {
                                                              Curry._1(dispatch, {
                                                                    TAG: /* SetCreateNewFileResults */2,
                                                                    _0: /* Loading */1
                                                                  });
                                                              var fetchCurrencyFailed = function (param) {
                                                                return Curry._1(dispatch, {
                                                                            TAG: /* SetCreateNewFileResults */2,
                                                                            _0: {
                                                                              TAG: /* LoadFailed */1,
                                                                              _0: "Can not fetch currency exchange rates, please try again"
                                                                            }
                                                                          });
                                                              };
                                                              var match$2 = match.modelType;
                                                              if (!match$2) {
                                                                return requestCreateNewFile(undefined, match, match$1);
                                                              }
                                                              if (typeof exchangeRateResults !== "number" && exchangeRateResults.TAG === /* LoadSuccess */0) {
                                                                return handleCreateImbruvicaNewFile(exchangeRateResults._0.rates, match, match$1, fetchCurrencyFailed);
                                                              }
                                                              return Curry._2(fetchExchangeRates, (function (exchangeRatesOpt) {
                                                                            if (exchangeRatesOpt !== undefined) {
                                                                              return handleCreateImbruvicaNewFile(exchangeRatesOpt.rates, match, match$1, fetchCurrencyFailed);
                                                                            } else {
                                                                              return Curry._1(dispatch, {
                                                                                          TAG: /* SetCreateNewFileResults */2,
                                                                                          _0: {
                                                                                            TAG: /* LoadFailed */1,
                                                                                            _0: "Can not fetch currency exchange rates, please try again"
                                                                                          }
                                                                                        });
                                                                            }
                                                                          }), undefined);
                                                            }
                                                            
                                                          })
                                                      })),
                                              undefined,
                                              undefined
                                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                              undefined,
                                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined
                                            ])))),
                            undefined,
                            undefined
                          ]))));
        } else {
          tmp = typeof modelsContext === "number" || modelsContext.TAG === /* LoadSuccess */0 ? null : React.createElement("p", {
                  style: {
                    padding: "100px",
                    textAlign: "start",
                    width: "100%"
                  }
                }, Utils$CTEM.textWithNewLine(modelsContext._0));
        }
        break;
    case 2 :
        tmp = React.createElement("div", {
              style: {
                height: "100%",
                padding: "100px",
                width: "100%"
              }
            }, React.createElement(Loading$CTEM.make, {}));
        break;
    
  }
  var msg = state.createNewFileResult;
  var tmp$1;
  tmp$1 = typeof msg === "number" ? (
      msg === /* NotLoaded */0 ? null : React.createElement(ModalDialog$CTEM.make, {
              onHandleClose: (function (param) {
                  
                }),
              children: React.createElement(CircularProgress, {
                    color: MaterialUIDataType.MainWithInheritColor.inherit_
                  })
            })
    ) : (
      msg.TAG === /* LoadSuccess */0 ? null : React.createElement(ModalError$CTEM.make, {
              onHandleClose: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* SetCreateNewFileResults */2,
                              _0: /* NotLoaded */0
                            });
                }),
              msg: msg._0
            })
    );
  return React.createElement("div", {
              className: "dashboard-page"
            }, React.createElement(TopBar$CTEM.make, {
                  mode: /* Presentation */{
                    _0: false
                  },
                  linkText: " < Cancel",
                  urlPath: UrlPath$CTEM.Route.getMemberPath({
                        TAG: /* Dashboard */0,
                        _0: modelTemplateID
                      }),
                  title: "New Model"
                }), tmp, tmp$1);
}

var make = CreateModelPage;

export {
  make ,
  
}
/* react Not a pure module */
