// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TextInput$CTEM from "../../../TextInput.bs.js";
import * as FloatInput$CTEM from "../../../FloatInput.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as RestoreButton$CTEM from "../../../RestoreButton.bs.js";
import * as InputComponent$CTEM from "../../../InputComponent.bs.js";
import * as SelectTextInput$CTEM from "../../../SelectTextInput.bs.js";
import Grid from "@material-ui/core/Grid";

function LAIInputForm$TextInputForm(Props) {
  var title = Props.title;
  var inputDisabled = Props.inputDisabled;
  var onTitleUpdate = Props.onTitleUpdate;
  var onTitleRestore = Props.onTitleRestore;
  var infoTitleMode = Props.infoTitleMode;
  var titlePlaceholderOpt = Props.titlePlaceholder;
  var tooltipClassNameOpt = Props.tooltipClassName;
  var titlePlaceholder = titlePlaceholderOpt !== undefined ? titlePlaceholderOpt : "";
  var tooltipClassName = tooltipClassNameOpt !== undefined ? tooltipClassNameOpt : "";
  var tmp = {
    value: title,
    className: "title-input",
    placeholder: titlePlaceholder,
    fullWidth: false,
    disabled: inputDisabled
  };
  if (onTitleUpdate !== undefined) {
    tmp.onValueUpdate = Caml_option.valFromOption(onTitleUpdate);
  }
  var tmp$1;
  if (infoTitleMode !== undefined) {
    var tmp$2 = {
      dynamicTooltipPosition: tooltipClassName === "",
      tooltipClassName: tooltipClassName,
      mode: infoTitleMode
    };
    if (onTitleRestore !== undefined) {
      tmp$2.onRestore = Caml_option.valFromOption(onTitleRestore);
    }
    tmp$1 = React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
              undefined,
              Caml_option.some(MaterialUIDataType.GridSize.size(2)),
              undefined,
              undefined,
              undefined,
              "title-input-info last-col text-center",
              Caml_option.some(React.createElement(RestoreButton$CTEM.make, tmp$2)),
              undefined,
              undefined
            ]));
  } else {
    tmp$1 = null;
  }
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(10)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(TextInput$CTEM.make, tmp)),
                      undefined,
                      undefined
                    ])), tmp$1);
}

var TextInputForm = {
  make: LAIInputForm$TextInputForm
};

function LAIInputForm(Props) {
  var inputModeOpt = Props.inputMode;
  var floatInputDisplayType = Props.floatInputDisplayType;
  var labelAsShortNumber = Props.labelAsShortNumber;
  var title = Props.title;
  var titlePlaceholderOpt = Props.titlePlaceholder;
  var titleWeightOpt = Props.titleWeight;
  var subTitleOpt = Props.subTitle;
  var tooltipClassNameOpt = Props.tooltipClassName;
  var wrapperClassNameOpt = Props.wrapperClassName;
  var unit = Props.unit;
  var currency = Props.currency;
  var inputDisabledOpt = Props.inputDisabled;
  var digitOpt = Props.digit;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onValueUpdate = Props.onValueUpdate;
  var onTextUpdate = Props.onTextUpdate;
  var onTitleUpdate = Props.onTitleUpdate;
  var onTitleRestore = Props.onTitleRestore;
  var infoTitleMode = Props.infoTitleMode;
  var infoMode = Props.infoMode;
  var onRestore = Props.onRestore;
  var defaultValue = Props.defaultValue;
  var errorOpt = Props.error;
  var inputMode = inputModeOpt !== undefined ? inputModeOpt : ({
        TAG: /* TextInput */0,
        _0: ""
      });
  var titlePlaceholder = titlePlaceholderOpt !== undefined ? titlePlaceholderOpt : "";
  var titleWeight = titleWeightOpt !== undefined ? titleWeightOpt : "bold";
  var subTitle = subTitleOpt !== undefined ? subTitleOpt : "";
  var tooltipClassName = tooltipClassNameOpt !== undefined ? tooltipClassNameOpt : "";
  var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : "";
  var inputDisabled = inputDisabledOpt !== undefined ? inputDisabledOpt : false;
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var error = errorOpt !== undefined ? errorOpt : false;
  var match;
  var exit = 0;
  if (title === "" && subTitle === "") {
    switch (inputMode.TAG | 0) {
      case /* TextInput */0 :
      case /* FloatInput */1 :
          exit = 2;
          break;
      case /* FloatInputWithTitle */2 :
      case /* SelectInput */3 :
          exit = 1;
          break;
      
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        var match$1 = floatInputDisplayType !== undefined && floatInputDisplayType && floatInputDisplayType._0 ? [
            3,
            7
          ] : [
            7,
            3
          ];
        var tmp;
        if (inputMode.TAG === /* FloatInputWithTitle */2) {
          var tmp$1 = {
            title: title,
            inputDisabled: inputDisabled,
            titlePlaceholder: titlePlaceholder,
            tooltipClassName: tooltipClassName
          };
          if (onTitleUpdate !== undefined) {
            tmp$1.onTitleUpdate = Caml_option.valFromOption(onTitleUpdate);
          }
          if (onTitleRestore !== undefined) {
            tmp$1.onTitleRestore = Caml_option.valFromOption(onTitleRestore);
          }
          if (infoTitleMode !== undefined) {
            tmp$1.infoTitleMode = Caml_option.valFromOption(infoTitleMode);
          }
          tmp = React.createElement(LAIInputForm$TextInputForm, tmp$1);
        } else {
          tmp = React.createElement(React.Fragment, {
                children: null
              }, React.createElement("p", {
                    className: "input-form-title",
                    style: {
                      fontWeight: titleWeight
                    }
                  }, Utils$CTEM.textWithNewLine(title)), React.createElement("p", {
                    className: "input-form-subtitle"
                  }, Utils$CTEM.textWithNewLine(subTitle)));
        }
        match = [
          match$1[1],
          2,
          React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                    undefined,
                    Caml_option.some(MaterialUIDataType.GridSize.size(match$1[0])),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    Caml_option.some(tmp),
                    undefined,
                    undefined
                  ]))
        ];
        break;
    case 2 :
        match = [
          6,
          4,
          React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                    undefined,
                    Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                  ]))
        ];
        break;
    
  }
  var tmp$2;
  var exit$1 = 0;
  switch (inputMode.TAG | 0) {
    case /* TextInput */0 :
        var tmp$3 = {
          mode: /* ModifyData */1,
          fullWidth: false,
          value: inputMode._0,
          disabled: inputDisabled
        };
        if (onChange !== undefined) {
          tmp$3.onChange = Caml_option.valFromOption(onChange);
        }
        if (onBlur !== undefined) {
          tmp$3.onBlur = Caml_option.valFromOption(onBlur);
        }
        if (defaultValue !== undefined) {
          tmp$3.defaultValue = Caml_option.valFromOption(defaultValue);
        }
        tmp$2 = React.createElement(InputComponent$CTEM.make, tmp$3);
        break;
    case /* FloatInput */1 :
    case /* FloatInputWithTitle */2 :
        exit$1 = 1;
        break;
    case /* SelectInput */3 :
        var tmp$4 = {
          selectOptions: inputMode._0,
          disabled: inputDisabled,
          error: error
        };
        if (onTextUpdate !== undefined) {
          tmp$4.onChange = Caml_option.valFromOption(onTextUpdate);
        }
        tmp$2 = React.createElement(SelectTextInput$CTEM.make, tmp$4);
        break;
    
  }
  if (exit$1 === 1) {
    var tmp$5 = {
      value: inputMode._0,
      disabled: inputDisabled,
      digit: digit,
      error: error
    };
    if (floatInputDisplayType !== undefined) {
      tmp$5.displayType = Caml_option.valFromOption(floatInputDisplayType);
    }
    if (onValueUpdate !== undefined) {
      tmp$5.onValueUpdate = Caml_option.valFromOption(onValueUpdate);
    }
    if (onTextUpdate !== undefined) {
      tmp$5.onTextUpdate = Caml_option.valFromOption(onTextUpdate);
    }
    if (currency !== undefined) {
      tmp$5.currency = Caml_option.valFromOption(currency);
    }
    if (labelAsShortNumber !== undefined) {
      tmp$5.labelAsShortNumber = Caml_option.valFromOption(labelAsShortNumber);
    }
    tmp$2 = React.createElement(FloatInput$CTEM.make, tmp$5);
  }
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "edit-input-form " + wrapperClassName,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), match[2], React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(match[0])),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(tmp$2),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(match[1])),
                      undefined,
                      undefined,
                      undefined,
                      "last-col text-center",
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement("p", {
                      className: "input-form-unit"
                    }, unit), React.createElement(RestoreButton$CTEM.make, {
                      dynamicTooltipPosition: tooltipClassName === "",
                      tooltipClassName: tooltipClassName,
                      mode: infoMode,
                      onRestore: onRestore
                    })));
}

var make = LAIInputForm;

export {
  TextInputForm ,
  make ,
  
}
/* react Not a pure module */
