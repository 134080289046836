// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";

function SectionBox$ChildBoxWithTitle(Props) {
  var titleOpt = Props.title;
  var classNameOpt = Props.className;
  var gridSizeOpt = Props.gridSize;
  var children = Props.children;
  var title = titleOpt !== undefined ? titleOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "width-edit-form";
  var gridSize = gridSizeOpt !== undefined ? gridSizeOpt : 6;
  var editFormSpeicalClass = gridSize === 6 ? "width-edit-form " : " ";
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(gridSize)),
                  undefined,
                  undefined,
                  undefined,
                  "bg-edit-form-1 " + (editFormSpeicalClass + className),
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), title !== "" ? React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        Caml_option.some(React.createElement("h3", undefined, title)),
                        undefined,
                        undefined
                      ])) : null, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(children),
                      undefined,
                      undefined
                    ])));
}

var ChildBoxWithTitle = {
  make: SectionBox$ChildBoxWithTitle
};

function SectionBox(Props) {
  var title = Props.title;
  var subtitle = Props.subtitle;
  var classNameOpt = Props.className;
  var wrapperChildrenclassNameOpt = Props.wrapperChildrenclassName;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var wrapperChildrenclassName = wrapperChildrenclassNameOpt !== undefined ? wrapperChildrenclassNameOpt : "";
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  className,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), title !== "" || Belt_Option.isSome(subtitle) ? React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                        undefined,
                        Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        Caml_option.some(null),
                        undefined,
                        undefined
                      ]), title !== "" ? React.createElement("h3", undefined, title) : null, Belt_Option.mapWithDefault(subtitle, null, (function (str) {
                          return React.createElement("h4", undefined, str);
                        }))) : null, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      wrapperChildrenclassName,
                      Caml_option.some(children),
                      undefined,
                      undefined
                    ])));
}

var make = SectionBox;

export {
  ChildBoxWithTitle ,
  make ,
  
}
/* react Not a pure module */
