// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apis$CTEM from "../../../../Effects/Apis.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Encoder$CTEM from "../../../../Effects/Encoder.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as SectionBox$CTEM from "../../SectionBox.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as TemplateUtils$CTEM from "../../TemplateUtils.bs.js";
import Box from "@material-ui/core/Box";
import * as ModelInputHelper$CTEM from "../../../../Effects/ModelInputHelper.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";

function createSumInputEl(param) {
  var func = function (param$1, param$2, param$3, param$4, param$5, param$6) {
    return ModelInputHelper$CTEM.createSumInputEl(param, param$1, param$2, param$3, param$4, param$5, param$6);
  };
  return function (param, param$1, param$2) {
    return Curry._4(func, param, param$1, param$2, false);
  };
}

var getTreatmentName = TemplateUtils$CTEM.Imbruvica.getTreatmentName;

function getLossIncome(daysInMonth, monthlyIncome) {
  return Utils$CTEM.roundedFloat(0, daysInMonth / 30 * monthlyIncome);
}

function getPatientCareGiverLossIncome(patientMonthlyIncome, caregiverMonthlyIncome, param) {
  var indirectCost = param.indirectCost;
  return [
          getLossIncome(indirectCost.patientReducedWorkdays.value, patientMonthlyIncome.value),
          getLossIncome(indirectCost.caregiverReducedWorkdays.value, caregiverMonthlyIncome.value)
        ];
}

function IMBRUVICA_IndirectCost(Props) {
  var work = Props.work;
  var currentData = Props.currentData;
  var defaultData = Props.defaultData;
  var exchangeRate = Props.exchangeRate;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useState(function () {
        return /* NotLoaded */0;
      });
  var setApiState = match$1[1];
  var currency = work.currency;
  var requestUpdateData = function (normalInputs) {
    Curry._1(setApiState, (function (param) {
            return /* Loading */1;
          }));
    return Apis$CTEM.requestUpdateSavedWorkNormalInput(work.id, normalInputs, (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadSuccess */2;
                              }));
                }), (function (json) {
                  var errMsg = RequestUtils$CTEM.getResponseMsgFromJson(json);
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: errMsg
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadFailed */{
                                        _0: errMsg
                                      };
                              }));
                }));
  };
  var inputDisabled = match$1[0] === /* Loading */1;
  var arg = work.currency.code;
  var arg$1 = work.currency.code;
  var arg$2 = exchangeRate;
  var createFloatInput = function (param) {
    return function (param$1) {
      var func = function (param$2, param$3, param$4, param$5, param$6, param$7) {
        return ModelInputHelper$CTEM.createFloatInputEl(param, inputDisabled, param$1, arg, arg$1, param$2, param$3, param$4, param$5, param$6, param$7);
      };
      return function (param) {
        return Curry._2(func, param, arg$2);
      };
    };
  };
  var onValueUpdate = function (encoder, value) {
    return requestUpdateData({
                hd: Curry._1(encoder, value),
                tl: /* [] */0
              });
  };
  return React.createElement(Box, {
              className: "height-100",
              alignItems: MaterialUIDataType.AlignItems.center,
              children: React.createElement(SectionBox$CTEM.make, {
                    title: "",
                    subtitle: "Loss of monthly income per patient (or caregiver) = (reduced workdays in a month / 30) x monthly income",
                    children: null
                  }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
                                      title: "",
                                      children: [
                                        Curry._5(createFloatInput("imb-indirect-monthly-inocomepatientMonthlyIncome")(function (param) {
                                                  return onValueUpdate(Encoder$CTEM.Imbruvica.IndirectCost.patientMonthlyIncome, param);
                                                }), undefined, undefined, undefined, defaultData.patientMonthlyIncome, currentData.patientMonthlyIncome),
                                        Curry._5(createFloatInput("imb-indirect-monthly-inocomecaregiverMonthlyIncome")(function (param) {
                                                  return onValueUpdate(Encoder$CTEM.Imbruvica.IndirectCost.caregiverMonthlyIncome, param);
                                                }), undefined, undefined, undefined, defaultData.caregiverMonthlyIncome, currentData.caregiverMonthlyIncome)
                                      ]
                                    })),
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some($$Array.of_list(Belt_List.keepMap(Belt_List.sort(currentData.treatments, (function (a, b) {
                                                return Caml_primitive.caml_int_compare(a.id, b.id);
                                              })), (function (treatment) {
                                            return Belt_Option.map(Belt_List.getBy(defaultData.treatments, (function (defaultTreatment) {
                                                              return defaultTreatment.id === treatment.id;
                                                            })), (function (defaultTreatment) {
                                                          var indirectCostKey = function (key) {
                                                            return "imb-indirect-cost-" + (key + ("-" + String(treatment.id)));
                                                          };
                                                          var indirectCost = treatment.indirectCost;
                                                          var defaultIndirectCost = defaultTreatment.indirectCost;
                                                          var match = getPatientCareGiverLossIncome(currentData.patientMonthlyIncome, currentData.caregiverMonthlyIncome, treatment);
                                                          var caregiverLossIncome = match[1];
                                                          var patientLossIncome = match[0];
                                                          var onValueUpdate = function (encoder, value) {
                                                            return requestUpdateData({
                                                                        hd: Curry._2(encoder, treatment.id, value),
                                                                        tl: /* [] */0
                                                                      });
                                                          };
                                                          return React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
                                                                      title: getTreatmentName(treatment),
                                                                      className: ModelInputHelper$CTEM.treatmentBgClassName(treatment),
                                                                      children: [
                                                                        ModelInputHelper$CTEM.createIntInputEl(indirectCostKey("patientReducedWorkdays"), inputDisabled, (function (param) {
                                                                                return onValueUpdate(Encoder$CTEM.Imbruvica.IndirectCost.patientReducedWorkdays, param);
                                                                              }), "days", undefined, defaultIndirectCost.patientReducedWorkdays, indirectCost.patientReducedWorkdays),
                                                                        ModelInputHelper$CTEM.createIntInputEl(indirectCostKey("caregiverReducedWorkdays"), inputDisabled, (function (param) {
                                                                                return onValueUpdate(Encoder$CTEM.Imbruvica.IndirectCost.caregiverReducedWorkdays, param);
                                                                              }), "days", undefined, defaultIndirectCost.caregiverReducedWorkdays, indirectCost.caregiverReducedWorkdays),
                                                                        React.createElement("br", {
                                                                              key: indirectCostKey("whitespace-loss-1")
                                                                            }),
                                                                        React.createElement("br", {
                                                                              key: indirectCostKey("whitespace-loss-2")
                                                                            }),
                                                                        Curry._2(createSumInputEl(indirectCostKey("patientLossIncome"))("Loss of monthly income (Patient)", undefined, undefined), currency, patientLossIncome),
                                                                        Curry._2(createSumInputEl(indirectCostKey("caregiverLossIncome"))("Loss of monthly income (caregiver)", undefined, undefined), currency, caregiverLossIncome),
                                                                        React.createElement("br", {
                                                                              key: indirectCostKey("whitespace-cost-sum")
                                                                            }),
                                                                        Curry._2(createSumInputEl(indirectCostKey("cost-sum"))(undefined, undefined, undefined), currency, patientLossIncome + caregiverLossIncome)
                                                                      ],
                                                                      key: "imb-indirect-cost-" + (String(treatment.id) + ("-" + treatment.treatmentName.title))
                                                                    });
                                                        }));
                                          })))),
                            undefined,
                            undefined
                          ])))
            });
}

var createIntInputEl = ModelInputHelper$CTEM.createIntInputEl;

var createFloatInputEl = ModelInputHelper$CTEM.createFloatInputEl;

var bgClassName = ModelInputHelper$CTEM.treatmentBgClassName;

var make = IMBRUVICA_IndirectCost;

export {
  createIntInputEl ,
  createFloatInputEl ,
  createSumInputEl ,
  bgClassName ,
  getTreatmentName ,
  getLossIncome ,
  getPatientCareGiverLossIncome ,
  make ,
  
}
/* react Not a pure module */
