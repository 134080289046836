// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Json_encode from "@glennsl/bs-json/lib/es6/src/Json_encode.bs.js";
import * as DataTypes$CTEM from "../DataTypes.bs.js";

function inputDatasRaw(data) {
  return [
          "normal_input_data",
          data
        ];
}

function inputDatasByListInputs(inputList) {
  return Json_encode.object_({
              hd: [
                "normal_input_data",
                Js_dict.fromList(inputList)
              ],
              tl: /* [] */0
            });
}

function inputDatas(encoder) {
  return Json_encode.object_({
              hd: [
                "normal_input_data",
                encoder
              ],
              tl: /* [] */0
            });
}

function objectDataNameAndValue(name, value) {
  return Json_encode.object_({
              hd: [
                "name",
                name
              ],
              tl: {
                hd: [
                  "value",
                  value
                ],
                tl: /* [] */0
              }
            });
}

function objectDataNameAndFloatValue(name, value) {
  return objectDataNameAndValue(name, String(value));
}

function objectDataNameAndIntValue(name, value) {
  return objectDataNameAndValue(name, String(value));
}

function objectDataName(name) {
  return Json_encode.object_({
              hd: [
                "name",
                name
              ],
              tl: /* [] */0
            });
}

function objectDataValueRaw(value) {
  return Json_encode.object_({
              hd: [
                "value",
                value
              ],
              tl: /* [] */0
            });
}

function objectDataIntValue(value) {
  return objectDataValueRaw(String(value));
}

function objectDataFloatValue(value) {
  return objectDataValueRaw(String(value));
}

function handleEncoderObjectDataFloat($staropt$star, treatmentId, encoder, param) {
  var rate = $staropt$star !== undefined ? $staropt$star : 1;
  return Curry._2(encoder, treatmentId, Utils$CTEM.Currency.covertPriceWithRateWithDigit(undefined, rate, param.value));
}

function handleEncoderObjectDataInt(treatmentId, encoder, param) {
  return Curry._2(encoder, treatmentId, param.value);
}

var treatmentKey = DataTypes$CTEM.Decode.Imbruvica.treatmentKey;

function numberOfPatients(numberOfPatients$1) {
  return [
          "number_patients",
          objectDataValueRaw(String(numberOfPatients$1))
        ];
}

function treatmentDurationMonths(treamentDurationMonths) {
  return [
          "treatment_duration_months",
          objectDataValueRaw(String(treamentDurationMonths))
        ];
}

function cohortData(param) {
  return Json_encode.object_({
              hd: [
                "number_patients",
                objectDataValueRaw(String(param.numberOfPatients.value))
              ],
              tl: {
                hd: [
                  "treatment_duration_months",
                  objectDataValueRaw(String(param.treamentDurationMonths.value))
                ],
                tl: /* [] */0
              }
            });
}

function treatmentOptionCost(treatmentId, value) {
  return [
          Curry._2(treatmentKey, treatmentId, "treatment_cost"),
          objectDataValueRaw(String(value))
        ];
}

function treatmentOptionName(treatmentId, value) {
  return [
          Curry._2(treatmentKey, treatmentId, "name"),
          objectDataValueRaw(value)
        ];
}

function othersTitleSpecifyTreatmentId(treatmentId, key, title) {
  return [
          Curry._2(treatmentKey, treatmentId, key),
          objectDataName(title)
        ];
}

function othersTitle(key, title) {
  return Belt_List.makeBy(4, (function (id) {
                return othersTitleSpecifyTreatmentId(id + 1 | 0, key, title);
              }));
}

function titleAndValueObjectDataFloat(rate, treatmentId, key, param) {
  var value = Utils$CTEM.Currency.covertPriceWithRateWithDigit(undefined, rate, param.value);
  return [
          Curry._2(treatmentKey, treatmentId, key),
          objectDataNameAndValue(param.title, String(value))
        ];
}

function titleAndValueObjectDataInt(treatmentId, key, param) {
  return [
          Curry._2(treatmentKey, treatmentId, key),
          objectDataNameAndValue(param.title, String(param.value))
        ];
}

var baseKey = "med_";

function getKey(k) {
  return baseKey + k;
}

function encoder(treatmentId, key, value) {
  return [
          Curry._2(treatmentKey, treatmentId, baseKey + key),
          objectDataValueRaw(String(value))
        ];
}

function fosaprepitant(param) {
  return function (param$1) {
    return encoder(param, "fosaprepitant", param$1);
  };
}

function ondem(param) {
  return function (param$1) {
    return encoder(param, "ondem", param$1);
  };
}

function pegylated(param) {
  return function (param$1) {
    return encoder(param, "pegylated", param$1);
  };
}

function flucon(param) {
  return function (param$1) {
    return encoder(param, "flucon", param$1);
  };
}

function dexamethasone(param) {
  return function (param$1) {
    return encoder(param, "dexamethasone", param$1);
  };
}

function others01(param) {
  return function (param$1) {
    return encoder(param, "others_01", param$1);
  };
}

function others02(param) {
  return function (param$1) {
    return encoder(param, "others_02", param$1);
  };
}

var partial_arg = "med_others_01";

function titleOthers01(param) {
  return othersTitle(partial_arg, param);
}

var partial_arg$1 = "med_others_02";

function titleOthers02(param) {
  return othersTitle(partial_arg$1, param);
}

function allTreatmentData(rate, param) {
  var preMedication = param.preMedication;
  var treatmentId = param.id;
  var partial_arg = rate;
  var encoder = function (param, param$1) {
    return handleEncoderObjectDataFloat(partial_arg, treatmentId, param, param$1);
  };
  return {
          hd: Curry._2(encoder, fosaprepitant, preMedication.fosaprepitant),
          tl: {
            hd: Curry._2(encoder, ondem, preMedication.ondem),
            tl: {
              hd: Curry._2(encoder, pegylated, preMedication.pegylated),
              tl: {
                hd: Curry._2(encoder, flucon, preMedication.flucon),
                tl: {
                  hd: Curry._2(encoder, dexamethasone, preMedication.dexamethasone),
                  tl: {
                    hd: titleAndValueObjectDataFloat(rate, treatmentId, "med_others_01", preMedication.others01),
                    tl: {
                      hd: titleAndValueObjectDataFloat(rate, treatmentId, "med_others_02", preMedication.others02),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        };
}

var PreMed = {
  baseKey: baseKey,
  getKey: getKey,
  encoder: encoder,
  fosaprepitant: fosaprepitant,
  ondem: ondem,
  pegylated: pegylated,
  flucon: flucon,
  dexamethasone: dexamethasone,
  others01: others01,
  others02: others02,
  titleOthers01: titleOthers01,
  titleOthers02: titleOthers02,
  allTreatmentData: allTreatmentData
};

var baseKey$1 = "nmed_";

function getKey$1(k) {
  return baseKey$1 + k;
}

function encoder$1(treatmentId, key, value) {
  return [
          Curry._2(treatmentKey, treatmentId, baseKey$1 + key),
          objectDataValueRaw(String(value))
        ];
}

function infusion(param) {
  return function (param$1) {
    return encoder$1(param, "infusion", param$1);
  };
}

function hospitalVisits(param) {
  return function (param$1) {
    return encoder$1(param, "hospital_visits", param$1);
  };
}

function transport(param) {
  return function (param$1) {
    return encoder$1(param, "transport", param$1);
  };
}

function accomodation(param) {
  return function (param$1) {
    return encoder$1(param, "accomodation", param$1);
  };
}

function labTest(param) {
  return function (param$1) {
    return encoder$1(param, "lab_tests_pre_post_infusion", param$1);
  };
}

function others01$1(param) {
  return function (param$1) {
    return encoder$1(param, "others_01", param$1);
  };
}

function others02$1(param) {
  return function (param$1) {
    return encoder$1(param, "others_02", param$1);
  };
}

var partial_arg$2 = "nmed_others_01";

function titleOthers01$1(param) {
  return othersTitle(partial_arg$2, param);
}

var partial_arg$3 = "nmed_others_02";

function titleOthers02$1(param) {
  return othersTitle(partial_arg$3, param);
}

function allTreatmentData$1(rate, param) {
  var nonMedication = param.nonMedication;
  var treatmentId = param.id;
  var partial_arg = rate;
  var encoder = function (param, param$1) {
    return handleEncoderObjectDataFloat(partial_arg, treatmentId, param, param$1);
  };
  return {
          hd: Curry._2(encoder, infusion, nonMedication.infusion),
          tl: {
            hd: Curry._2(encoder, hospitalVisits, nonMedication.hospitalVisits),
            tl: {
              hd: Curry._2(encoder, transport, nonMedication.transport),
              tl: {
                hd: Curry._2(encoder, accomodation, nonMedication.accomodation),
                tl: {
                  hd: Curry._2(encoder, labTest, nonMedication.labTest),
                  tl: {
                    hd: titleAndValueObjectDataFloat(rate, treatmentId, "nmed_others_01", nonMedication.others01),
                    tl: {
                      hd: titleAndValueObjectDataFloat(rate, treatmentId, "nmed_others_02", nonMedication.others02),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        };
}

var NonMed = {
  baseKey: baseKey$1,
  getKey: getKey$1,
  encoder: encoder$1,
  infusion: infusion,
  hospitalVisits: hospitalVisits,
  transport: transport,
  accomodation: accomodation,
  labTest: labTest,
  others01: others01$1,
  others02: others02$1,
  titleOthers01: titleOthers01$1,
  titleOthers02: titleOthers02$1,
  allTreatmentData: allTreatmentData$1
};

function encoder$2(treatmentId, key, value) {
  return [
          Curry._2(treatmentKey, treatmentId, key),
          objectDataValueRaw(String(value))
        ];
}

function patientReducedWorkdays(param) {
  return function (param$1) {
    return encoder$2(param, "patient_reduced_workdays", param$1);
  };
}

function caregiverReducedWorkdays(param) {
  return function (param$1) {
    return encoder$2(param, "caregiver_reduced_workdays", param$1);
  };
}

function patientMonthlyIncome(value) {
  return [
          "patient_monthly_income",
          objectDataValueRaw(String(value))
        ];
}

function caregiverMonthlyIncome(value) {
  return [
          "caregiver_monthly_income",
          objectDataValueRaw(String(value))
        ];
}

function allTreatmentData$2(param) {
  var indirectCost = param.indirectCost;
  var treatmentId = param.id;
  return {
          hd: handleEncoderObjectDataInt(treatmentId, patientReducedWorkdays, indirectCost.patientReducedWorkdays),
          tl: {
            hd: handleEncoderObjectDataInt(treatmentId, caregiverReducedWorkdays, indirectCost.caregiverReducedWorkdays),
            tl: /* [] */0
          }
        };
}

var IndirectCost = {
  encoder: encoder$2,
  patientReducedWorkdays: patientReducedWorkdays,
  caregiverReducedWorkdays: caregiverReducedWorkdays,
  patientMonthlyIncome: patientMonthlyIncome,
  caregiverMonthlyIncome: caregiverMonthlyIncome,
  allTreatmentData: allTreatmentData$2
};

function mapDataTypeToKey(dataType) {
  if (dataType) {
    return "cost";
  } else {
    return "rate";
  }
}

var baseKey$2 = "ae_";

function getKey$2(key, dataType) {
  return baseKey$2 + (key + ("_" + (
              dataType ? "cost" : "rate"
            )));
}

function encoder$3(treatmentId, key, dataType, value) {
  return [
          Curry._2(treatmentKey, treatmentId, getKey$2(key, dataType)),
          objectDataValueRaw(String(value))
        ];
}

function fatigue(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "fatigue", param$1, param$2);
  };
}

function anemia(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "anemia", param$1, param$2);
  };
}

function atrialFibrillation(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "atrial_fibrillation", param$1, param$2);
  };
}

function hypertension(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "hypertension", param$1, param$2);
  };
}

function lymphopenia(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "lymphopenia", param$1, param$2);
  };
}

function haematologicalToxicity(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "haematological_toxicity", param$1, param$2);
  };
}

function infections(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "infections", param$1, param$2);
  };
}

function infusionReactions(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "infusion_reactions", param$1, param$2);
  };
}

function leucocytopenia(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "leucocytopenia", param$1, param$2);
  };
}

function neutropenia(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "neutropenia", param$1, param$2);
  };
}

function pneumonia(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "pneumonia", param$1, param$2);
  };
}

function thrombocytopenia(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "thrombocytopenia", param$1, param$2);
  };
}

function others01$2(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "others_01", param$1, param$2);
  };
}

function others02$2(param) {
  return function (param$1, param$2) {
    return encoder$3(param, "others_02", param$1, param$2);
  };
}

function othersTitleCostRate(key, title) {
  return Belt_List.flatten(Belt_List.makeBy(4, (function (id) {
                    var encoder = function (dataType) {
                      return othersTitleSpecifyTreatmentId(id + 1 | 0, getKey$2(key, dataType), title);
                    };
                    return {
                            hd: encoder(/* Cost */1),
                            tl: {
                              hd: encoder(/* Rate */0),
                              tl: /* [] */0
                            }
                          };
                  })));
}

function titleOthers01$2(param) {
  return othersTitleCostRate("others_01", param);
}

function titleOthers02$2(param) {
  return othersTitleCostRate("others_02", param);
}

function othersTitleValueCostRate(exchangeRate, key, param) {
  var cost = param.cost;
  var rate = param.rate;
  return Belt_List.flatten(Belt_List.makeBy(4, (function (id) {
                    var encoder = function (dataType, data) {
                      return titleAndValueObjectDataFloat(exchangeRate, id + 1 | 0, getKey$2(key, dataType), data);
                    };
                    return {
                            hd: encoder(/* Cost */1, cost),
                            tl: {
                              hd: encoder(/* Rate */0, rate),
                              tl: /* [] */0
                            }
                          };
                  })));
}

function encoderTreatmentAdverseEvent(exchangeRate, encoder, param) {
  return {
          hd: Curry._2(encoder, /* Rate */0, param.rate.value),
          tl: {
            hd: Curry._2(encoder, /* Cost */1, Utils$CTEM.Currency.covertPriceWithRateWithDigit(undefined, exchangeRate, param.cost.value)),
            tl: /* [] */0
          }
        };
}

function allTreatmentData$3(rate, param) {
  var adverseEvent = param.adverseEvent;
  var treatmentId = param.id;
  return Belt_List.flatten({
              hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                      return encoder$3(treatmentId, "fatigue", param, param$1);
                    }), adverseEvent.fatigue),
              tl: {
                hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                        return encoder$3(treatmentId, "anemia", param, param$1);
                      }), adverseEvent.anemia),
                tl: {
                  hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                          return encoder$3(treatmentId, "atrial_fibrillation", param, param$1);
                        }), adverseEvent.atrialFibrillation),
                  tl: {
                    hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                            return encoder$3(treatmentId, "hypertension", param, param$1);
                          }), adverseEvent.hypertension),
                    tl: {
                      hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                              return encoder$3(treatmentId, "lymphopenia", param, param$1);
                            }), adverseEvent.lymphopenia),
                      tl: {
                        hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                                return encoder$3(treatmentId, "haematological_toxicity", param, param$1);
                              }), adverseEvent.haematologicalToxicity),
                        tl: {
                          hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                                  return encoder$3(treatmentId, "infections", param, param$1);
                                }), adverseEvent.infections),
                          tl: {
                            hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                                    return encoder$3(treatmentId, "infusion_reactions", param, param$1);
                                  }), adverseEvent.infusionReactions),
                            tl: {
                              hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                                      return encoder$3(treatmentId, "leucocytopenia", param, param$1);
                                    }), adverseEvent.leucocytopenia),
                              tl: {
                                hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                                        return encoder$3(treatmentId, "neutropenia", param, param$1);
                                      }), adverseEvent.neutropenia),
                                tl: {
                                  hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                                          return encoder$3(treatmentId, "pneumonia", param, param$1);
                                        }), adverseEvent.pneumonia),
                                  tl: {
                                    hd: encoderTreatmentAdverseEvent(rate, (function (param, param$1) {
                                            return encoder$3(treatmentId, "thrombocytopenia", param, param$1);
                                          }), adverseEvent.thrombocytopenia),
                                    tl: {
                                      hd: othersTitleValueCostRate(rate, "others_01", adverseEvent.others01),
                                      tl: {
                                        hd: othersTitleValueCostRate(rate, "others_02", adverseEvent.others02),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var AdverseEvent = {
  mapDataTypeToKey: mapDataTypeToKey,
  baseKey: baseKey$2,
  getKey: getKey$2,
  encoder: encoder$3,
  fatigue: fatigue,
  anemia: anemia,
  atrialFibrillation: atrialFibrillation,
  hypertension: hypertension,
  lymphopenia: lymphopenia,
  haematologicalToxicity: haematologicalToxicity,
  infections: infections,
  infusionReactions: infusionReactions,
  leucocytopenia: leucocytopenia,
  neutropenia: neutropenia,
  pneumonia: pneumonia,
  thrombocytopenia: thrombocytopenia,
  others01: others01$2,
  others02: others02$2,
  othersTitleCostRate: othersTitleCostRate,
  titleOthers01: titleOthers01$2,
  titleOthers02: titleOthers02$2,
  othersTitleValueCostRate: othersTitleValueCostRate,
  encoderTreatmentAdverseEvent: encoderTreatmentAdverseEvent,
  allTreatmentData: allTreatmentData$3
};

function encoder$4(exchangeRate, imbruvicaData) {
  var rate = Belt_Option.getWithDefault(exchangeRate, 1);
  var func = Utils$CTEM.Currency.covertPriceWithRateWithDigit;
  var cohortInputList_0 = [
    "number_patients",
    objectDataValueRaw(String(imbruvicaData.numberOfPatients.value))
  ];
  var cohortInputList_1 = {
    hd: [
      "treatment_duration_months",
      objectDataValueRaw(String(imbruvicaData.treamentDurationMonths.value))
    ],
    tl: {
      hd: patientMonthlyIncome(Curry._3(func, undefined, rate, imbruvicaData.patientMonthlyIncome.value)),
      tl: {
        hd: caregiverMonthlyIncome(Curry._3(func, undefined, rate, imbruvicaData.caregiverMonthlyIncome.value)),
        tl: /* [] */0
      }
    }
  };
  var cohortInputList = {
    hd: cohortInputList_0,
    tl: cohortInputList_1
  };
  var treatmentInputList = Belt_List.flatten(Belt_List.map(imbruvicaData.treatments, (function (treatment) {
              var treatmentId = treatment.id;
              return Belt_List.flatten({
                          hd: {
                            hd: treatmentOptionName(treatmentId, treatment.treatmentName.value),
                            tl: {
                              hd: treatmentOptionCost(treatmentId, Curry._3(func, undefined, rate, treatment.cost.value)),
                              tl: /* [] */0
                            }
                          },
                          tl: {
                            hd: allTreatmentData$2(treatment),
                            tl: {
                              hd: allTreatmentData(rate, treatment),
                              tl: {
                                hd: allTreatmentData$1(rate, treatment),
                                tl: {
                                  hd: allTreatmentData$3(rate, treatment),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        });
            })));
  return inputDatasByListInputs(Belt_List.concat(treatmentInputList, cohortInputList));
}

var Imbruvica = {
  treatmentKey: treatmentKey,
  numberOfPatients: numberOfPatients,
  treatmentDurationMonths: treatmentDurationMonths,
  cohortData: cohortData,
  treatmentOptionCost: treatmentOptionCost,
  treatmentOptionName: treatmentOptionName,
  othersTitleSpecifyTreatmentId: othersTitleSpecifyTreatmentId,
  othersTitle: othersTitle,
  titleAndValueObjectDataFloat: titleAndValueObjectDataFloat,
  titleAndValueObjectDataInt: titleAndValueObjectDataInt,
  PreMed: PreMed,
  NonMed: NonMed,
  IndirectCost: IndirectCost,
  AdverseEvent: AdverseEvent,
  encoder: encoder$4
};

export {
  inputDatasRaw ,
  inputDatasByListInputs ,
  inputDatas ,
  objectDataNameAndValue ,
  objectDataNameAndFloatValue ,
  objectDataNameAndIntValue ,
  objectDataName ,
  objectDataValueRaw ,
  objectDataIntValue ,
  objectDataFloatValue ,
  handleEncoderObjectDataFloat ,
  handleEncoderObjectDataInt ,
  Imbruvica ,
  
}
/* Utils-CTEM Not a pure module */
