// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

function SelectTextInput(Props) {
  var selectOptions = Props.selectOptions;
  var onChange = Props.onChange;
  var className = Props.className;
  var disabledOpt = Props.disabled;
  var errorOpt = Props.error;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var error = errorOpt !== undefined ? errorOpt : false;
  var match = React.useState(function () {
        return selectOptions.value;
      });
  var setValueText = match[1];
  var shouldUpdateValue = function (e) {
    var newValueText = Utils$CTEM.getValueFromReactEventForm(e);
    Belt_Option.map(onChange, (function (f) {
            return Curry._1(f, newValueText);
          }));
    return Curry._1(setValueText, (function (param) {
                  return newValueText;
                }));
  };
  React.useEffect((function () {
          Curry._1(setValueText, (function (param) {
                  return selectOptions.value;
                }));
          
        }), [selectOptions.value]);
  return React.createElement(FormControl, {
              variant: MaterialUIDataType.Variant.outlined,
              className: "select-text-input input-modify-data " + Belt_Option.getWithDefault(className, ""),
              error: error,
              disabled: disabled,
              children: React.createElement(Select, {
                    disabled: disabled,
                    value: match[0],
                    onChange: shouldUpdateValue,
                    children: Belt_Array.mapWithIndex(selectOptions.items, (function (idx, param) {
                            return React.createElement(MenuItem, {
                                        value: param.value,
                                        children: param.label,
                                        key: "select-text-menu-item-" + String(idx)
                                      });
                          }))
                  })
            });
}

var make = SelectTextInput;

export {
  make ,
  
}
/* react Not a pure module */
