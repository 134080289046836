// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apis$CTEM from "../../../../Effects/Apis.bs.js";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as EncodeLAI$CTEM from "../EncodeLAI.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as LAIInputForm$CTEM from "./LAIInputForm.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";

function LAICIAndHRU(Props) {
  var workId = Props.workId;
  var defaultData = Props.defaultData;
  var currentData = Props.currentData;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* RequestState */0 :
                return {
                        overallHealthcare: state.overallHealthcare,
                        indirectCosts: state.indirectCosts,
                        loadingData: action._0
                      };
            case /* UpdateOverallHealthcare */1 :
                return {
                        overallHealthcare: action._0,
                        indirectCosts: state.indirectCosts,
                        loadingData: state.loadingData
                      };
            case /* UpdateIndirectCosts */2 :
                return {
                        overallHealthcare: state.overallHealthcare,
                        indirectCosts: action._0,
                        loadingData: state.loadingData
                      };
            
          }
        }), {
        overallHealthcare: currentData.overallHealthcare,
        indirectCosts: currentData.indirectCosts,
        loadingData: false
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var updateLaiData = function (overallHealthcare, incarceration) {
    Curry._1(dispatch, {
          TAG: /* RequestState */0,
          _0: true
        });
    return Apis$CTEM.requestUpdateSavedWork(workId, EncodeLAI$CTEM.healthcareAndIncarceration(overallHealthcare, incarceration), (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }), (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }));
  };
  React.useEffect((function () {
          if (state.overallHealthcare !== currentData.overallHealthcare) {
            Curry._1(dispatch, {
                  TAG: /* UpdateOverallHealthcare */1,
                  _0: currentData.overallHealthcare
                });
          }
          if (state.indirectCosts !== currentData.indirectCosts) {
            Curry._1(dispatch, {
                  TAG: /* UpdateIndirectCosts */2,
                  _0: currentData.indirectCosts
                });
          }
          
        }), [currentData]);
  var tooltipLeftClass = "tooltip-infos-left";
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement("h3", undefined, "Hospitalization Rate")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-top-1 width-edit-form",
                      Caml_option.some(React.createElement("h3", undefined, "Hospitalization when treated with ORALs")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-top-2 width-edit-form margin-edit-form",
                      Caml_option.some(React.createElement("h3", undefined, "Reduction in hospitalizations when treated with LAIs")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-middle-1 width-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.overallHealthcare.oral.hospitalizationRate.value
                                },
                                title: state.overallHealthcare.oral.hospitalizationRate.title,
                                subTitle: "Percentage of patient requiring hospitalization over 1 year",
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.hospitalizationRate;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: {
                                        title: init$2.title,
                                        value: value,
                                        source: init$2.source
                                      },
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  }),
                                infoMode: state.overallHealthcare.oral.hospitalizationRate.value !== defaultData.overallHealthcare.oral.hospitalizationRate.value ? /* Restore */0 : /* Infos */({
                                      _0: state.overallHealthcare.oral.hospitalizationRate.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.hospitalizationRate;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: {
                                        title: init$2.title,
                                        value: defaultData.overallHealthcare.oral.hospitalizationRate.value,
                                        source: init$2.source
                                      },
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.overallHealthcare.lais.hospitalizationRate.value
                                },
                                title: state.overallHealthcare.lais.hospitalizationRate.title,
                                subTitle: "Reduction in rehospitaization when switching to LAIs",
                                tooltipClassName: tooltipLeftClass,
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.lais;
                                    var init$2 = state.overallHealthcare.oral.hospitalizationRate;
                                    var overallHealthcare_oral = init.oral;
                                    var overallHealthcare_lais = {
                                      hospitalizationRate: {
                                        title: init$2.title,
                                        value: value,
                                        source: init$2.source
                                      },
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  }),
                                infoMode: state.overallHealthcare.lais.hospitalizationRate.value !== defaultData.overallHealthcare.lais.hospitalizationRate.value ? /* Restore */0 : /* Infos */({
                                      _0: state.overallHealthcare.lais.hospitalizationRate.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.lais;
                                    var init$2 = state.overallHealthcare.oral.hospitalizationRate;
                                    var overallHealthcare_oral = init.oral;
                                    var overallHealthcare_lais = {
                                      hospitalizationRate: {
                                        title: init$2.title,
                                        value: defaultData.overallHealthcare.lais.hospitalizationRate.value,
                                        source: init$2.source
                                      },
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-middle-1 width-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.overallHealthcare.oral.lengthOfStay.value
                                },
                                title: state.overallHealthcare.oral.lengthOfStay.title,
                                subTitle: "Number of days in hospital per hospitalized patient in 1 year",
                                unit: "days",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.lengthOfStay;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: {
                                        title: init$2.title,
                                        value: value,
                                        source: init$2.source
                                      },
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  }),
                                infoMode: state.overallHealthcare.oral.lengthOfStay.value !== defaultData.overallHealthcare.oral.lengthOfStay.value ? /* Restore */0 : /* Infos */({
                                      _0: state.overallHealthcare.oral.lengthOfStay.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.lengthOfStay;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: {
                                        title: init$2.title,
                                        value: defaultData.overallHealthcare.oral.lengthOfStay.value,
                                        source: init$2.source
                                      },
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.overallHealthcare.lais.lengthOfStay.value
                                },
                                title: state.overallHealthcare.lais.lengthOfStay.title,
                                subTitle: "Reduction in length of hospital stay when switching to LAIs",
                                tooltipClassName: tooltipLeftClass,
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.lais;
                                    var init$2 = state.overallHealthcare.lais.lengthOfStay;
                                    var overallHealthcare_oral = init.oral;
                                    var overallHealthcare_lais = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: {
                                        title: init$2.title,
                                        value: value,
                                        source: init$2.source
                                      },
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  }),
                                infoMode: state.overallHealthcare.lais.lengthOfStay.value !== defaultData.overallHealthcare.lais.lengthOfStay.value ? /* Restore */0 : /* Infos */({
                                      _0: state.overallHealthcare.lais.lengthOfStay.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.lais;
                                    var init$2 = state.overallHealthcare.lais.lengthOfStay;
                                    var overallHealthcare_oral = init.oral;
                                    var overallHealthcare_lais = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: {
                                        title: init$2.title,
                                        value: defaultData.overallHealthcare.lais.lengthOfStay.value,
                                        source: init$2.source
                                      },
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-bottom-1 width-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.overallHealthcare.oral.relapseRate.value
                                },
                                title: state.overallHealthcare.oral.relapseRate.title,
                                subTitle: "Proportion of relapsed patients within all hospitalization",
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.relapseRate;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: {
                                        title: init$2.title,
                                        value: value,
                                        source: init$2.source
                                      },
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  }),
                                infoMode: state.overallHealthcare.oral.relapseRate.value !== defaultData.overallHealthcare.oral.relapseRate.value ? /* Restore */0 : /* Infos */({
                                      _0: state.overallHealthcare.oral.relapseRate.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.relapseRate;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: {
                                        title: init$2.title,
                                        value: defaultData.overallHealthcare.oral.relapseRate.value,
                                        source: init$2.source
                                      },
                                      avgRelapseRate: init$1.avgRelapseRate
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-bottom-2 width-edit-form margin-edit-form",
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement("h3", undefined, "Relapse Rate")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-top-1 width-edit-form",
                      Caml_option.some(React.createElement("h3", undefined, "Relapse rate when treated with ORALs")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-top-2 width-edit-form margin-edit-form",
                      Caml_option.some(React.createElement("h3", undefined, "Reduction in relapse when treated with LAIs")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-middle-1 width-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.overallHealthcare.oral.avgRelapseRate.value
                                },
                                title: state.overallHealthcare.oral.avgRelapseRate.title,
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.avgRelapseRate;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: {
                                        title: init$2.title,
                                        value: value,
                                        source: init$2.source
                                      }
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  }),
                                infoMode: state.overallHealthcare.oral.avgRelapseRate.value !== defaultData.overallHealthcare.oral.avgRelapseRate.value ? /* Restore */0 : /* Infos */({
                                      _0: state.overallHealthcare.oral.avgRelapseRate.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.oral;
                                    var init$2 = state.overallHealthcare.oral.avgRelapseRate;
                                    var overallHealthcare_oral = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: {
                                        title: init$2.title,
                                        value: defaultData.overallHealthcare.oral.avgRelapseRate.value,
                                        source: init$2.source
                                      }
                                    };
                                    var overallHealthcare_lais = init.lais;
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.overallHealthcare.lais.avgRelapseRate.value
                                },
                                title: state.overallHealthcare.lais.avgRelapseRate.title,
                                tooltipClassName: tooltipLeftClass,
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.lais;
                                    var init$2 = state.overallHealthcare.lais.avgRelapseRate;
                                    var overallHealthcare_oral = init.oral;
                                    var overallHealthcare_lais = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: {
                                        title: init$2.title,
                                        value: value,
                                        source: init$2.source
                                      }
                                    };
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  }),
                                infoMode: state.overallHealthcare.lais.avgRelapseRate.value !== defaultData.overallHealthcare.lais.avgRelapseRate.value ? /* Restore */0 : /* Infos */({
                                      _0: state.overallHealthcare.lais.avgRelapseRate.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.overallHealthcare;
                                    var init$1 = state.overallHealthcare.lais;
                                    var init$2 = state.overallHealthcare.lais.avgRelapseRate;
                                    var overallHealthcare_oral = init.oral;
                                    var overallHealthcare_lais = {
                                      hospitalizationRate: init$1.hospitalizationRate,
                                      lengthOfStay: init$1.lengthOfStay,
                                      relapseRate: init$1.relapseRate,
                                      avgRelapseRate: {
                                        title: init$2.title,
                                        value: defaultData.overallHealthcare.lais.avgRelapseRate.value,
                                        source: init$2.source
                                      }
                                    };
                                    var overallHealthcare = {
                                      oral: overallHealthcare_oral,
                                      lais: overallHealthcare_lais
                                    };
                                    return updateLaiData(overallHealthcare, state.indirectCosts.incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-bottom-1 width-edit-form",
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-bottom-2 width-edit-form margin-edit-form",
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement("h3", undefined, "Incarceration Rate")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-top-1 width-edit-form",
                      Caml_option.some(React.createElement("h3", undefined, "Incarceration rate when treated with ORALs")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-top-2 width-edit-form margin-edit-form",
                      Caml_option.some(React.createElement("h3", undefined, "Incarceration rate when treated with LAIs")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-bottom-1 width-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.indirectCosts.incarceration.oralPatients.value
                                },
                                title: state.indirectCosts.incarceration.oralPatients.title,
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.indirectCosts.incarceration;
                                    var init$1 = state.indirectCosts.incarceration.oralPatients;
                                    var incarceration_oralPatients = {
                                      title: init$1.title,
                                      value: value,
                                      source: init$1.source
                                    };
                                    var incarceration_pp1mPatients = init.pp1mPatients;
                                    var incarceration = {
                                      oralPatients: incarceration_oralPatients,
                                      pp1mPatients: incarceration_pp1mPatients
                                    };
                                    return updateLaiData(state.overallHealthcare, incarceration);
                                  }),
                                infoMode: state.indirectCosts.incarceration.oralPatients.value !== defaultData.indirectCosts.incarceration.oralPatients.value ? /* Restore */0 : /* Infos */({
                                      _0: state.indirectCosts.incarceration.oralPatients.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.indirectCosts.incarceration;
                                    var init$1 = state.indirectCosts.incarceration.oralPatients;
                                    var incarceration_oralPatients = {
                                      title: init$1.title,
                                      value: defaultData.indirectCosts.incarceration.oralPatients.value,
                                      source: init$1.source
                                    };
                                    var incarceration_pp1mPatients = init.pp1mPatients;
                                    var incarceration = {
                                      oralPatients: incarceration_oralPatients,
                                      pp1mPatients: incarceration_pp1mPatients
                                    };
                                    return updateLaiData(state.overallHealthcare, incarceration);
                                  })
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-edit-form-bottom-2 width-edit-form margin-edit-form",
                      Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                inputMode: {
                                  TAG: /* FloatInput */1,
                                  _0: state.indirectCosts.incarceration.pp1mPatients.value
                                },
                                title: state.indirectCosts.incarceration.pp1mPatients.title,
                                tooltipClassName: tooltipLeftClass,
                                unit: "%",
                                inputDisabled: state.loadingData,
                                onValueUpdate: (function (value) {
                                    var init = state.indirectCosts.incarceration;
                                    var init$1 = state.indirectCosts.incarceration.pp1mPatients;
                                    var incarceration_oralPatients = init.oralPatients;
                                    var incarceration_pp1mPatients = {
                                      title: init$1.title,
                                      value: value,
                                      source: init$1.source
                                    };
                                    var incarceration = {
                                      oralPatients: incarceration_oralPatients,
                                      pp1mPatients: incarceration_pp1mPatients
                                    };
                                    return updateLaiData(state.overallHealthcare, incarceration);
                                  }),
                                infoMode: state.indirectCosts.incarceration.pp1mPatients.value !== defaultData.indirectCosts.incarceration.pp1mPatients.value ? /* Restore */0 : /* Infos */({
                                      _0: state.indirectCosts.incarceration.pp1mPatients.source
                                    }),
                                onRestore: (function (param) {
                                    var init = state.indirectCosts.incarceration;
                                    var init$1 = state.indirectCosts.incarceration.pp1mPatients;
                                    var incarceration_oralPatients = init.oralPatients;
                                    var incarceration_pp1mPatients = {
                                      title: init$1.title,
                                      value: defaultData.indirectCosts.incarceration.pp1mPatients.value,
                                      source: init$1.source
                                    };
                                    var incarceration = {
                                      oralPatients: incarceration_oralPatients,
                                      pp1mPatients: incarceration_pp1mPatients
                                    };
                                    return updateLaiData(state.overallHealthcare, incarceration);
                                  }),
                                defaultValue: Utils$CTEM.getFloatWithDigit(2, state.indirectCosts.incarceration.pp1mPatients.value)
                              })),
                      undefined,
                      undefined
                    ])));
}

var refOralRelapseRateTreated = "Relapse rates of ORAL antipsychotics were retrieved from Lin et al. 2016 and weighted by the local market share";

var make = LAICIAndHRU;

export {
  refOralRelapseRateTreated ,
  make ,
  
}
/* react Not a pure module */
