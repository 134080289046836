// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Encoder$CTEM from "./Encoder.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as DataTypes$CTEM from "../DataTypes.bs.js";
import * as RequestUtils$CTEM from "../RequestUtils.bs.js";

function requestUpdateSavedWork(workId, encoder, successAction, failAction) {
  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Patch */8, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), Caml_option.some(JSON.stringify(encoder)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.fileIdPath(String(workId))), successAction, failAction);
  
}

function requestUpdateSavedWorkNormalInput(workId, normalInputs, successAction, failAction) {
  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Patch */8, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), Caml_option.some(JSON.stringify(Encoder$CTEM.inputDatasByListInputs(normalInputs))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.fileIdPath(String(workId))), successAction, failAction);
  
}

function requestRealtimeCurrencyExchangeRates(successAction, failAction) {
  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), "https://www.floatrates.com/daily/usd.json", (function (json) {
          return Curry._1(successAction, Curry._1(DataTypes$CTEM.Decode.CurrencyExchangeRate.decoder, json));
        }), failAction);
  
}

export {
  requestUpdateSavedWork ,
  requestUpdateSavedWorkNormalInput ,
  requestRealtimeCurrencyExchangeRates ,
  
}
/* Encoder-CTEM Not a pure module */
