// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as React from "react";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Bar from "@nivo/bar";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var mapDatasIntoVericalChartDatas = (function(data1, data2) {
    var bar1 = {
      title: "Without LAIs",
    };
    var bar2 = {
      title: "With LAIs",
    };
    var chart = {
      bars: [],
      keys: [],
      colors: [],
      totalValue: [0,0]
    };
    for (var i = 0 ; i < data1.length ; i++) {
      bar1[data1[i].title] = parseInt(data1[i].value);
      chart.keys.push(data1[i].title);
      chart.colors.push(data1[i].color);
      chart.totalValue[0] += parseInt(data1[i].value);
    };
    for (var i = 0 ; i < data2.length ; i++) {
      bar2[data2[i].title] = parseInt(data2[i].value);
      chart.colors.push(data2[i].color);
      chart.totalValue[1] += parseInt(data2[i].value);
    };
    if (data1.length > 0) {
      chart.bars.push(bar1);
    }
    if (data2.length > 0) {
      chart.bars.push(bar2);
    }
    return chart;
  });

var getLabel = (function(data, maxValue) {
    return (data.value * 100 / maxValue).toFixed(2) + "%";
  });

function textElement(text, x, y, fontSize, fontWeight, fill) {
  return React.createElement("text", {
              className: "tooltip-text-",
              style: {
                fontSize: fontSize,
                fontWeight: fontWeight
              },
              fill: fill,
              x: x,
              y: y
            }, text);
}

function lineElement(x1, x2, y1, y2, stroke, strokeWidth) {
  return React.createElement("line", {
              style: {
                stroke: stroke,
                strokeWidth: strokeWidth
              },
              x1: x1,
              x2: x2,
              y1: y1,
              y2: y2
            });
}

function fontWeightToString(weight, unit) {
  if (weight.TAG === /* Default */0) {
    return [
            String(weight._0) + unit,
            "normal"
          ];
  } else {
    return [
            String(weight._0) + unit,
            "bold"
          ];
  }
}

function getTooltipDirection(value) {
  if (value === 0) {
    return /* Middle */1;
  } else if (value > 0) {
    return /* DownToTop */2;
  } else {
    return /* TopToDown */0;
  }
}

function getTooltipColor(direction) {
  switch (direction) {
    case /* TopToDown */0 :
        return "red";
    case /* Middle */1 :
        return "black";
    case /* DownToTop */2 :
        return "green";
    
  }
}

function StackedBarChart(Props) {
  var verticalChartData = Props.verticalChartData;
  var classNameOpt = Props.className;
  var layoutOpt = Props.layout;
  var colorByOpt = Props.colorBy;
  var enableLabelOpt = Props.enableLabel;
  var enableGridYOpt = Props.enableGridY;
  var unitGridYValueOpt = Props.unitGridYValue;
  var showLegendsOpt = Props.showLegends;
  var onlyDifferenceOpt = Props.onlyDifference;
  var borderRadiusOpt = Props.borderRadius;
  var heightOpt = Props.height;
  var paddingOpt = Props.padding;
  var marginOpt = Props.margin;
  var maxValueOutsideOpt = Props.maxValueOutside;
  var onClick = Props.onClick;
  var className = classNameOpt !== undefined ? classNameOpt : "stacked-bar-chart";
  var layout = layoutOpt !== undefined ? layoutOpt : "horizontal";
  var colorBy = colorByOpt !== undefined ? colorByOpt : "index";
  var enableLabel = enableLabelOpt !== undefined ? enableLabelOpt : false;
  var enableGridY = enableGridYOpt !== undefined ? enableGridYOpt : false;
  var unitGridYValue = unitGridYValueOpt !== undefined ? unitGridYValueOpt : "";
  var showLegends = showLegendsOpt !== undefined ? showLegendsOpt : /* InVisible */1;
  var onlyDifference = onlyDifferenceOpt !== undefined ? onlyDifferenceOpt : false;
  var borderRadius = borderRadiusOpt !== undefined ? borderRadiusOpt : "0px";
  var height = heightOpt !== undefined ? heightOpt : "200px";
  var padding = paddingOpt !== undefined ? paddingOpt : 0.5;
  var margin = marginOpt !== undefined ? Caml_option.valFromOption(marginOpt) : ({
        top: 5,
        right: 70,
        bottom: 80,
        left: 0
      });
  var maxValueOutside = maxValueOutsideOpt !== undefined ? maxValueOutsideOpt : 0;
  var rawVerticalData = verticalChartData !== undefined ? mapDatasIntoVericalChartDatas(verticalChartData.oldData, verticalChartData.newData) : mapDatasIntoVericalChartDatas([], []);
  var coupleBarVerticalLayer = function (datas) {
    return $$Array.of_list(List.mapi((function (index, bar) {
                      var x = String(bar.x);
                      var y = String(bar.y);
                      var transform = "translate(" + (x + (", " + (y + ")")));
                      var tmp;
                      if (index % 2 === 0) {
                        var pairBar = Caml_array.get(datas.bars, index + 1 | 0);
                        var endX = (pairBar.x - bar.x | 0) + pairBar.width | 0;
                        var result = onlyDifference ? Caml_array.get(rawVerticalData.totalValue, 1) - Caml_array.get(rawVerticalData.totalValue, 0) : pairBar.data.value - bar.data.value;
                        var tooltipDirection = getTooltipDirection(result);
                        var color = getTooltipColor(tooltipDirection);
                        var match;
                        switch (tooltipDirection) {
                          case /* TopToDown */0 :
                              match = [
                                String(endX + 15 | 0) + ("," + (String(pairBar.y - bar.y | 0) + (" " + (String(endX) + ("," + String(pairBar.y - bar.y | 0)))))),
                                String(((pairBar.y - bar.y | 0) / 2 | 0) + 5 | 0)
                              ];
                              break;
                          case /* Middle */1 :
                              match = [
                                "",
                                String(5)
                              ];
                              break;
                          case /* DownToTop */2 :
                              match = [
                                String(endX + 15 | 0) + ("," + (String(-(bar.y - pairBar.y | 0) | 0) + (" " + (String(endX) + ("," + String(-(bar.y - pairBar.y | 0) | 0)))))),
                                String((-((bar.y - pairBar.y | 0) / 2 | 0) | 0) + 5 | 0)
                              ];
                              break;
                          
                        }
                        var textY = match[1];
                        var points = String(endX) + (",0 " + (String(endX + 15 | 0) + (",0 " + match[0])));
                        var tooltipDiffs = function (param) {
                          return React.createElement("g", {
                                      transform: transform
                                    }, React.createElement("line", {
                                          style: {
                                            stroke: color,
                                            strokeWidth: "2px"
                                          },
                                          strokeDasharray: "5,5",
                                          x1: "0",
                                          x2: String(endX),
                                          y1: "0",
                                          y2: "0"
                                        }), React.createElement("polyline", {
                                          style: {
                                            stroke: color,
                                            strokeWidth: "4px"
                                          },
                                          fill: "none",
                                          points: points
                                        }), React.createElement("text", {
                                          className: "value-pair-diff",
                                          style: {
                                            fontWeight: "bold"
                                          },
                                          fill: color,
                                          x: String((endX + 15 | 0) + 10 | 0),
                                          y: textY
                                        }, (
                                          result >= 0 ? "+ " : "- "
                                        ) + Utils$CTEM.shortNumberFromString(Math.abs(result).toString())));
                        };
                        var legendHeightDistance = datas.height * 0.06 | 0;
                        var legendWidthDistance = datas.width * 0.25 | 0;
                        var rectSize = datas.height * 0.07 | 0;
                        var tmp$1;
                        if (typeof showLegends === "number") {
                          tmp$1 = null;
                        } else {
                          var legendY = Caml_array.get(datas.bars, 0).y + Caml_array.get(datas.bars, 0).height | 0;
                          var diff = pairBar.data.value - bar.data.value;
                          var fill = getTooltipColor(getTooltipDirection(diff));
                          tmp$1 = React.createElement(React.Fragment, {
                                children: null
                              }, React.createElement("rect", {
                                    height: String(rectSize),
                                    width: String(rectSize),
                                    fill: bar.color,
                                    x: String((endX + 15 | 0) + legendWidthDistance | 0),
                                    y: String((legendY + Math.imul(-index | 0, legendHeightDistance) | 0) - rectSize | 0)
                                  }), React.createElement("text", {
                                    className: "legend-text",
                                    x: String((((endX + 15 | 0) + legendWidthDistance | 0) + rectSize | 0) + 10 | 0),
                                    y: String((legendY + Math.imul(-index | 0, legendHeightDistance) | 0) - 15 | 0)
                                  }, React.createElement("tspan", {
                                        fill: bar.color
                                      }, bar.data.id)), React.createElement("text", {
                                    className: "legend-text",
                                    x: String((((endX + 15 | 0) + legendWidthDistance | 0) + rectSize | 0) + 10 | 0),
                                    y: String((legendY + Math.imul(-index | 0, legendHeightDistance) | 0) + 2 | 0)
                                  }, showLegends._0 ? React.createElement("tspan", {
                                          style: {
                                            fontWeight: "bold"
                                          },
                                          fill: fill
                                        }, (
                                          diff >= 0 ? " ( +" : " ( -"
                                        ) + (Utils$CTEM.shortNumberFromString(Math.abs(diff).toString()) + " )")) : React.createElement("tspan", {
                                          style: {
                                            fontWeight: "bold"
                                          },
                                          fill: "black"
                                        }, " ( " + (Utils$CTEM.shortNumberFromString(Math.abs(bar.data.value).toString()) + (" vs " + (Utils$CTEM.shortNumberFromString(Math.abs(pairBar.data.value).toString()) + " )"))))));
                        }
                        tmp = React.createElement(React.Fragment, {
                              children: null,
                              key: "tooltip-vertical-" + String(index)
                            }, onlyDifference && !(index === (datas.bars.length - 2 | 0) || index === (datas.bars.length - 2 | 0)) ? null : tooltipDiffs(undefined), tmp$1);
                      } else {
                        tmp = null;
                      }
                      var tmp$2;
                      if (index === 0 || index === 1) {
                        var sumValueTransform = "translate(" + (String(bar.x + (bar.width / 2 | 0) | 0) + (", " + (String((bar.y + bar.height | 0) + 50 | 0) + ")")));
                        var textDiff = typeof showLegends === "number" && showLegends !== 0 ? 5 : 20;
                        tmp$2 = React.createElement("g", {
                              transform: sumValueTransform
                            }, React.createElement("text", {
                                  key: "total-text-" + String(index),
                                  className: "bar-value",
                                  fill: "black",
                                  textAnchor: bar.width <= 110 ? (
                                      index === 0 ? "end" : "start"
                                    ) : "middle",
                                  x: bar.width <= 110 ? (
                                      index === 0 ? String(textDiff) : String(-textDiff | 0)
                                    ) : "0",
                                  y: "15"
                                }, Utils$CTEM.shortNumberFromString(String(Caml_array.get(rawVerticalData.totalValue, index)))), React.createElement("text", {
                                  key: "index-value-text-" + String(index),
                                  className: "bar-index-value",
                                  fill: "black",
                                  textAnchor: index === 0 ? "end" : "start",
                                  x: index === 0 ? String(textDiff) : String(-textDiff | 0),
                                  y: "-20"
                                }, bar.data.indexValue));
                      } else {
                        tmp$2 = null;
                      }
                      return React.createElement(React.Fragment, {
                                  children: null
                                }, tmp, tmp$2);
                    }), $$Array.to_list(datas.bars)));
  };
  var singleBarVerticalLayer = function (datas) {
    return $$Array.of_list(List.mapi((function (index, bar) {
                      var x = String(bar.x);
                      var y = String(bar.y);
                      var transform = "translate(" + (x + (", " + (y + ")")));
                      var isLeft = index % 2 === 0;
                      var startX = isLeft ? -100 : bar.width;
                      var textAnchor = isLeft ? "end" : "start";
                      var textX = isLeft ? -5 : bar.width + 5 | 0;
                      return React.createElement("g", {
                                  key: "tooltip-vertical-" + String(index),
                                  transform: transform
                                }, React.createElement("line", {
                                      style: {
                                        stroke: bar.color,
                                        strokeWidth: "2px"
                                      },
                                      x1: String(startX),
                                      x2: isLeft ? "0" : String(bar.width + 100 | 0),
                                      y1: String(bar.height),
                                      y2: String(bar.height)
                                    }), React.createElement("text", {
                                      style: {
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                      },
                                      fill: bar.color,
                                      textAnchor: textAnchor,
                                      x: String(textX),
                                      y: String(bar.height - 30 | 0)
                                    }, bar.data.id), React.createElement("text", {
                                      style: {
                                        fontSize: "14px",
                                        fontWeight: "bold"
                                      },
                                      fill: bar.color,
                                      textAnchor: textAnchor,
                                      x: String(textX),
                                      y: String(bar.height - 10 | 0)
                                    }, Utils$CTEM.shortNumberFromString(bar.data.value.toString())));
                    }), $$Array.to_list(datas.bars)));
  };
  var isSigleBar = verticalChartData !== undefined ? verticalChartData.oldData.length === 0 || verticalChartData.newData.length === 0 : false;
  var layers = isSigleBar ? [
      "",
      "",
      "bars",
      singleBarVerticalLayer
    ] : (
      enableGridY ? [
          "grid",
          "axes",
          "bars",
          coupleBarVerticalLayer
        ] : [
          "",
          "",
          "bars",
          coupleBarVerticalLayer
        ]
    );
  var maxValue = maxValueOutside === 0 ? (
      Caml_obj.caml_greaterthan(Caml_array.get(rawVerticalData.totalValue, 0), Caml_array.get(rawVerticalData.totalValue, 1)) ? Caml_array.get(rawVerticalData.totalValue, 0) : Caml_array.get(rawVerticalData.totalValue, 1)
    ) : maxValueOutside;
  var tmp = {
    className: className,
    style: {
      height: height,
      userSelect: "none"
    }
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("div", tmp, React.createElement(Bar.ResponsiveBar, {
                  data: rawVerticalData.bars,
                  keys: rawVerticalData.keys,
                  layout: layout,
                  maxValue: maxValue,
                  valueScale: {
                    type: "linear"
                  },
                  indexScale: {
                    type: "band",
                    round: true
                  },
                  margin: margin,
                  padding: padding,
                  axisTop: null,
                  axisRight: null,
                  axisBottom: null,
                  axisLeft: {
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "",
                    legendPosition: "middle",
                    legendOffset: 0,
                    format: (function (data) {
                        return unitGridYValue + (" " + Utils$CTEM.shortNumberFromString(String(data)));
                      })
                  },
                  enableGridY: enableGridY,
                  gridXValues: false,
                  layers: layers,
                  enableLabel: enableLabel,
                  label: (function (data) {
                      return getLabel(data, maxValue);
                    }),
                  labelTextColor: "#ffffff",
                  labelSkipWidth: 12,
                  labelSkipHeight: 12,
                  borderRadius: borderRadius,
                  isInteractive: false,
                  animate: false,
                  motionStiffness: 90,
                  motionDamping: 15,
                  indexBy: "title",
                  colors: rawVerticalData.colors,
                  borderColor: {
                    from: "color",
                    modifiers: [[
                        "darker",
                        1.6
                      ]]
                  },
                  colorBy: colorBy
                }), verticalChartData !== undefined ? React.createElement(React.Fragment, {
                    children: null
                  }, React.createElement("h2", {
                        style: {
                          display: "inline-block"
                        }
                      }, Utils$CTEM.textWithNewLine(verticalChartData.topic)), React.createElement("p", undefined, React.createElement("i", {
                            className: "sub-title"
                          }, verticalChartData.subTitle)), verticalChartData.unitName !== "" ? React.createElement("p", {
                          style: {
                            fontSize: "12px",
                            marginLeft: "5px"
                          }
                        }, React.createElement("i", undefined, "(" + (verticalChartData.unitName + ")"))) : null) : null);
}

var make = StackedBarChart;

export {
  mapDatasIntoVericalChartDatas ,
  getLabel ,
  textElement ,
  lineElement ,
  fontWeightToString ,
  getTooltipDirection ,
  getTooltipColor ,
  make ,
  
}
/* react Not a pure module */
