// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as SectionBox$CTEM from "../../SectionBox.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Box from "@material-ui/core/Box";
import * as ModelInputHelper$CTEM from "../../../../Effects/ModelInputHelper.bs.js";
import Grid from "@material-ui/core/Grid";
import * as IMBRUVICA_PresentationTotalCostChart$CTEM from "./IMBRUVICA_PresentationTotalCostChart.bs.js";

function createCostEl(classNameOpt, currency, title, cost) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "edit-input-form " + className,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement("p", {
                                className: "input-form-title"
                              }, React.createElement("strong", undefined, title))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "text-right",
                      Caml_option.some(Utils$CTEM.Float.toLocaleStringUS(cost) + (" " + currency.code)),
                      undefined,
                      undefined
                    ])));
}

function createCostElFromObjectDataFloat(cal, currency, param) {
  return createCostEl(undefined, currency, param.title, Curry._1(cal, param.value));
}

function IMBRUVICA_PresentationTotalCost(Props) {
  var currency = Props.currency;
  var totalCostPerMonthPerPatient = Props.totalCostPerMonthPerPatient;
  var titleOpt = Props.title;
  var monthsOpt = Props.months;
  var patients = Props.patients;
  var goToChartView = Props.goToChartView;
  var title = titleOpt !== undefined ? titleOpt : "";
  var months = monthsOpt !== undefined ? monthsOpt : 6;
  var cal = function (param) {
    return IMBRUVICA_PresentationTotalCostChart$CTEM.calculateValue(months, patients, param);
  };
  var subtitle = IMBRUVICA_PresentationTotalCostChart$CTEM.getSubTitle(patients, months);
  return React.createElement(Box, {
              className: "height-100",
              alignItems: MaterialUIDataType.AlignItems.center,
              children: React.createElement(SectionBox$CTEM.make, {
                    title: title,
                    subtitle: subtitle,
                    className: "text-center",
                    wrapperChildrenclassName: "relative",
                    children: null
                  }, goToChartView !== undefined ? IMBRUVICA_PresentationTotalCostChart$CTEM.viewAsOtherViewType(/* Chart */1, goToChartView) : null, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            "text-left total-cost-table-container",
                            Caml_option.some($$Array.of_list(Belt_List.map(Belt_List.sort(totalCostPerMonthPerPatient.treatments, (function (a, b) {
                                                return Caml_primitive.caml_int_compare(a.id, b.id);
                                              })), (function (treatment) {
                                            return React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
                                                        title: treatment.treatmentName,
                                                        className: ModelInputHelper$CTEM.treatmentBgClassNameRaw(treatment.id),
                                                        children: null,
                                                        key: "imb-presentation-total-cost-" + (String(treatment.id) + ("-" + treatment.treatmentName))
                                                      }, createCostElFromObjectDataFloat(cal, currency, treatment.medical), createCostElFromObjectDataFloat(cal, currency, treatment.nonMedical), createCostElFromObjectDataFloat(cal, currency, treatment.adverseEvent), createCostElFromObjectDataFloat(cal, currency, treatment.indirect), createCostEl("total-costs-row", currency, "Total costs", IMBRUVICA_PresentationTotalCostChart$CTEM.calculateValue(months, patients, treatment.medical.value + treatment.nonMedical.value + treatment.adverseEvent.value + treatment.indirect.value)));
                                          })))),
                            undefined,
                            undefined
                          ])))
            });
}

var bgClassName = ModelInputHelper$CTEM.treatmentBgClassNameRaw;

var calculateValue = IMBRUVICA_PresentationTotalCostChart$CTEM.calculateValue;

var viewAsOtherViewType = IMBRUVICA_PresentationTotalCostChart$CTEM.viewAsOtherViewType;

var make = IMBRUVICA_PresentationTotalCost;

export {
  bgClassName ,
  calculateValue ,
  viewAsOtherViewType ,
  createCostEl ,
  createCostElFromObjectDataFloat ,
  make ,
  
}
/* react Not a pure module */
