// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as React from "react";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/lib/es6/src/Json_encode.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ModalError$CTEM from "../Components/ModalError.bs.js";
import * as ModalDialog$CTEM from "../Components/ModalDialog.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as RequestUtils$CTEM from "../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as InputComponent$CTEM from "../Components/InputComponent.bs.js";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

function ForgotPassPage(Props) {
  var emailOpt = Props.email;
  var tokenOpt = Props.token;
  var email = emailOpt !== undefined ? emailOpt : "";
  var token = tokenOpt !== undefined ? tokenOpt : "";
  var match = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* SetEmail */0 :
                return {
                        email: action._0,
                        password: state.password,
                        confirmPassword: state.confirmPassword,
                        token: state.token,
                        resetPassResult: state.resetPassResult,
                        confirmResetPassResult: state.confirmResetPassResult
                      };
            case /* SetPassword */1 :
                return {
                        email: state.email,
                        password: action._0,
                        confirmPassword: state.confirmPassword,
                        token: state.token,
                        resetPassResult: state.resetPassResult,
                        confirmResetPassResult: state.confirmResetPassResult
                      };
            case /* SetConfirmPassword */2 :
                return {
                        email: state.email,
                        password: state.password,
                        confirmPassword: action._0,
                        token: state.token,
                        resetPassResult: state.resetPassResult,
                        confirmResetPassResult: state.confirmResetPassResult
                      };
            case /* ResetPassResult */3 :
                return {
                        email: state.email,
                        password: state.password,
                        confirmPassword: state.confirmPassword,
                        token: state.token,
                        resetPassResult: action._0,
                        confirmResetPassResult: state.confirmResetPassResult
                      };
            case /* ConfirmResetPassResult */4 :
                return {
                        email: state.email,
                        password: state.password,
                        confirmPassword: state.confirmPassword,
                        token: state.token,
                        resetPassResult: state.resetPassResult,
                        confirmResetPassResult: action._0
                      };
            
          }
        }), {
        email: email,
        password: "",
        confirmPassword: "",
        token: token,
        resetPassResult: /* NotLoaded */0,
        confirmResetPassResult: /* NotLoaded */0
      });
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.email;
  var match$2 = state.token;
  var msg = state.resetPassResult;
  var tmp;
  tmp = typeof msg === "number" ? (
      msg === /* NotLoaded */0 ? null : React.createElement(ModalDialog$CTEM.make, {
              onHandleClose: (function (param) {
                  
                }),
              children: React.createElement(CircularProgress, {
                    color: MaterialUIDataType.MainWithInheritColor.inherit_
                  })
            })
    ) : (
      msg.TAG === /* LoadSuccess */0 ? React.createElement(ModalError$CTEM.make, {
              onHandleClose: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* ResetPassResult */3,
                              _0: /* NotLoaded */0
                            });
                }),
              msg: msg._0
            }) : React.createElement(ModalError$CTEM.make, {
              onHandleClose: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* ResetPassResult */3,
                              _0: /* NotLoaded */0
                            });
                }),
              msg: msg._0
            })
    );
  var msg$1 = state.confirmResetPassResult;
  var tmp$1;
  tmp$1 = typeof msg$1 === "number" ? (
      msg$1 === /* NotLoaded */0 ? null : React.createElement(ModalDialog$CTEM.make, {
              onHandleClose: (function (param) {
                  
                }),
              children: React.createElement(CircularProgress, {
                    color: MaterialUIDataType.MainWithInheritColor.inherit_
                  })
            })
    ) : (
      msg$1.TAG === /* LoadSuccess */0 ? React.createElement(ModalError$CTEM.make, {
              onHandleClose: (function (param) {
                  return ReasonReactRouter.push(UrlPath$CTEM.Route.getNonMemberPath(/* Login */0));
                }),
              msg: msg$1._0
            }) : React.createElement(ModalError$CTEM.make, {
              onHandleClose: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* ConfirmResetPassResult */4,
                              _0: /* NotLoaded */0
                            });
                }),
              msg: msg$1._0
            })
    );
  return React.createElement("div", {
              className: "forgot-pass-page"
            }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 2, undefined), [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      "forgot-pass-content text-center",
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement("h2", {
                                    className: "title"
                                  }, "Info Visualizer")),
                          undefined,
                          undefined
                        ])), match$1 !== "" && match$2 !== "" ? React.createElement(React.Fragment, {
                        children: null
                      }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement("h2", undefined, "Enter your new password.")),
                                undefined,
                                undefined
                              ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement(InputComponent$CTEM.make, {
                                          type: "password",
                                          label: "New Password",
                                          placeholder: "Your New Password",
                                          fullWidth: true,
                                          value: state.password,
                                          onChange: (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetPassword */1,
                                                          _0: Utils$CTEM.getValueFromReactEventForm($$event)
                                                        });
                                            })
                                        })),
                                undefined,
                                undefined
                              ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement(InputComponent$CTEM.make, {
                                          type: "password",
                                          label: "Confirm Password",
                                          placeholder: "Your Confirm Password",
                                          fullWidth: true,
                                          value: state.confirmPassword,
                                          onChange: (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetConfirmPassword */2,
                                                          _0: Utils$CTEM.getValueFromReactEventForm($$event)
                                                        });
                                            })
                                        })),
                                undefined,
                                undefined
                              ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement(InputComponent$CTEM.make, {
                                          label: "Email",
                                          placeholder: "Your Email",
                                          fullWidth: true,
                                          value: state.email,
                                          disabled: true
                                        })),
                                undefined,
                                undefined
                              ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement(Button, {
                                          children: "Reset Password",
                                          className: "red-button",
                                          disabled: state.password === "" || state.confirmPassword === "" || state.password !== state.confirmPassword || state.token === "",
                                          onClick: (function (param) {
                                              Curry._1(dispatch, {
                                                    TAG: /* ConfirmResetPassResult */4,
                                                    _0: /* Loading */1
                                                  });
                                              RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Post */2, {
                                                          "Content-Type": "application/json"
                                                        }, Caml_option.some(JSON.stringify(Json_encode.object_({
                                                                      hd: [
                                                                        "password",
                                                                        state.password
                                                                      ],
                                                                      tl: {
                                                                        hd: [
                                                                          "confirm_password",
                                                                          state.confirmPassword
                                                                        ],
                                                                        tl: {
                                                                          hd: [
                                                                            "token",
                                                                            state.token
                                                                          ],
                                                                          tl: {
                                                                            hd: [
                                                                              "email",
                                                                              state.email
                                                                            ],
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.confirmResetPasswordAPIPath), (function (json) {
                                                      return Curry._1(dispatch, {
                                                                  TAG: /* ConfirmResetPassResult */4,
                                                                  _0: {
                                                                    TAG: /* LoadSuccess */0,
                                                                    _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                                                  }
                                                                });
                                                    }), (function (json) {
                                                      return Curry._1(dispatch, {
                                                                  TAG: /* ConfirmResetPassResult */4,
                                                                  _0: {
                                                                    TAG: /* LoadFailed */1,
                                                                    _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                                                  }
                                                                });
                                                    }));
                                              
                                            })
                                        })),
                                undefined,
                                undefined
                              ]))) : React.createElement(React.Fragment, {
                        children: null
                      }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(null),
                                undefined,
                                undefined
                              ]), React.createElement("h2", undefined, "Forgot your password?"), React.createElement("h4", {
                                style: {
                                  marginBottom: "0px"
                                }
                              }, "Please enter your email."), React.createElement("h4", {
                                style: {
                                  marginTop: "0px"
                                }
                              }, "We will send you a password reset email.")), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement(InputComponent$CTEM.make, {
                                          label: "Email",
                                          placeholder: "Your Email",
                                          fullWidth: true,
                                          value: state.email,
                                          onChange: (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetEmail */0,
                                                          _0: Utils$CTEM.getValueFromReactEventForm($$event)
                                                        });
                                            })
                                        })),
                                undefined,
                                undefined
                              ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement(Button, {
                                          children: "Send",
                                          className: "red-button",
                                          disabled: state.email === "",
                                          onClick: (function (param) {
                                              Curry._1(dispatch, {
                                                    TAG: /* ResetPassResult */3,
                                                    _0: /* Loading */1
                                                  });
                                              RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Post */2, {
                                                          "Content-Type": "application/json"
                                                        }, Caml_option.some(JSON.stringify(Json_encode.object_({
                                                                      hd: [
                                                                        "email",
                                                                        state.email
                                                                      ],
                                                                      tl: /* [] */0
                                                                    }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.resetPasswordAPIPath), (function (json) {
                                                      return Curry._1(dispatch, {
                                                                  TAG: /* ResetPassResult */3,
                                                                  _0: {
                                                                    TAG: /* LoadSuccess */0,
                                                                    _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                                                  }
                                                                });
                                                    }), (function (json) {
                                                      return Curry._1(dispatch, {
                                                                  TAG: /* ResetPassResult */3,
                                                                  _0: {
                                                                    TAG: /* LoadFailed */1,
                                                                    _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                                                  }
                                                                });
                                                    }));
                                              
                                            })
                                        })),
                                undefined,
                                undefined
                              ]))), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement(Link, {
                                    color: "black",
                                    variant: MaterialUIDataType.TypographyStyle.subtitle1,
                                    children: React.createElement("u", {
                                          onClick: (function (param) {
                                              return ReasonReactRouter.push(UrlPath$CTEM.Route.getNonMemberPath(/* Login */0));
                                            })
                                        }, "Go back to Login")
                                  })),
                          undefined,
                          undefined
                        ]))), tmp, tmp$1);
}

var make = ForgotPassPage;

export {
  make ,
  
}
/* react Not a pure module */
