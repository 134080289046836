// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function managementTopicToString(topic) {
  switch (topic) {
    case /* ModelOverview */0 :
        return "Model Overview";
    case /* DirectMedical */1 :
        return "Direct: Medical";
    case /* DirectNonMedical */2 :
        return "Direct: Other Medical and Non-Medical";
    case /* DirectAdverseEvent */3 :
        return "Direct: Adverse Events";
    case /* IndirectCosts */4 :
        return "Indirect Costs";
    
  }
}

var Presentation = {
  colors: {
    hd: "#4699DB",
    tl: {
      hd: "#00CD8B",
      tl: {
        hd: "#55B538",
        tl: {
          hd: "#D55000",
          tl: /* [] */0
        }
      }
    }
  }
};

function getTreatmentName(param) {
  var treatmentName = param.treatmentName;
  if (param.id === 4 && treatmentName.value !== "") {
    return treatmentName.value;
  } else {
    return treatmentName.title;
  }
}

var Imbruvica_managementTopics = {
  hd: /* ModelOverview */0,
  tl: {
    hd: /* DirectMedical */1,
    tl: {
      hd: /* DirectNonMedical */2,
      tl: {
        hd: /* DirectAdverseEvent */3,
        tl: {
          hd: /* IndirectCosts */4,
          tl: /* [] */0
        }
      }
    }
  }
};

var Imbruvica = {
  adverseEventMonths: 6,
  managementTopics: Imbruvica_managementTopics,
  managementTopicToString: managementTopicToString,
  Presentation: Presentation,
  getTreatmentName: getTreatmentName
};

var laiManagementTopics = {
  hd: /* LAIModelOverview */0,
  tl: {
    hd: /* TargetedAndMS */1,
    tl: {
      hd: /* CIAndHRU */2,
      tl: {
        hd: /* DirectCosts */3,
        tl: {
          hd: /* IndirectCosts */4,
          tl: /* [] */0
        }
      }
    }
  }
};

function laiManagementTopicToString(topic) {
  switch (topic) {
    case /* LAIModelOverview */0 :
        return "Model Overview";
    case /* TargetedAndMS */1 :
        return "Targeted Schizophrenia Patients & Market Share";
    case /* CIAndHRU */2 :
        return "Clinical Inputs & Healthcare Resource Utilization";
    case /* DirectCosts */3 :
        return "Drug Costs";
    case /* IndirectCosts */4 :
        return "Other Costs";
    
  }
}

function getManagementTopicsFromTemplate(modeltTemplate) {
  if (modeltTemplate.TAG === /* TemplateLAI */0) {
    return laiManagementTopics;
  } else {
    return /* [] */0;
  }
}

var slideController = (function(isVertical, currentSlide, startAxis, touchEvent) {
    if (touchEvent.type == "touchstart") {
      if (touchEvent.touches.length > 0) {
        var screenAxis = (isVertical ? touchEvent.touches[0].screenY : touchEvent.touches[0].screenX)
        return screenAxis;
      }
    } else {
      if (touchEvent.changedTouches.length > 0) {
        var endAxis = (isVertical ? touchEvent.changedTouches[0].screenY : touchEvent.changedTouches[0].screenX);
        if (endAxis - startAxis >= 100) {
          return currentSlide - 1;
        } else if (startAxis - endAxis >= 100) {
          return currentSlide + 1;
        }
        return currentSlide;
      }
    }
    return -1;
  });

function mapMIDStringToType(midString) {
  var match = midString.toLowerCase();
  switch (match) {
    case "imbruvica_cost_consequence" :
        return /* IMBRUVICA */1;
    case "lai_1_3_m" :
        return /* LAI */0;
    default:
      return /* LAI */0;
  }
}

function sumFloatValue(objectDataFloatList) {
  return Belt_List.reduce(objectDataFloatList, 0, (function (acc, param) {
                return acc + param.value;
              }));
}

function sumIntValue(objectDataIntList) {
  return Belt_List.reduce(objectDataIntList, 0, (function (acc, param) {
                return acc + param.value | 0;
              }));
}

function getExchangeRate(workData) {
  return Belt_Option.getWithDefault(workData.exchangeRate, 1);
}

var drugMSDefault = {
  oralKey: "",
  laiKey: "",
  withoutLAI: {
    title: "",
    value: 0,
    source: ""
  },
  withLAI: {
    title: "",
    value: 0,
    source: ""
  }
};

export {
  Imbruvica ,
  drugMSDefault ,
  laiManagementTopics ,
  laiManagementTopicToString ,
  getManagementTopicsFromTemplate ,
  slideController ,
  mapMIDStringToType ,
  sumFloatValue ,
  sumIntValue ,
  getExchangeRate ,
  
}
/* No side effect */
