// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as TopicButton$CTEM from "../../../TopicButton.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";

function IMBRUVICA_PresentationIntro(Props) {
  var patients = Props.patients;
  var months = Props.months;
  var currency = Props.currency;
  var onChangeSlide = Props.onChangeSlide;
  var monthString = Utils$CTEM.pluralWorldSimple(undefined, undefined, months, "month");
  return React.createElement("div", {
              className: "lai-intro-section"
            }, React.createElement("div", {
                  className: "intro-detail",
                  style: {
                    paddingTop: "2%"
                  }
                }, React.createElement("div", {
                      style: {
                        display: "inline-block"
                      }
                    }, React.createElement("h3", {
                          className: "intro-header"
                        }, "Illustration of total costs per cohort for " + monthString), React.createElement("div", undefined, "No. of Patients: " + String(patients), React.createElement("br", undefined), "Currency: " + currency))), React.createElement("div", {
                  className: "lai-intro-topics"
                }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          "topic-buttons-container",
                          Caml_option.some(null),
                          undefined,
                          undefined
                        ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(TopicButton$CTEM.make, {
                                        title: "Overview".toUpperCase(),
                                        topicIcon: /* BarChart */47,
                                        onClick: (function (param) {
                                            return Curry._1(onChangeSlide, /* TotalCostChartByCostType */1);
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(TopicButton$CTEM.make, {
                                        title: ("Total Costs " + monthString).toUpperCase(),
                                        topicIcon: /* Calendar6Months */49,
                                        onClick: (function (param) {
                                            return Curry._1(onChangeSlide, /* TotalCostPerCohortChart */2);
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(TopicButton$CTEM.make, {
                                        title: "Total Costs per month".toUpperCase(),
                                        topicIcon: /* Calendar1Month */48,
                                        onClick: (function (param) {
                                            return Curry._1(onChangeSlide, /* TotalCostPerPatientChart */4);
                                          })
                                      })),
                              undefined,
                              undefined
                            ])))));
}

var make = IMBRUVICA_PresentationIntro;

export {
  make ,
  
}
/* react Not a pure module */
