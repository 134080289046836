// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as React from "react";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/lib/es6/src/Json_encode.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as DataTypes$CTEM from "../DataTypes.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ModalError$CTEM from "../Components/ModalError.bs.js";
import * as ModalDialog$CTEM from "../Components/ModalDialog.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as RequestUtils$CTEM from "../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as InputComponent$CTEM from "../Components/InputComponent.bs.js";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

function encodeLogin(username, password) {
  return Json_encode.object_({
              hd: [
                "username",
                username
              ],
              tl: {
                hd: [
                  "password",
                  password
                ],
                tl: /* [] */0
              }
            });
}

function LoginPage(Props) {
  var match = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* SetUsername */0 :
                return {
                        username: action._0,
                        password: state.password,
                        loginResult: state.loginResult
                      };
            case /* SetPassword */1 :
                return {
                        username: state.username,
                        password: action._0,
                        loginResult: state.loginResult
                      };
            case /* SetLoginResult */2 :
                return {
                        username: state.username,
                        password: state.password,
                        loginResult: action._0
                      };
            
          }
        }), {
        username: "",
        password: "",
        loginResult: /* NotLoaded */0
      });
  var dispatch = match[1];
  var state = match[0];
  var msg = state.loginResult;
  var tmp;
  tmp = typeof msg === "number" ? (
      msg === /* NotLoaded */0 ? null : React.createElement(ModalDialog$CTEM.make, {
              onHandleClose: (function (param) {
                  
                }),
              children: React.createElement(CircularProgress, {
                    color: MaterialUIDataType.MainWithInheritColor.inherit_
                  })
            })
    ) : (
      msg.TAG === /* LoadSuccess */0 ? null : React.createElement(ModalError$CTEM.make, {
              onHandleClose: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* SetLoginResult */2,
                              _0: /* NotLoaded */0
                            });
                }),
              msg: msg._0
            })
    );
  return React.createElement("div", {
              className: "login-page"
            }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 2, undefined), [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      "login-content text-center",
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement("h2", {
                                    className: "title"
                                  }, "Info Visualizer")),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement(InputComponent$CTEM.make, {
                                    label: "User Name",
                                    placeholder: "Your User Name",
                                    fullWidth: true,
                                    value: state.username,
                                    onChange: (function ($$event) {
                                        return Curry._1(dispatch, {
                                                    TAG: /* SetUsername */0,
                                                    _0: Utils$CTEM.getValueFromReactEventForm($$event)
                                                  });
                                      })
                                  })),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement(InputComponent$CTEM.make, {
                                    type: "password",
                                    label: "Password",
                                    placeholder: "Your Password",
                                    fullWidth: true,
                                    value: state.password,
                                    onChange: (function ($$event) {
                                        return Curry._1(dispatch, {
                                                    TAG: /* SetPassword */1,
                                                    _0: Utils$CTEM.getValueFromReactEventForm($$event)
                                                  });
                                      })
                                  })),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement(Button, {
                                    children: "Login",
                                    className: "red-button",
                                    disabled: state.username === "" || state.password === "",
                                    onClick: (function (param) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetLoginResult */2,
                                              _0: /* Loading */1
                                            });
                                        RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Post */2, {
                                                    "Content-Type": "application/json"
                                                  }, Caml_option.some(JSON.stringify(encodeLogin(state.username, state.password))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.loginAPIPath), (function (json) {
                                                var userProfile = DataTypes$CTEM.Decode.userProfile(json);
                                                localStorage.setItem("token", userProfile.token);
                                                Curry._1(dispatch, {
                                                      TAG: /* SetLoginResult */2,
                                                      _0: {
                                                        TAG: /* LoadSuccess */0,
                                                        _0: ""
                                                      }
                                                    });
                                                return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath(/* SelectModel */0));
                                              }), (function (json) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetLoginResult */2,
                                                            _0: {
                                                              TAG: /* LoadFailed */1,
                                                              _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                                            }
                                                          });
                                              }));
                                        
                                      })
                                  })),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement(Link, {
                                    color: "black",
                                    variant: MaterialUIDataType.TypographyStyle.subtitle1,
                                    children: React.createElement("u", {
                                          onClick: (function (param) {
                                              return ReasonReactRouter.push(UrlPath$CTEM.Route.getNonMemberPath(/* ForgotPassword */1));
                                            })
                                        }, "Forgot your password?")
                                  })),
                          undefined,
                          undefined
                        ]))), tmp);
}

var make = LoginPage;

export {
  encodeLogin ,
  make ,
  
}
/* react Not a pure module */
