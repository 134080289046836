// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/lib/es6/src/Json_encode.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as CtemIcon$CTEM from "./CtemIcon.bs.js";
import * as ModalError$CTEM from "./ModalError.bs.js";
import * as IconButton2$CTEM from "./MuiComponents/IconButton2.bs.js";
import * as ModalDialog$CTEM from "./ModalDialog.bs.js";
import * as ModalRemove$CTEM from "./ModalRemove.bs.js";
import * as ModalRename$CTEM from "./ModalRename.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as RequestUtils$CTEM from "../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as TemplateUtils$CTEM from "./ModelTemplates/TemplateUtils.bs.js";
import * as ModalItemGroup$CTEM from "./ModalItemGroup.bs.js";
import * as PresentationPage$CTEM from "../Pages/PresentationPage.bs.js";
import * as SavedWorksContext$CTEM from "../Contexts/SavedWorksContext.bs.js";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

var setRatio = (function(popperId, clearAnchorEl) {
    setTimeout(function(){
      var workboxPopper = document.getElementById(popperId);
      var modalContent = document.getElementsByClassName("modal-content")[0];
      var scale = localStorage.getItem('scaleRatio');
      var bodyHeight = document.body.offsetHeight;

      if (workboxPopper && modalContent) {
        var style = window.getComputedStyle(workboxPopper);
        var matrix = new WebKitCSSMatrix(style.transform);
        var modalHeight = modalContent.offsetHeight;

        if ((modalContent.offsetHeight * scale) + matrix.m42 > document.body.offsetHeight) {
          var translateY = matrix.m42 - ((modalContent.offsetHeight * scale) + matrix.m42 - document.body.offsetHeight);
          workboxPopper.style.transform = "translate3d(" + matrix.m41 + "px, " + translateY + "px, " + matrix.m43 + "px) scale(" + scale + ")";
        } else {
          workboxPopper.style.transform = workboxPopper.style.transform + " scale(" + scale + ")";
        }
        workboxPopper.style.opacity = 1;
      }
      document.getElementsByClassName("homepage-content")[0].addEventListener('scroll', function(event) {
        event.preventDefault();
        event.stopPropagation();
        clearAnchorEl();
      }, true);

    }, 0);
  });

function WorkBox$ActionIcon(Props) {
  var workId = Props.workId;
  var onRename = Props.onRename;
  var onDelete = Props.onDelete;
  var onEdit = Props.onEdit;
  var match = React.useState(function () {
        return null;
      });
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var clearAnchorEl = function (param) {
    return Curry._1(setAnchorEl, (function (param) {
                  return null;
                }));
  };
  var popperId = "popper-action-" + workId;
  var modalItems_0 = {
    title: "Rename",
    color: "black",
    onClick: (function (param) {
        Curry._1(setAnchorEl, (function (param) {
                return null;
              }));
        return Curry._1(onRename, undefined);
      })
  };
  var modalItems_1 = {
    hd: {
      title: "Edit Data",
      color: "black",
      onClick: (function (param) {
          return Curry._1(onEdit, undefined);
        })
    },
    tl: {
      hd: {
        title: "Delete",
        color: "red",
        onClick: (function (param) {
            Curry._1(setAnchorEl, (function (param) {
                    return null;
                  }));
            return Curry._1(onDelete, undefined);
          })
      },
      tl: /* [] */0
    }
  };
  var modalItems = {
    hd: modalItems_0,
    tl: modalItems_1
  };
  var onClick = function (e) {
    e.stopPropagation();
    var target = e.currentTarget;
    if (anchorEl == null) {
      Curry._1(setAnchorEl, (function (param) {
              return target;
            }));
      return setRatio(popperId, clearAnchorEl);
    } else {
      return Curry._1(setAnchorEl, (function (param) {
                    return null;
                  }));
    }
  };
  return React.createElement(ClickAwayListener, {
              children: React.createElement("div", {
                    className: "workbox-detail-col right"
                  }, React.createElement(IconButton, IconButton2$CTEM.makeProps(undefined, undefined, undefined, undefined, undefined, onClick, undefined, undefined, undefined, undefined, Caml_option.some(React.createElement(CtemIcon$CTEM.make, {
                                    iconType: /* More */12,
                                    className: "icon-solid-color-grey"
                                  })), popperId, undefined)), React.createElement(Popper, {
                        id: popperId,
                        className: "workbox-popper",
                        anchorEl: anchorEl,
                        children: React.createElement(ModalItemGroup$CTEM.make, {
                              modalItems: modalItems,
                              onCancel: clearAnchorEl
                            }),
                        modifiers: {
                          flip: {
                            enabled: true
                          },
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent"
                          }
                        },
                        open: !(anchorEl == null)
                      })),
              onClickAway: (function (param) {
                  if (!(anchorEl == null)) {
                    return Curry._1(setAnchorEl, (function (param) {
                                  return null;
                                }));
                  }
                  
                })
            });
}

var ActionIcon = {
  make: WorkBox$ActionIcon
};

function WorkBox(Props) {
  var savedWork = Props.savedWork;
  var onClick = Props.onClick;
  var match = SavedWorksContext$CTEM.useSavedWorksResults(undefined);
  var fetchData = match.fetchData;
  var match$1 = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* SetActionMode */0 :
                return {
                        actionMode: action._0,
                        deleteResult: state.deleteResult,
                        renameResult: state.renameResult
                      };
            case /* SetDeleteResult */1 :
                return {
                        actionMode: state.actionMode,
                        deleteResult: action._0,
                        renameResult: state.renameResult
                      };
            case /* SetRenameResult */2 :
                return {
                        actionMode: state.actionMode,
                        deleteResult: state.deleteResult,
                        renameResult: action._0
                      };
            
          }
        }), {
        actionMode: /* Idle */0,
        deleteResult: /* NotLoaded */0,
        renameResult: /* NotLoaded */0
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var requestUpdateFilename = function (filename) {
    Curry._1(dispatch, {
          TAG: /* SetRenameResult */2,
          _0: /* Loading */1
        });
    if (savedWork !== undefined) {
      Curry._1(dispatch, {
            TAG: /* SetDeleteResult */1,
            _0: /* Loading */1
          });
      RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Patch */8, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), Caml_option.some(JSON.stringify(Json_encode.object_({
                              hd: [
                                "save_name",
                                filename
                              ],
                              tl: /* [] */0
                            }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.fileIdPath(String(savedWork.id))), (function (_json) {
              Curry._1(dispatch, {
                    TAG: /* SetActionMode */0,
                    _0: /* Idle */0
                  });
              return Curry._1(fetchData, true);
            }), (function (json) {
              return Curry._1(dispatch, {
                          TAG: /* SetRenameResult */2,
                          _0: /* LoadFailed */{
                            _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                          }
                        });
            }));
      return ;
    }
    
  };
  React.useEffect((function () {
          
        }), [state.actionMode]);
  var tmp = {
    className: "workbox-container" + (
      savedWork !== undefined ? "" : " add-new"
    )
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  var match$2 = state.actionMode;
  var tmp$1;
  if (savedWork !== undefined) {
    var fileName = savedWork.fileName;
    switch (match$2) {
      case /* Idle */0 :
          tmp$1 = null;
          break;
      case /* Rename */1 :
          var msg = state.renameResult;
          tmp$1 = typeof msg === "number" ? React.createElement(ModalRename$CTEM.make, {
                  name: fileName,
                  onHandleClose: (function (param) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetActionMode */0,
                                  _0: /* Idle */0
                                });
                    }),
                  onUpdate: requestUpdateFilename,
                  loadingState: state.renameResult
                }) : React.createElement(ModalError$CTEM.make, {
                  onHandleClose: (function (param) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetRenameResult */2,
                                  _0: /* NotLoaded */0
                                });
                    }),
                  msg: msg._0
                });
          break;
      case /* Delete */2 :
          var msg$1 = state.deleteResult;
          if (typeof msg$1 === "number") {
            switch (msg$1) {
              case /* NotLoaded */0 :
                  tmp$1 = React.createElement(ModalRemove$CTEM.make, {
                        onHandleClose: (function (param) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetActionMode */0,
                                        _0: /* Idle */0
                                      });
                          }),
                        onConfirm: (function (param) {
                            if (savedWork !== undefined) {
                              Curry._1(dispatch, {
                                    TAG: /* SetDeleteResult */1,
                                    _0: /* Loading */1
                                  });
                              RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Delete */4, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.fileIdPath(String(savedWork.id))), (function (_json) {
                                      Curry._1(dispatch, {
                                            TAG: /* SetActionMode */0,
                                            _0: /* Idle */0
                                          });
                                      return Curry._1(fetchData, true);
                                    }), (function (json) {
                                      return Curry._1(dispatch, {
                                                  TAG: /* SetDeleteResult */1,
                                                  _0: /* LoadFailed */{
                                                    _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                                  }
                                                });
                                    }));
                              return ;
                            }
                            
                          })
                      });
                  break;
              case /* Loading */1 :
                  tmp$1 = React.createElement(ModalDialog$CTEM.make, {
                        onHandleClose: (function (param) {
                            
                          }),
                        children: React.createElement(CircularProgress, {
                              color: MaterialUIDataType.MainWithInheritColor.inherit_
                            })
                      });
                  break;
              case /* LoadSuccess */2 :
                  tmp$1 = React.createElement(ModalDialog$CTEM.make, {
                        onHandleClose: (function (param) {
                            Curry._1(dispatch, {
                                  TAG: /* SetActionMode */0,
                                  _0: /* Idle */0
                                });
                            return Curry._1(dispatch, {
                                        TAG: /* SetDeleteResult */1,
                                        _0: /* NotLoaded */0
                                      });
                          }),
                        children: React.createElement("p", undefined, "Delete " + fileName + " Successfully")
                      });
                  break;
              
            }
          } else {
            tmp$1 = React.createElement(ModalError$CTEM.make, {
                  onHandleClose: (function (param) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetDeleteResult */1,
                                  _0: /* NotLoaded */0
                                });
                    }),
                  msg: msg$1._0
                });
          }
          break;
      
    }
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement("div", tmp, savedWork !== undefined ? React.createElement(React.Fragment, {
                        children: null
                      }, React.createElement("div", {
                            className: "workbox-image"
                          }, React.createElement(PresentationPage$CTEM.make, {
                                work: savedWork
                              })), React.createElement("div", {
                            className: "workbox-detail"
                          }, React.createElement("div", {
                                className: "workbox-detail-col left"
                              }, React.createElement("h3", undefined, savedWork.fileName), React.createElement("p", undefined, savedWork.modelName), React.createElement("span", undefined, savedWork.lastUpdate.toLocaleDateString())), React.createElement(WorkBox$ActionIcon, {
                                workId: savedWork.id,
                                onRename: (function (param) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetActionMode */0,
                                                _0: /* Rename */1
                                              });
                                  }),
                                onDelete: (function (param) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetActionMode */0,
                                                _0: /* Delete */2
                                              });
                                  }),
                                onEdit: (function (param) {
                                    return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath({
                                                    TAG: /* EditWork */2,
                                                    _0: TemplateUtils$CTEM.mapMIDStringToType(savedWork.mid),
                                                    _1: String(savedWork.id)
                                                  }));
                                  })
                              }))) : React.createElement("div", {
                        className: "add-new-content"
                      }, React.createElement("i", {
                            className: "fas fa-plus"
                          }), React.createElement(CtemIcon$CTEM.make, {
                            iconType: /* Plus */5
                          }), React.createElement("h3", undefined, "Add New"))), tmp$1);
}

var make = WorkBox;

export {
  setRatio ,
  ActionIcon ,
  make ,
  
}
/* react Not a pure module */
