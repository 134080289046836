// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as React from "react";
import * as Apis$CTEM from "../Effects/Apis.bs.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/lib/es6/src/Json_encode.bs.js";
import * as TopBar$CTEM from "../Components/TopBar.bs.js";
import * as Encoder$CTEM from "../Effects/Encoder.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as DataTypes$CTEM from "../DataTypes.bs.js";
import * as EncodeLAI$CTEM from "../Components/ModelTemplates/LAI/EncodeLAI.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ModalError$CTEM from "../Components/ModalError.bs.js";
import * as ModalRename$CTEM from "../Components/ModalRename.bs.js";
import * as ModalRestore$CTEM from "../Components/ModalRestore.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as RequestUtils$CTEM from "../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as TemplateUtils$CTEM from "../Components/ModelTemplates/TemplateUtils.bs.js";
import * as SavedWorkContext$CTEM from "../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";
import * as SavedWorksContext$CTEM from "../Contexts/SavedWorksContext.bs.js";
import Replay from "@material-ui/icons/Replay";
import * as LAIManagementWrapper$CTEM from "../Components/ModelTemplates/LAI/ManagementMenus/LAIManagementWrapper.bs.js";
import * as IMBRUVICA_ManagementWrapper$CTEM from "../Components/ModelTemplates/IMBRUVICA/IMBRUVICA_ManagementWrapper.bs.js";
import AirplayRounded from "@material-ui/icons/AirplayRounded";
import * as CurrencyExchangeRatesContext$CTEM from "../Contexts/CurrencyExchangeRatesContext.bs.js";
import CircularProgress from "@material-ui/core/CircularProgress";

function topbarToolEl(onClick, content, tooltip) {
  return React.createElement("div", {
              style: {
                display: "inline-block",
                height: "90%"
              },
              title: tooltip,
              onClick: onClick
            }, content);
}

function ModelManagementPage(Props) {
  var work = Props.work;
  var match = SavedWorksContext$CTEM.useSavedWorksResults(undefined);
  var fetchData = match.fetchData;
  var match$1 = CurrencyExchangeRatesContext$CTEM.useExchangeRatesResults(undefined);
  var fetchExchangeRates = match$1.fetchData;
  var exchangeRateResults = match$1.value;
  var match$2 = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* OnChangeMenu */0 :
                return {
                        mode: state.mode,
                        renameResult: state.renameResult,
                        restoreResult: state.restoreResult,
                        currentWork: state.currentWork,
                        currentMenuIndex: action._0,
                        savedWorkResult: state.savedWorkResult,
                        defaultWorkResult: state.defaultWorkResult
                      };
            case /* OnChangeMode */1 :
                return {
                        mode: action._0,
                        renameResult: state.renameResult,
                        restoreResult: state.restoreResult,
                        currentWork: state.currentWork,
                        currentMenuIndex: state.currentMenuIndex,
                        savedWorkResult: state.savedWorkResult,
                        defaultWorkResult: state.defaultWorkResult
                      };
            case /* UpdateCurrentWork */2 :
                return {
                        mode: state.mode,
                        renameResult: state.renameResult,
                        restoreResult: state.restoreResult,
                        currentWork: action._0,
                        currentMenuIndex: state.currentMenuIndex,
                        savedWorkResult: state.savedWorkResult,
                        defaultWorkResult: state.defaultWorkResult
                      };
            case /* SetRenameResult */3 :
                return {
                        mode: state.mode,
                        renameResult: action._0,
                        restoreResult: state.restoreResult,
                        currentWork: state.currentWork,
                        currentMenuIndex: state.currentMenuIndex,
                        savedWorkResult: state.savedWorkResult,
                        defaultWorkResult: state.defaultWorkResult
                      };
            case /* SetRestoreResult */4 :
                return {
                        mode: state.mode,
                        renameResult: state.renameResult,
                        restoreResult: action._0,
                        currentWork: state.currentWork,
                        currentMenuIndex: state.currentMenuIndex,
                        savedWorkResult: state.savedWorkResult,
                        defaultWorkResult: state.defaultWorkResult
                      };
            case /* SetSavedWorkResult */5 :
                return {
                        mode: state.mode,
                        renameResult: state.renameResult,
                        restoreResult: state.restoreResult,
                        currentWork: state.currentWork,
                        currentMenuIndex: state.currentMenuIndex,
                        savedWorkResult: action._0,
                        defaultWorkResult: state.defaultWorkResult
                      };
            case /* SetDefaultWorkResult */6 :
                return {
                        mode: state.mode,
                        renameResult: state.renameResult,
                        restoreResult: state.restoreResult,
                        currentWork: state.currentWork,
                        currentMenuIndex: state.currentMenuIndex,
                        savedWorkResult: state.savedWorkResult,
                        defaultWorkResult: action._0
                      };
            
          }
        }), {
        mode: /* Idle */0,
        renameResult: /* NotLoaded */0,
        restoreResult: /* NotLoaded */0,
        currentWork: work,
        currentMenuIndex: 0,
        savedWorkResult: /* NotLoaded */0,
        defaultWorkResult: /* NotLoaded */0
      });
  var dispatch = match$2[1];
  var state = match$2[0];
  var requestUpdateFilename = function (filename) {
    Curry._1(dispatch, {
          TAG: /* SetRenameResult */3,
          _0: /* Loading */1
        });
    RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Patch */8, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), Caml_option.some(JSON.stringify(Json_encode.object_({
                            hd: [
                              "save_name",
                              filename
                            ],
                            tl: /* [] */0
                          }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.fileIdPath(String(work.id))), (function (_json) {
            return Curry._1(fetchData, true);
          }), (function (json) {
            return Curry._1(dispatch, {
                        TAG: /* SetRenameResult */3,
                        _0: /* LoadFailed */{
                          _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                        }
                      });
          }));
    
  };
  var requestRestoreWork = function (param) {
    var partial_arg = work.id;
    var func = function (param, param$1, param$2) {
      return Apis$CTEM.requestUpdateSavedWork(partial_arg, param, param$1, param$2);
    };
    var arg = function (json) {
      var responseWork = DataTypes$CTEM.Decode.work(json);
      Curry._1(dispatch, {
            TAG: /* SetSavedWorkResult */5,
            _0: {
              TAG: /* LoadSuccess */0,
              _0: responseWork
            }
          });
      Curry._1(dispatch, {
            TAG: /* UpdateCurrentWork */2,
            _0: responseWork
          });
      Curry._1(dispatch, {
            TAG: /* OnChangeMode */1,
            _0: /* Idle */0
          });
      return Curry._1(dispatch, {
                  TAG: /* SetRestoreResult */4,
                  _0: /* NotLoaded */0
                });
    };
    var arg$1 = function (json) {
      return Curry._1(dispatch, {
                  TAG: /* SetRestoreResult */4,
                  _0: /* LoadFailed */{
                    _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                  }
                });
    };
    var imbCallRequestRestoreWork = function (rates, defaultImbruvicaData) {
      return Curry._3(func, Encoder$CTEM.Imbruvica.encoder(Utils$CTEM.Currency.getRateTargetCurrencyByContryCode(rates, work.currency.code), defaultImbruvicaData), arg, arg$1);
    };
    Curry._1(dispatch, {
          TAG: /* SetRestoreResult */4,
          _0: /* Loading */1
        });
    var match = state.defaultWorkResult;
    if (typeof match === "number") {
      return Curry._1(dispatch, {
                  TAG: /* SetRestoreResult */4,
                  _0: /* LoadFailed */{
                    _0: Utils$CTEM.defaultErrorMessage
                  }
                });
    }
    if (match.TAG !== /* LoadSuccess */0) {
      return Curry._1(dispatch, {
                  TAG: /* SetRestoreResult */4,
                  _0: /* LoadFailed */{
                    _0: Utils$CTEM.defaultErrorMessage
                  }
                });
    }
    var match$1 = match._0.templateData;
    if (match$1 === undefined) {
      return Curry._1(dispatch, {
                  TAG: /* SetRestoreResult */4,
                  _0: /* LoadFailed */{
                    _0: Utils$CTEM.defaultErrorMessage
                  }
                });
    }
    if (match$1.TAG === /* TemplateLAI */0) {
      return Curry._3(func, EncodeLAI$CTEM.laiData(match$1._0), arg, arg$1);
    }
    var defaultImbruvicaData = match$1._0;
    if (typeof exchangeRateResults !== "number" && exchangeRateResults.TAG === /* LoadSuccess */0) {
      return imbCallRequestRestoreWork(exchangeRateResults._0.rates, defaultImbruvicaData);
    }
    return Curry._2(fetchExchangeRates, (function (exchangeRatesOpt) {
                  if (exchangeRatesOpt !== undefined) {
                    return imbCallRequestRestoreWork(exchangeRatesOpt.rates, defaultImbruvicaData);
                  } else {
                    return Curry._1(dispatch, {
                                TAG: /* SetRestoreResult */4,
                                _0: /* LoadFailed */{
                                  _0: "Can not fetch currency exchange rates, please try again"
                                }
                              });
                  }
                }), undefined);
  };
  var requestSavedWorkFormId = function (reload) {
    return Utils$CTEM.loadDataIfNone(state.savedWorkResult, reload, (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* SetSavedWorkResult */5,
                        _0: /* Loading */1
                      });
                  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Get */0, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.savedWorkAPIPath(String(work.id))), (function (json) {
                          var match = state.mode;
                          if (match >= 3) {
                            Utils$CTEM.refreshPage(undefined);
                          }
                          var responseWork = DataTypes$CTEM.Decode.work(json);
                          Curry._1(dispatch, {
                                TAG: /* SetSavedWorkResult */5,
                                _0: {
                                  TAG: /* LoadSuccess */0,
                                  _0: responseWork
                                }
                              });
                          Curry._1(dispatch, {
                                TAG: /* UpdateCurrentWork */2,
                                _0: responseWork
                              });
                          var _defaultWork = state.defaultWorkResult;
                          if (typeof _defaultWork === "number" || _defaultWork.TAG !== /* LoadSuccess */0) {
                            Curry._1(dispatch, {
                                  TAG: /* SetDefaultWorkResult */6,
                                  _0: /* Loading */1
                                });
                            RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Get */0, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.masterTemplateAPIPath(responseWork.mid, responseWork.country)), (function (json) {
                                    var responseWork = DataTypes$CTEM.Decode.work(json);
                                    return Curry._1(dispatch, {
                                                TAG: /* SetDefaultWorkResult */6,
                                                _0: {
                                                  TAG: /* LoadSuccess */0,
                                                  _0: responseWork
                                                }
                                              });
                                  }), (function (json) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetDefaultWorkResult */6,
                                                _0: {
                                                  TAG: /* LoadFailed */1,
                                                  _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                                }
                                              });
                                  }));
                            return ;
                          }
                          
                        }), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSavedWorkResult */5,
                                      _0: {
                                        TAG: /* LoadFailed */1,
                                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                      }
                                    });
                        }));
                  
                }));
  };
  React.useEffect((function () {
          requestSavedWorkFormId(false);
          var match = work.templateData;
          if (match !== undefined && match.TAG !== /* TemplateLAI */0) {
            Curry._2(fetchExchangeRates, undefined, undefined);
          }
          
        }), []);
  React.useEffect((function () {
          Curry._1(dispatch, {
                TAG: /* UpdateCurrentWork */2,
                _0: work
              });
          
        }), [work]);
  var match$3 = TemplateUtils$CTEM.mapMIDStringToType(work.mid);
  var msg = state.renameResult;
  var msg$1 = state.restoreResult;
  var match$4 = state.mode;
  var tmp;
  switch (match$4) {
    case /* Idle */0 :
        tmp = null;
        break;
    case /* Rename */1 :
        tmp = React.createElement(ModalRename$CTEM.make, {
              name: state.currentWork.fileName,
              onHandleClose: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* OnChangeMode */1,
                              _0: /* Idle */0
                            });
                }),
              onUpdate: requestUpdateFilename,
              loadingState: state.renameResult
            });
        break;
    case /* Restore */2 :
        tmp = React.createElement(ModalRestore$CTEM.make, {
              onHandleClose: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* OnChangeMode */1,
                              _0: /* Idle */0
                            });
                }),
              onRestore: requestRestoreWork,
              loadingState: state.restoreResult
            });
        break;
    case /* Error */3 :
        var errMsg = state.savedWorkResult;
        tmp = typeof errMsg === "number" || errMsg.TAG !== /* LoadFailed */1 ? null : React.createElement(ModalError$CTEM.make, {
                open: true,
                onHandleClose: (function (param) {
                    return requestSavedWorkFormId(true);
                  }),
                msg: errMsg._0
              });
        break;
    
  }
  var defaultWork = state.defaultWorkResult;
  var tmp$1;
  if (typeof defaultWork === "number") {
    tmp$1 = defaultWork === /* NotLoaded */0 ? null : React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(Caml_option.some(MaterialUIDataType.FlexDirection.column), 3, undefined), [
                undefined,
                undefined,
                Caml_option.some(MaterialUIDataType.AlignItems.center),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(CircularProgress, {
                                        color: MaterialUIDataType.MainWithInheritColor.inherit_
                                      })),
                              undefined,
                              undefined
                            ]))),
                undefined,
                undefined
              ]));
  } else if (defaultWork.TAG === /* LoadSuccess */0) {
    var match$5 = defaultWork._0.templateData;
    var match$6 = state.currentWork.templateData;
    var tmp$2;
    tmp$2 = match$5 !== undefined ? (
        match$5.TAG === /* TemplateLAI */0 ? (
            match$6 !== undefined && match$6.TAG === /* TemplateLAI */0 ? React.createElement(LAIManagementWrapper$CTEM.make, {
                    workId: work.id,
                    currency: work.currency,
                    defaultData: match$5._0,
                    currentData: match$6._0
                  }) : null
          ) : (
            match$6 !== undefined && match$6.TAG !== /* TemplateLAI */0 ? React.createElement(IMBRUVICA_ManagementWrapper$CTEM.make, {
                    work: work,
                    defaultData: match$5._0,
                    currentData: match$6._0
                  }) : null
          )
      ) : null;
    tmp$1 = React.createElement(SavedWorkContext$CTEM.Provider.make, SavedWorkContext$CTEM.Provider.makeProps({
              value: state.savedWorkResult,
              fetchData: requestSavedWorkFormId,
              updateData: (function (results) {
                  Curry._1(dispatch, {
                        TAG: /* SetSavedWorkResult */5,
                        _0: results
                      });
                  if (typeof results === "number") {
                    return ;
                  } else if (results.TAG === /* LoadSuccess */0) {
                    return Curry._1(dispatch, {
                                TAG: /* UpdateCurrentWork */2,
                                _0: results._0
                              });
                  } else {
                    return Curry._1(dispatch, {
                                TAG: /* OnChangeMode */1,
                                _0: /* Error */3
                              });
                  }
                })
            }, tmp$2, undefined));
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: "model-management-page"
            }, React.createElement(TopBar$CTEM.make, {
                  mode: /* Presentation */{
                    _0: true
                  },
                  linkText: "< Back to Home",
                  urlPath: UrlPath$CTEM.Route.getMemberPath({
                        TAG: /* Dashboard */0,
                        _0: TemplateUtils$CTEM.mapMIDStringToType(work.mid)
                      }),
                  title: state.currentWork.fileName,
                  onEditName: (function (param) {
                      return Curry._1(dispatch, {
                                  TAG: /* OnChangeMode */1,
                                  _0: /* Rename */1
                                });
                    }),
                  rightElement: React.createElement("div", {
                        className: "topbar-presentation-edit-right"
                      }, topbarToolEl((function (param) {
                              return Curry._1(dispatch, {
                                          TAG: /* OnChangeMode */1,
                                          _0: /* Restore */2
                                        });
                            }), React.createElement(Replay, {}), "Restore default to the whole page"), topbarToolEl((function (param) {
                              Curry._1(fetchData, true);
                              return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath({
                                              TAG: /* PresentationWork */3,
                                              _0: TemplateUtils$CTEM.mapMIDStringToType(work.mid),
                                              _1: String(work.id)
                                            }));
                            }), React.createElement(AirplayRounded, {}), match$3 ? "Total cost tabulation in graph format" : "Presentation mode"))
                }), typeof msg === "number" ? null : React.createElement(ModalError$CTEM.make, {
                    onHandleClose: (function (param) {
                        return Curry._1(dispatch, {
                                    TAG: /* SetRenameResult */3,
                                    _0: /* NotLoaded */0
                                  });
                      }),
                    msg: msg._0
                  }), typeof msg$1 === "number" ? null : React.createElement(ModalError$CTEM.make, {
                    onHandleClose: (function (param) {
                        return Curry._1(dispatch, {
                                    TAG: /* SetRestoreResult */4,
                                    _0: /* NotLoaded */0
                                  });
                      }),
                    msg: msg$1._0
                  }), tmp, tmp$1);
}

var make = ModelManagementPage;

export {
  topbarToolEl ,
  make ,
  
}
/* react Not a pure module */
