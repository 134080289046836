// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

var selectModel = "select-model";

var dashboard = "saved-works";

var presentation = "presentation";

var creatModel = "create-model";

var forgotPassword = "forgot-password";

var login = "login";

function editWorkPath(id) {
  return "" + presentation + "/" + id + "/edit";
}

function presentWorkPath(id) {
  return "" + presentation + "/" + id;
}

function getPath(path) {
  return "/" + path;
}

function getMemberRoute(urlPath) {
  if (!urlPath) {
    return /* SelectModel */0;
  }
  switch (urlPath.hd) {
    case "create-model" :
        if (urlPath.tl) {
          return /* SelectModel */0;
        } else {
          return {
                  TAG: /* CreateModel */1,
                  _0: /* LAI */0
                };
        }
    case "imb" :
        var _tail = urlPath.tl;
        if (!_tail) {
          return {
                  TAG: /* Dashboard */0,
                  _0: /* IMBRUVICA */1
                };
        }
        switch (_tail.hd) {
          case "create-model" :
              if (_tail.tl) {
                return {
                        TAG: /* Dashboard */0,
                        _0: /* IMBRUVICA */1
                      };
              } else {
                return {
                        TAG: /* CreateModel */1,
                        _0: /* IMBRUVICA */1
                      };
              }
          case "presentation" :
              var match = _tail.tl;
              if (!match) {
                return {
                        TAG: /* Dashboard */0,
                        _0: /* IMBRUVICA */1
                      };
              }
              var match$1 = match.tl;
              var workId = match.hd;
              if (match$1) {
                if (match$1.hd === "edit" && !match$1.tl) {
                  return {
                          TAG: /* EditWork */2,
                          _0: /* IMBRUVICA */1,
                          _1: workId
                        };
                } else {
                  return {
                          TAG: /* Dashboard */0,
                          _0: /* IMBRUVICA */1
                        };
                }
              } else {
                return {
                        TAG: /* PresentationWork */3,
                        _0: /* IMBRUVICA */1,
                        _1: workId
                      };
              }
          default:
            return {
                    TAG: /* Dashboard */0,
                    _0: /* IMBRUVICA */1
                  };
        }
    case "lai" :
        var _tail$1 = urlPath.tl;
        if (!_tail$1) {
          return {
                  TAG: /* Dashboard */0,
                  _0: /* LAI */0
                };
        }
        switch (_tail$1.hd) {
          case "create-model" :
              if (_tail$1.tl) {
                return {
                        TAG: /* Dashboard */0,
                        _0: /* LAI */0
                      };
              } else {
                return {
                        TAG: /* CreateModel */1,
                        _0: /* LAI */0
                      };
              }
          case "presentation" :
              var match$2 = _tail$1.tl;
              if (!match$2) {
                return {
                        TAG: /* Dashboard */0,
                        _0: /* LAI */0
                      };
              }
              var match$3 = match$2.tl;
              var workId$1 = match$2.hd;
              if (match$3) {
                if (match$3.hd === "edit" && !match$3.tl) {
                  return {
                          TAG: /* EditWork */2,
                          _0: /* LAI */0,
                          _1: workId$1
                        };
                } else {
                  return {
                          TAG: /* Dashboard */0,
                          _0: /* LAI */0
                        };
                }
              } else {
                return {
                        TAG: /* PresentationWork */3,
                        _0: /* LAI */0,
                        _1: workId$1
                      };
              }
          case "saved-works" :
              return {
                      TAG: /* Dashboard */0,
                      _0: /* LAI */0
                    };
          default:
            return {
                    TAG: /* Dashboard */0,
                    _0: /* LAI */0
                  };
        }
    case "presentation" :
        var match$4 = urlPath.tl;
        if (!match$4) {
          return /* SelectModel */0;
        }
        var match$5 = match$4.tl;
        var workId$2 = match$4.hd;
        if (match$5) {
          if (match$5.hd === "edit" && !match$5.tl) {
            return {
                    TAG: /* EditWork */2,
                    _0: /* LAI */0,
                    _1: workId$2
                  };
          } else {
            return /* SelectModel */0;
          }
        } else {
          return {
                  TAG: /* PresentationWork */3,
                  _0: /* LAI */0,
                  _1: workId$2
                };
        }
    case "saved-works" :
        if (urlPath.tl) {
          return /* SelectModel */0;
        } else {
          return {
                  TAG: /* Dashboard */0,
                  _0: /* LAI */0
                };
        }
    case "" :
    case "select-model" :
        return /* SelectModel */0;
    default:
      return /* SelectModel */0;
  }
}

function getNonMemberRoute(urlPath) {
  if (!urlPath) {
    return /* Login */0;
  }
  switch (urlPath.hd) {
    case "forgot-password" :
    case "reset-password" :
        if (urlPath.tl) {
          return /* Login */0;
        } else {
          return /* ForgotPassword */1;
        }
    default:
      return /* Login */0;
  }
}

function getPrefixPathByModelTemplateID(modelTemplateID) {
  if (modelTemplateID) {
    return "imb";
  } else {
    return "lai";
  }
}

function getMemberPath(route) {
  var path = function (mid, path$1) {
    return "/" + ((
              mid ? "imb" : "lai"
            ) + ("/" + path$1));
  };
  var pathWithWorkId = function (mid, workIdStr, pathPage) {
    return path(mid, Belt_Option.mapWithDefault(Belt_Int.fromString(workIdStr), dashboard, pathPage));
  };
  if (typeof route === "number") {
    return "/select-model";
  }
  switch (route.TAG | 0) {
    case /* Dashboard */0 :
        return path(route._0, dashboard);
    case /* CreateModel */1 :
        return path(route._0, creatModel);
    case /* EditWork */2 :
        return pathWithWorkId(route._0, route._1, editWorkPath);
    case /* PresentationWork */3 :
        return pathWithWorkId(route._0, route._1, presentWorkPath);
    
  }
}

function getNonMemberPath(route) {
  if (route) {
    return "/forgot-password";
  } else {
    return "/login";
  }
}

var Route = {
  getMemberRoute: getMemberRoute,
  getNonMemberRoute: getNonMemberRoute,
  getPrefixPathByModelTemplateID: getPrefixPathByModelTemplateID,
  getMemberPath: getMemberPath,
  getNonMemberPath: getNonMemberPath
};

function createQueryParams(params) {
  return Belt_Array.map(params, (function (param) {
                  return param[0] + ("=" + param[1]);
                })).join("&");
}

var v1 = "/v1";

var v2 = "/v2";

function internalUrl(isV2Opt, endPoint) {
  var isV2 = isV2Opt !== undefined ? isV2Opt : true;
  var server = (`${API_SERVER}`);
  return server + ((
            isV2 ? v2 : v1
          ) + endPoint);
}

function savedWorkAPIPath(id) {
  return "/models/" + (id + "/");
}

function masterTemplateAPIPath(mid, countryCode) {
  return "/models/master/" + (mid + ("/" + (countryCode + "/")));
}

function fileIdPath(id) {
  return "/models/" + (id + "/");
}

function createWorkAPIPath0(mid, countryCode) {
  return "/models/create/" + (mid + ("/" + (countryCode + "/")));
}

function createWorkAPIPath(marketsharezeroOpt, exchangeRate, mid, countryCode) {
  var marketsharezero = marketsharezeroOpt !== undefined ? marketsharezeroOpt : true;
  var params = [[
      "marketsharezero",
      Pervasives.string_of_bool(marketsharezero)
    ]];
  Belt_Option.map(exchangeRate, (function (rate) {
          return params.push([
                      "exchange_rate",
                      String(rate)
                    ]);
        }));
  return createWorkAPIPath0(mid, countryCode) + ("?" + createQueryParams(params));
}

var input = "input";

var loginAPIPath = "/accounts/login/";

var resetPasswordAPIPath = "/accounts/reset-password/";

var confirmResetPasswordAPIPath = "/accounts/confirm-reset-password/";

var savedWorksAPIPath = "/models/saved/";

var modelsAPIPath = "/models/templates/";

export {
  selectModel ,
  dashboard ,
  presentation ,
  creatModel ,
  input ,
  forgotPassword ,
  login ,
  editWorkPath ,
  presentWorkPath ,
  getPath ,
  Route ,
  createQueryParams ,
  v1 ,
  v2 ,
  internalUrl ,
  loginAPIPath ,
  resetPasswordAPIPath ,
  confirmResetPasswordAPIPath ,
  savedWorksAPIPath ,
  savedWorkAPIPath ,
  modelsAPIPath ,
  masterTemplateAPIPath ,
  fileIdPath ,
  createWorkAPIPath0 ,
  createWorkAPIPath ,
  
}
/* No side effect */
