// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as FloatInput$CTEM from "../Components/FloatInput.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as LAIInputForm$CTEM from "../Components/ModelTemplates/LAI/ManagementMenus/LAIInputForm.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as RestoreButton$CTEM from "../Components/RestoreButton.bs.js";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

function valueNotMatchWithDefaultValue(digitOpt, defaultValue, value) {
  var digit = digitOpt !== undefined ? digitOpt : 2;
  return Utils$CTEM.getFloatWithDigitReturnFloat(digit, value) !== Utils$CTEM.getFloatWithDigitReturnFloat(digit, defaultValue);
}

function treatmentBgClassNameRaw(id) {
  return "bg-treatment-" + String(id);
}

function treatmentBgClassName(param) {
  return "bg-treatment-" + String(param.id);
}

var treatmentDurationOptions = Belt_Array.makeBy(6, (function (i) {
        var month = String(i + 1 | 0);
        return {
                label: month,
                value: month
              };
      }));

function inputLabelShowsAsShortNumber(currencyCode) {
  return Belt_Array.some(["VND"], (function (code) {
                return code.toLowerCase() === currencyCode.toLowerCase();
              }));
}

function createInputEl(key, inputDisabled, onRestore, onValueUpdate, $staropt$star, $staropt$star$1, currency, defaultData, param) {
  var value = param.value;
  var unit = $staropt$star !== undefined ? $staropt$star : "";
  var error = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  return React.createElement(LAIInputForm$CTEM.make, {
              inputMode: {
                TAG: /* FloatInput */1,
                _0: value
              },
              labelAsShortNumber: inputLabelShowsAsShortNumber(currency),
              title: param.title,
              unit: unit,
              inputDisabled: inputDisabled,
              digit: 0,
              onValueUpdate: onValueUpdate,
              infoMode: value !== defaultData.value ? /* Restore */0 : /* Infos */({
                    _0: param.source
                  }),
              onRestore: onRestore,
              error: error,
              key: key
            });
}

function createFloatInputEl(key, inputDisabled, requestUpdateValue, currency, $staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, newTitle, defaultData, param) {
  var value = param.value;
  var unit = $staropt$star !== undefined ? $staropt$star : "";
  var error = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var exchangeRate = $staropt$star$2 !== undefined ? $staropt$star$2 : 1;
  var digit = $staropt$star$3 !== undefined ? $staropt$star$3 : 0;
  var finalTitle = Belt_Option.getWithDefault(newTitle, param.title);
  var defaultValue = Utils$CTEM.Currency.covertPriceWithRateWithDigit(digit, exchangeRate, defaultData.value);
  return React.createElement(LAIInputForm$CTEM.make, {
              inputMode: {
                TAG: /* FloatInput */1,
                _0: value
              },
              labelAsShortNumber: inputLabelShowsAsShortNumber(currency),
              title: finalTitle,
              unit: unit,
              inputDisabled: inputDisabled,
              digit: digit,
              onValueUpdate: Curry.__1(requestUpdateValue),
              infoMode: valueNotMatchWithDefaultValue(digit, defaultValue, value) ? /* Restore */0 : /* Infos */({
                    _0: param.source
                  }),
              onRestore: (function (param) {
                  return Curry._1(requestUpdateValue, defaultValue);
                }),
              error: error,
              key: key
            });
}

function createFloatInputWithTitleEl(key, inputDisabled, requestUpdateValue, requestUpdateTitle, currency, $staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, defaultData, param) {
  var value = param.value;
  var title = param.title;
  var titlePlaceholder = $staropt$star !== undefined ? $staropt$star : "";
  var unit = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var error = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var exchangeRate = $staropt$star$3 !== undefined ? $staropt$star$3 : 1;
  var digit = $staropt$star$4 !== undefined ? $staropt$star$4 : 0;
  var defaultValue = Utils$CTEM.Currency.covertPriceWithRateWithDigit(digit, exchangeRate, defaultData.value);
  return React.createElement(LAIInputForm$CTEM.make, {
              inputMode: {
                TAG: /* FloatInputWithTitle */2,
                _0: value
              },
              labelAsShortNumber: inputLabelShowsAsShortNumber(currency),
              title: title,
              titlePlaceholder: titlePlaceholder,
              unit: unit,
              inputDisabled: inputDisabled,
              digit: digit,
              onValueUpdate: Curry.__1(requestUpdateValue),
              onTitleUpdate: Curry.__1(requestUpdateTitle),
              onTitleRestore: (function (param) {
                  return Curry._1(requestUpdateTitle, defaultData.title);
                }),
              infoTitleMode: title !== defaultData.title ? /* Restore */0 : /* Infos */({
                    _0: undefined
                  }),
              infoMode: valueNotMatchWithDefaultValue(digit, defaultValue, value) ? /* Restore */0 : /* Infos */({
                    _0: param.source
                  }),
              onRestore: (function (param) {
                  return Curry._1(requestUpdateValue, defaultValue);
                }),
              error: error,
              key: key
            });
}

function createIntInputEl(key, inputDisabled, requestUpdateValue, $staropt$star, $staropt$star$1, defaultData, param) {
  var value = param.value;
  var unit = $staropt$star !== undefined ? $staropt$star : "";
  var error = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  return React.createElement(LAIInputForm$CTEM.make, {
              inputMode: {
                TAG: /* FloatInput */1,
                _0: value
              },
              title: param.title,
              unit: unit,
              inputDisabled: inputDisabled,
              digit: 0,
              onValueUpdate: (function (newValue) {
                  return Curry._1(requestUpdateValue, newValue | 0);
                }),
              infoMode: value !== defaultData.value ? /* Restore */0 : /* Infos */({
                    _0: param.source
                  }),
              onRestore: (function (param) {
                  return Curry._1(requestUpdateValue, defaultData.value);
                }),
              error: error,
              key: key
            });
}

function createSumInputEl(key, titleOpt, digitOpt, inputDisabledOpt, expandValueOpt, currency, value) {
  var title = titleOpt !== undefined ? titleOpt : "Total costs";
  var digit = digitOpt !== undefined ? digitOpt : 0;
  var inputDisabled = inputDisabledOpt !== undefined ? inputDisabledOpt : true;
  var expandValue = expandValueOpt !== undefined ? expandValueOpt : true;
  var match = currency !== undefined && inputDisabled ? [
      currency.code,
      value
    ] : [
      "",
      value
    ];
  return React.createElement(LAIInputForm$CTEM.make, {
              inputMode: {
                TAG: /* FloatInput */1,
                _0: match[1]
              },
              floatInputDisplayType: /* Text */{
                _0: expandValue
              },
              title: title,
              wrapperClassName: "cal-sum-input",
              unit: match[0],
              inputDisabled: inputDisabled,
              digit: digit,
              infoMode: /* Infos */{
                _0: undefined
              },
              onRestore: (function (param) {
                  
                }),
              key: key
            });
}

function createIntSelectInput(key, inputDisabled, requestUpdateValue, $staropt$star, $staropt$star$1, items, defaultData, param) {
  var value = param.value;
  var unit = $staropt$star !== undefined ? $staropt$star : "";
  var error = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  return React.createElement(LAIInputForm$CTEM.make, {
              inputMode: {
                TAG: /* SelectInput */3,
                _0: {
                  value: String(value),
                  items: items
                }
              },
              title: param.title,
              unit: unit,
              inputDisabled: inputDisabled,
              digit: 0,
              onTextUpdate: (function (newString) {
                  return Curry._1(requestUpdateValue, Belt_Option.getExn(Belt_Int.fromString(newString)));
                }),
              infoMode: value !== defaultData.value ? /* Restore */0 : /* Infos */({
                    _0: param.source
                  }),
              onRestore: (function (param) {
                  return Curry._1(requestUpdateValue, defaultData.value);
                }),
              error: error,
              key: key
            });
}

function createHeaders(key, alignOpt, columns) {
  var align = alignOpt !== undefined ? alignOpt : "left";
  return React.createElement(TableHead, {
              children: React.createElement(TableRow, {
                    children: $$Array.of_list(Belt_List.mapWithIndex(columns, (function (idx, column) {
                                return React.createElement(TableCell, {
                                            align: align,
                                            style: {
                                              width: String(Caml_int32.div(100, List.length(columns))) + "%"
                                            },
                                            children: React.createElement("b", undefined, column),
                                            key: "table-head-" + (key + ("-" + String(idx)))
                                          });
                              })))
                  })
            });
}

function createFloatInputCell($staropt$star, $staropt$star$1, $staropt$star$2, currency, unit, requestUpdateValue, defaultData, $staropt$star$3, param) {
  var value = param.value;
  var inputDisabled = $staropt$star !== undefined ? $staropt$star : false;
  var digit = $staropt$star$1 !== undefined ? $staropt$star$1 : 2;
  var className = $staropt$star$2 !== undefined ? $staropt$star$2 : "input-price";
  var exchangeRate = $staropt$star$3 !== undefined ? $staropt$star$3 : 1;
  var handleValueChange = function (newValue) {
    return Belt_Option.mapWithDefault(requestUpdateValue, undefined, (function (f) {
                  return Curry._1(f, newValue);
                }));
  };
  var currencyCode = unit !== undefined ? unit : (
      currency !== undefined ? currency.code : undefined
    );
  var tmp = {
    value: value,
    onValueUpdate: handleValueChange,
    className: className,
    disabled: inputDisabled,
    digit: digit
  };
  if (currencyCode !== undefined) {
    tmp.currency = Caml_option.valFromOption(currencyCode);
  }
  var tmp$1 = Belt_Option.map(currencyCode, inputLabelShowsAsShortNumber);
  if (tmp$1 !== undefined) {
    tmp.labelAsShortNumber = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  if (defaultData !== undefined) {
    var finalDefaultValue = Utils$CTEM.Currency.covertPriceWithRateWithDigit(undefined, exchangeRate, defaultData.value);
    tmp$2 = React.createElement(RestoreButton$CTEM.make, {
          mode: valueNotMatchWithDefaultValue(digit, finalDefaultValue, value) ? /* Restore */0 : /* Infos */({
                _0: param.source
              }),
          onRestore: (function (param) {
              return handleValueChange(finalDefaultValue);
            })
        });
  } else {
    tmp$2 = null;
  }
  return React.createElement(TableCell, {
              align: "left",
              children: React.createElement("div", {
                    className: "table-info-content"
                  }, React.createElement(FloatInput$CTEM.make, tmp), tmp$2)
            });
}

function createCalculateFloatInputCell(disabledOpt, digitOpt, classNameOpt, currency, source, value) {
  var disabled = disabledOpt !== undefined ? disabledOpt : true;
  var digit = digitOpt !== undefined ? digitOpt : 0;
  var className = classNameOpt !== undefined ? classNameOpt : "input-price";
  var currencyCode = Belt_Option.map(currency, (function (c) {
          return c.code;
        }));
  var tmp = {
    displayType: /* Text */{
      _0: false
    },
    value: value,
    className: className,
    disabled: disabled,
    digit: digit
  };
  var tmp$1 = Belt_Option.map(currencyCode, inputLabelShowsAsShortNumber);
  if (tmp$1 !== undefined) {
    tmp.labelAsShortNumber = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(TableCell, {
              align: "left",
              children: React.createElement("div", {
                    className: "table-info-content cal-sum-input"
                  }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(null),
                            undefined,
                            undefined
                          ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(8)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(React.createElement(FloatInput$CTEM.make, tmp)),
                                undefined,
                                undefined
                              ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                                undefined,
                                undefined,
                                undefined,
                                "last-col text-center",
                                Caml_option.some(null),
                                undefined,
                                undefined
                              ]), currencyCode !== undefined ? React.createElement("p", {
                                  className: "input-form-unit"
                                }, currencyCode) : null, React.createElement(RestoreButton$CTEM.make, {
                                mode: /* Infos */{
                                  _0: source
                                }
                              }))))
            });
}

var Table = {
  createHeaders: createHeaders,
  createFloatInputCell: createFloatInputCell,
  createCalculateFloatInputCell: createCalculateFloatInputCell
};

export {
  valueNotMatchWithDefaultValue ,
  treatmentBgClassNameRaw ,
  treatmentBgClassName ,
  treatmentDurationOptions ,
  inputLabelShowsAsShortNumber ,
  createInputEl ,
  createFloatInputEl ,
  createFloatInputWithTitleEl ,
  createIntInputEl ,
  createSumInputEl ,
  createIntSelectInput ,
  Table ,
  
}
/* treatmentDurationOptions Not a pure module */
