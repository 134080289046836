// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";

function getColorClassName(left) {
  if (left) {
    return "1";
  } else {
    return "2";
  }
}

function getColorBoxClassName(left, pos) {
  return "box-overview-" + ((
            left ? "left" : "right"
          ) + ("-" + (pos + "-color")));
}

function childrenBoxs(titles) {
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "height-80",
                  Caml_option.some(Belt_Array.mapWithIndex(titles, (function (index, title) {
                              return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                              undefined,
                                              Caml_option.some(MaterialUIDataType.GridSize.true_),
                                              undefined,
                                              undefined,
                                              undefined,
                                              "children-box",
                                              Caml_option.some(title),
                                              "children-box-" + String(index),
                                              undefined
                                            ]));
                            }))),
                  undefined,
                  undefined
                ]));
}

function centerGreyBox(title) {
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                  undefined,
                  undefined,
                  undefined,
                  "bg-color-1",
                  Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                "height-100",
                                Caml_option.some(null),
                                undefined,
                                undefined
                              ]), React.createElement("div", {
                                className: "line-mapping"
                              }), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                    undefined,
                                    Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined
                                  ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                    undefined,
                                    Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                    undefined,
                                    undefined,
                                    undefined,
                                    "text-center box-overview-center",
                                    Caml_option.some(React.createElement("div", {
                                              className: "box-rounded"
                                            }, title)),
                                    undefined,
                                    undefined
                                  ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                    undefined,
                                    Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined
                                  ])))),
                  undefined,
                  undefined
                ]));
}

function compareTopBox(leftOpt, title) {
  var left = leftOpt !== undefined ? leftOpt : true;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                  undefined,
                  undefined,
                  undefined,
                  "text-center",
                  Caml_option.some(React.createElement("div", {
                            className: "box-overview box-top-overview " + getColorBoxClassName(left, "top")
                          }, title)),
                  undefined,
                  undefined
                ]));
}

function compareBottomBox(leftOpt, title) {
  var left = leftOpt !== undefined ? leftOpt : true;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                  undefined,
                  undefined,
                  undefined,
                  "text-center",
                  Caml_option.some(React.createElement("div", {
                            className: "box-overview box-bottom-overview " + getColorBoxClassName(left, "bottom")
                          }, title)),
                  undefined,
                  undefined
                ]));
}

function compareLightBox(leftOpt, title) {
  var left = leftOpt !== undefined ? leftOpt : true;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                  undefined,
                  undefined,
                  undefined,
                  "text-center",
                  Caml_option.some(React.createElement("div", {
                            className: "box-overview " + getColorBoxClassName(left, "middle")
                          }, title)),
                  undefined,
                  undefined
                ]));
}

function compareBoxWithBoxesInside(leftOpt, messages) {
  var left = leftOpt !== undefined ? leftOpt : true;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                  undefined,
                  undefined,
                  undefined,
                  "text-center box-overview box-overview-parent-4 " + getColorBoxClassName(left, "children"),
                  Caml_option.some(childrenBoxs(messages)),
                  undefined,
                  undefined
                ]));
}

function OverviewChart(Props) {
  var overviewChart = Props.overviewChart;
  var hasBottomBox = overviewChart.bottom !== "";
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "model-overview " + (
                    hasBottomBox ? "mt-2" : "no-bottom-box"
                  ),
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), centerGreyBox(overviewChart.top), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100 box-overview-head",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), compareTopBox(undefined, overviewChart.left.top), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), compareTopBox(false, overviewChart.right.top))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-color-2",
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), compareLightBox(undefined, overviewChart.left.middle), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), compareLightBox(false, overviewChart.right.middle))),
                      undefined,
                      undefined
                    ])), Belt_Array.mapWithIndex(overviewChart.childrenBoxes, (function (idx, messages) {
                    return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                    undefined,
                                    Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                    undefined,
                                    undefined,
                                    undefined,
                                    "bg-color-" + (
                                      idx % 2 !== 0 ? "1" : "2"
                                    ),
                                    Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                                  Caml_option.some(MaterialUIDataType.Justify.center),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  "height-100",
                                                  Caml_option.some(null),
                                                  undefined,
                                                  undefined
                                                ]), compareBoxWithBoxesInside(undefined, messages), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                      undefined,
                                                      Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined
                                                    ])), compareBoxWithBoxesInside(false, messages))),
                                    "overview-chart-children-boxes-" + String(idx),
                                    undefined
                                  ]));
                  })), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), compareBottomBox(undefined, overviewChart.left.bottom), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), compareBottomBox(false, overviewChart.right.bottom))),
                      undefined,
                      undefined
                    ])), hasBottomBox ? React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                        undefined,
                        Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      "height-100",
                                      Caml_option.some(null),
                                      undefined,
                                      undefined
                                    ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                          undefined,
                                          Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined
                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                          undefined,
                                          Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                          undefined,
                                          undefined,
                                          undefined,
                                          "text-center box-overview-center",
                                          Caml_option.some(React.createElement("div", {
                                                    className: "box-rounded",
                                                    style: {
                                                      marginTop: "10px"
                                                    }
                                                  }, overviewChart.bottom)),
                                          undefined,
                                          undefined
                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                          undefined,
                                          Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined
                                        ])))),
                        undefined,
                        undefined
                      ])) : null);
}

var make = OverviewChart;

export {
  getColorClassName ,
  getColorBoxClassName ,
  childrenBoxs ,
  centerGreyBox ,
  compareTopBox ,
  compareBottomBox ,
  compareLightBox ,
  compareBoxWithBoxesInside ,
  make ,
  
}
/* react Not a pure module */
