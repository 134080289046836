// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apis$CTEM from "../../../../Effects/Apis.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Encoder$CTEM from "../../../../Effects/Encoder.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as SectionBox$CTEM from "../../SectionBox.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as TemplateUtils$CTEM from "../../TemplateUtils.bs.js";
import Box from "@material-ui/core/Box";
import * as ModelInputHelper$CTEM from "../../../../Effects/ModelInputHelper.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";

function createFloatInputWithTitleEl(param) {
  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11) {
    return ModelInputHelper$CTEM.createFloatInputWithTitleEl(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11);
  };
  return function (param) {
    var func$1 = Curry._1(func, param);
    return function (param) {
      var func$2 = Curry._1(func$1, param);
      return function (param) {
        var func$3 = Curry._1(func$2, param);
        return function (param) {
          return Curry._2(func$3, param, "Other Medication Name");
        };
      };
    };
  };
}

var getTreatmentName = TemplateUtils$CTEM.Imbruvica.getTreatmentName;

function totalCost(nonMed) {
  return TemplateUtils$CTEM.sumFloatValue({
              hd: nonMed.infusion,
              tl: {
                hd: nonMed.hospitalVisits,
                tl: {
                  hd: nonMed.transport,
                  tl: {
                    hd: nonMed.accomodation,
                    tl: {
                      hd: nonMed.labTest,
                      tl: {
                        hd: nonMed.others01,
                        tl: {
                          hd: nonMed.others02,
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function IMBRUVICA_DirectNonMedical(Props) {
  var work = Props.work;
  var currentData = Props.currentData;
  var defaultData = Props.defaultData;
  var exchangeRate = Props.exchangeRate;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useState(function () {
        return /* NotLoaded */0;
      });
  var setApiState = match$1[1];
  var requestUpdateData = function (normalInputs) {
    Curry._1(setApiState, (function (param) {
            return /* Loading */1;
          }));
    return Apis$CTEM.requestUpdateSavedWorkNormalInput(work.id, normalInputs, (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadSuccess */2;
                              }));
                }), (function (json) {
                  var errMsg = RequestUtils$CTEM.getResponseMsgFromJson(json);
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: errMsg
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadFailed */{
                                        _0: errMsg
                                      };
                              }));
                }));
  };
  var inputDisabled = match$1[0] === /* Loading */1;
  var arg = work.currency.code;
  var arg$1 = work.currency.code;
  var arg$2 = exchangeRate;
  var createFloatInput = function (param) {
    return function (param$1) {
      var func = function (param$2, param$3, param$4, param$5, param$6, param$7) {
        return ModelInputHelper$CTEM.createFloatInputEl(param, inputDisabled, param$1, arg, arg$1, param$2, param$3, param$4, param$5, param$6, param$7);
      };
      return function (param) {
        return Curry._2(func, param, arg$2);
      };
    };
  };
  var arg$3 = work.currency.code;
  var arg$4 = work.currency.code;
  var arg$5 = exchangeRate;
  var createFloatInputWithTitle = function (param) {
    var func = createFloatInputWithTitleEl(param)(inputDisabled);
    return function (param) {
      var func$1 = Curry._1(func, param);
      return function (param) {
        var func$2 = Curry._3(func$1, param, arg$3, arg$4);
        return function (param) {
          return Curry._2(func$2, param, arg$5);
        };
      };
    };
  };
  return React.createElement(Box, {
              className: "height-100",
              alignItems: MaterialUIDataType.AlignItems.center,
              children: React.createElement(SectionBox$CTEM.make, {
                    title: "",
                    subtitle: "Other direct medical and non-medical costs per patient per month",
                    children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some($$Array.of_list(Belt_List.keepMap(Belt_List.sort(currentData.treatments, (function (a, b) {
                                                  return Caml_primitive.caml_int_compare(a.id, b.id);
                                                })), (function (treatment) {
                                              return Belt_Option.map(Belt_List.getBy(defaultData.treatments, (function (defaultTreatment) {
                                                                return defaultTreatment.id === treatment.id;
                                                              })), (function (defaultTreatment) {
                                                            var nonMedKey = function (med) {
                                                              return "imb-non-med-" + (med + ("-" + String(treatment.id)));
                                                            };
                                                            var nonMed = treatment.nonMedication;
                                                            var defaultNonMed = defaultTreatment.nonMedication;
                                                            var onValueUpdate = function (encoder, value) {
                                                              return requestUpdateData({
                                                                          hd: Curry._2(encoder, treatment.id, value),
                                                                          tl: /* [] */0
                                                                        });
                                                            };
                                                            return React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
                                                                        title: getTreatmentName(treatment),
                                                                        className: ModelInputHelper$CTEM.treatmentBgClassName(treatment),
                                                                        children: [
                                                                          Curry._5(createFloatInput(nonMedKey("infusion"))(function (param) {
                                                                                    return onValueUpdate(Encoder$CTEM.Imbruvica.NonMed.infusion, param);
                                                                                  }), undefined, undefined, undefined, defaultNonMed.infusion, nonMed.infusion),
                                                                          Curry._5(createFloatInput(nonMedKey("hospitalVisits"))(function (param) {
                                                                                    return onValueUpdate(Encoder$CTEM.Imbruvica.NonMed.hospitalVisits, param);
                                                                                  }), undefined, undefined, undefined, defaultNonMed.hospitalVisits, nonMed.hospitalVisits),
                                                                          Curry._5(createFloatInput(nonMedKey("transport"))(function (param) {
                                                                                    return onValueUpdate(Encoder$CTEM.Imbruvica.NonMed.transport, param);
                                                                                  }), undefined, undefined, undefined, defaultNonMed.transport, nonMed.transport),
                                                                          Curry._5(createFloatInput(nonMedKey("accomodation"))(function (param) {
                                                                                    return onValueUpdate(Encoder$CTEM.Imbruvica.NonMed.accomodation, param);
                                                                                  }), undefined, undefined, undefined, defaultNonMed.accomodation, nonMed.accomodation),
                                                                          Curry._5(createFloatInput(nonMedKey("labTest"))(function (param) {
                                                                                    return onValueUpdate(Encoder$CTEM.Imbruvica.NonMed.labTest, param);
                                                                                  }), undefined, undefined, undefined, defaultNonMed.labTest, nonMed.labTest),
                                                                          Curry._5(createFloatInputWithTitle(nonMedKey("others01"))(function (param) {
                                                                                    return onValueUpdate(Encoder$CTEM.Imbruvica.NonMed.others01, param);
                                                                                  }), (function (param) {
                                                                                  return requestUpdateData(Curry._1(Encoder$CTEM.Imbruvica.NonMed.titleOthers01, param));
                                                                                }), undefined, undefined, defaultNonMed.others01, nonMed.others01),
                                                                          Curry._5(createFloatInputWithTitle(nonMedKey("others02"))(function (param) {
                                                                                    return onValueUpdate(Encoder$CTEM.Imbruvica.NonMed.others02, param);
                                                                                  }), (function (param) {
                                                                                  return requestUpdateData(Curry._1(Encoder$CTEM.Imbruvica.NonMed.titleOthers02, param));
                                                                                }), undefined, undefined, defaultNonMed.others02, nonMed.others02),
                                                                          React.createElement("br", {
                                                                                key: nonMedKey("whitespace-cost-sum")
                                                                              }),
                                                                          ModelInputHelper$CTEM.createSumInputEl(nonMedKey("cost-sum"), undefined, undefined, undefined, undefined, work.currency, totalCost(nonMed))
                                                                        ],
                                                                        key: "imb-direct-non-med-" + (String(treatment.id) + ("-" + treatment.treatmentName.title))
                                                                      });
                                                          }));
                                            })))),
                              undefined,
                              undefined
                            ]))
                  })
            });
}

var bgClassName = ModelInputHelper$CTEM.treatmentBgClassName;

var createFloatInputEl = ModelInputHelper$CTEM.createFloatInputEl;

var createSumInputEl = ModelInputHelper$CTEM.createSumInputEl;

var make = IMBRUVICA_DirectNonMedical;

export {
  bgClassName ,
  createFloatInputEl ,
  createSumInputEl ,
  createFloatInputWithTitleEl ,
  getTreatmentName ,
  totalCost ,
  make ,
  
}
/* react Not a pure module */
