// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as React from "react";
import * as Apis$CTEM from "./Effects/Apis.bs.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Utils$CTEM from "./Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TopBar$CTEM from "./Components/TopBar.bs.js";
import * as Loading$CTEM from "./Components/Loading.bs.js";
import * as UrlPath$CTEM from "./UrlPath.bs.js";
import * as DataTypes$CTEM from "./DataTypes.bs.js";
import * as LoginPage$CTEM from "./Pages/LoginPage.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as RequestUtils$CTEM from "./RequestUtils.bs.js";
import * as DashboardPage$CTEM from "./Pages/DashboardPage.bs.js";
import * as ModelsContext$CTEM from "./Contexts/ModelsContext.bs.js";
import * as ForgotPassPage$CTEM from "./Pages/ForgotPassPage.bs.js";
import * as CreateModelPage$CTEM from "./Pages/CreateModelPage.bs.js";
import * as SelectModelPage$CTEM from "./Pages/SelectModelPage.bs.js";
import * as PresentationPage$CTEM from "./Pages/PresentationPage.bs.js";
import * as SavedWorksContext$CTEM from "./Contexts/SavedWorksContext.bs.js";
import * as ModelManagementPage$CTEM from "./Pages/ModelManagementPage.bs.js";
import * as ServiceWorkerLoader$CTEM from "./ServiceWorkerLoader.bs.js";
import * as CurrencyExchangeRatesContext$CTEM from "./Contexts/CurrencyExchangeRatesContext.bs.js";

var setRatio = (function() {
    setTimeout(function(){
      var mainElement = document.getElementById("main");
      var elHeight = mainElement.offsetHeight;
      var elWidth = mainElement.offsetWidth;

      var rootElement = document.getElementById("root");

      var scale;
      scale = Math.min(
        rootElement.offsetWidth / elWidth,
        rootElement.offsetHeight / elHeight
      );
      mainElement.style.transform = "translate(-50%, -50%) " + "scale(" + scale + ")";
      localStorage.setItem('scaleRatio', scale);
      var mainElement = document.getElementById("main");
      window.addEventListener('resize', function(event) {
          var newScale = Math.min(
            rootElement.offsetWidth / elWidth,
            rootElement.offsetHeight / elHeight
          );
          mainElement.style.transform = "translate(-50%, -50%) " + "scale(" + newScale + ")";
          localStorage.setItem('scaleRatio', newScale);
      }, true);

    }, 500);
  });

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetCurrencyExchangesResults */0 :
        return {
                currencyExchangesResults: action._0,
                modelsResults: state.modelsResults,
                savedWorksResults: state.savedWorksResults
              };
    case /* SetModelsResults */1 :
        return {
                currencyExchangesResults: state.currencyExchangesResults,
                modelsResults: action._0,
                savedWorksResults: state.savedWorksResults
              };
    case /* SetSavedWorksResults */2 :
        return {
                currencyExchangesResults: state.currencyExchangesResults,
                modelsResults: state.modelsResults,
                savedWorksResults: action._0
              };
    
  }
}

function App(Props) {
  var url = ReasonReactRouter.useUrl(undefined, undefined);
  var match = localStorage.getItem("token");
  var isLogined = match !== null;
  var match$1 = React.useReducer(reducer, {
        currencyExchangesResults: /* NotLoaded */0,
        modelsResults: /* NotLoaded */0,
        savedWorksResults: /* NotLoaded */0
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var requestModels = function (param) {
    return Utils$CTEM.loadDataIfNone(state.modelsResults, false, (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* SetModelsResults */1,
                        _0: /* Loading */1
                      });
                  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Get */0, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.modelsAPIPath), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetModelsResults */1,
                                      _0: {
                                        TAG: /* LoadSuccess */0,
                                        _0: DataTypes$CTEM.Decode.modelTemplates(json)
                                      }
                                    });
                        }), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetModelsResults */1,
                                      _0: {
                                        TAG: /* LoadFailed */1,
                                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                      }
                                    });
                        }));
                  
                }));
  };
  var requestCurrencyExchangesResults = function (cb, param) {
    var match = state.currencyExchangesResults;
    var forceReload;
    forceReload = typeof match === "number" || match.TAG !== /* LoadSuccess */0 ? false : Utils$CTEM.$$Date.diffInHours(new Date(), match._0.fetchedAt) >= 12;
    return Utils$CTEM.loadDataIfNone(state.currencyExchangesResults, forceReload, (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* SetCurrencyExchangesResults */0,
                        _0: /* Loading */1
                      });
                  return Apis$CTEM.requestRealtimeCurrencyExchangeRates((function (rates) {
                                Curry._1(dispatch, {
                                      TAG: /* SetCurrencyExchangesResults */0,
                                      _0: {
                                        TAG: /* LoadSuccess */0,
                                        _0: rates
                                      }
                                    });
                                Belt_Option.map(cb, (function (f) {
                                        return Curry._1(f, rates);
                                      }));
                                
                              }), (function (json) {
                                var results = {
                                  TAG: /* LoadFailed */1,
                                  _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                };
                                Curry._1(dispatch, {
                                      TAG: /* SetCurrencyExchangesResults */0,
                                      _0: results
                                    });
                                Belt_Option.map(cb, (function (f) {
                                        return Curry._1(f, undefined);
                                      }));
                                
                              }));
                }));
  };
  var requestSavedWorks = function (reload) {
    return Utils$CTEM.loadDataIfNone(state.savedWorksResults, reload, (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* SetSavedWorksResults */2,
                        _0: /* Loading */1
                      });
                  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Get */0, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.savedWorksAPIPath), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSavedWorksResults */2,
                                      _0: {
                                        TAG: /* LoadSuccess */0,
                                        _0: DataTypes$CTEM.Decode.works(json)
                                      }
                                    });
                        }), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSavedWorksResults */2,
                                      _0: {
                                        TAG: /* LoadFailed */1,
                                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                      }
                                    });
                        }));
                  
                }));
  };
  React.useEffect((function () {
          ServiceWorkerLoader$CTEM.initServiceWorker(undefined);
          
        }), []);
  React.useEffect((function () {
          if (isLogined) {
            requestSavedWorks(false);
          } else {
            if (state.savedWorksResults !== /* NotLoaded */0) {
              Curry._1(dispatch, {
                    TAG: /* SetSavedWorksResults */2,
                    _0: /* NotLoaded */0
                  });
            }
            if (state.modelsResults !== /* NotLoaded */0) {
              Curry._1(dispatch, {
                    TAG: /* SetModelsResults */1,
                    _0: /* NotLoaded */0
                  });
            }
            if (state.currencyExchangesResults !== /* NotLoaded */0) {
              Curry._1(dispatch, {
                    TAG: /* SetCurrencyExchangesResults */0,
                    _0: /* NotLoaded */0
                  });
            }
            
          }
          
        }), [isLogined]);
  var presentaionNoDataTopBar = function (curMID) {
    return React.createElement(TopBar$CTEM.make, {
                mode: /* Presentation */{
                  _0: false
                },
                linkText: "< Back to Home",
                urlPath: UrlPath$CTEM.Route.getMemberPath({
                      TAG: /* Dashboard */0,
                      _0: curMID
                    }),
                title: "Saved work"
              });
  };
  var workNotFound = function (workId, curMID) {
    return React.createElement(React.Fragment, {
                children: null
              }, presentaionNoDataTopBar(curMID), React.createElement("p", {
                    style: {
                      padding: "100px",
                      textAlign: "start",
                      width: "100%"
                    }
                  }, "Your saved work id " + workId + " does not exist."));
  };
  React.createElement("p", {
        style: {
          padding: "100px",
          textAlign: "start",
          width: "100%"
        }
      }, Utils$CTEM.textWithNewLine(Utils$CTEM.defaultErrorMessage));
  var createWorkComponent = function (workId, curMID, addToElement) {
    var works = state.savedWorksResults;
    if (typeof works === "number") {
      if (works === /* NotLoaded */0) {
        return null;
      } else {
        return React.createElement(Loading$CTEM.make, {});
      }
    } else if (works.TAG === /* LoadSuccess */0) {
      return Belt_Option.mapWithDefault(List.find_opt((function (work) {
                        return String(work.id) === workId;
                      }), works._0), workNotFound(workId, curMID), (function (work) {
                    var match = work.templateData;
                    if (match !== undefined) {
                      if (match.TAG === /* TemplateLAI */0) {
                        if (curMID !== /* LAI */0) {
                          return workNotFound(workId, curMID);
                        } else {
                          return Curry._1(addToElement, work);
                        }
                      } else if (curMID !== /* IMBRUVICA */1) {
                        return workNotFound(workId, curMID);
                      } else {
                        return Curry._1(addToElement, work);
                      }
                    } else {
                      return workNotFound(workId, curMID);
                    }
                  }));
    } else {
      return React.createElement(React.Fragment, {
                  children: null
                }, presentaionNoDataTopBar(curMID), React.createElement("p", {
                      style: {
                        padding: "100px",
                        textAlign: "start",
                        width: "100%"
                      }
                    }, Utils$CTEM.textWithNewLine(works._0)));
    }
  };
  Curry._1(setRatio, undefined);
  var tmp;
  if (isLogined) {
    var modelTemplateID = UrlPath$CTEM.Route.getMemberRoute(url.path);
    var tmp$1;
    if (typeof modelTemplateID === "number") {
      tmp$1 = React.createElement(SelectModelPage$CTEM.make, {});
    } else {
      switch (modelTemplateID.TAG | 0) {
        case /* Dashboard */0 :
            tmp$1 = React.createElement(DashboardPage$CTEM.make, {
                  modelTemplateID: modelTemplateID._0
                });
            break;
        case /* CreateModel */1 :
            tmp$1 = React.createElement(CreateModelPage$CTEM.make, {
                  modelTemplateID: modelTemplateID._0
                });
            break;
        case /* EditWork */2 :
            tmp$1 = createWorkComponent(modelTemplateID._1, modelTemplateID._0, (function (work) {
                    return React.createElement(ModelManagementPage$CTEM.make, {
                                work: work
                              });
                  }));
            break;
        case /* PresentationWork */3 :
            tmp$1 = createWorkComponent(modelTemplateID._1, modelTemplateID._0, (function (work) {
                    return React.createElement(PresentationPage$CTEM.make, {
                                work: work
                              });
                  }));
            break;
        
      }
    }
    tmp = React.createElement(ModelsContext$CTEM.Provider.make, ModelsContext$CTEM.Provider.makeProps([
              state.modelsResults,
              requestModels
            ], React.createElement(CurrencyExchangeRatesContext$CTEM.Provider.make, CurrencyExchangeRatesContext$CTEM.Provider.makeProps({
                      value: state.currencyExchangesResults,
                      fetchData: requestCurrencyExchangesResults
                    }, React.createElement(SavedWorksContext$CTEM.Provider.make, SavedWorksContext$CTEM.Provider.makeProps({
                              value: state.savedWorksResults,
                              fetchData: requestSavedWorks,
                              updateData: (function (results) {
                                  return Curry._1(dispatch, {
                                              TAG: /* SetSavedWorksResults */2,
                                              _0: results
                                            });
                                })
                            }, tmp$1, undefined)), undefined)), undefined));
  } else {
    var match$2 = UrlPath$CTEM.Route.getNonMemberRoute(url.path);
    if (match$2) {
      var token = [""];
      var email = [""];
      $$Array.map((function (param) {
              var paramSplited = param.split("=");
              if (paramSplited.length === 2) {
                if (Caml_array.get(paramSplited, 0) === "email") {
                  return Caml_array.set(email, 0, Caml_array.get(paramSplited, 1).replace("%40", "@"));
                } else {
                  return Caml_array.set(token, 0, Caml_array.get(paramSplited, 1));
                }
              }
              
            }), url.search.split("&"));
      tmp = React.createElement(ForgotPassPage$CTEM.make, {
            email: Caml_array.get(email, 0),
            token: Caml_array.get(token, 0)
          });
    } else {
      tmp = React.createElement(LoginPage$CTEM.make, {});
    }
  }
  return React.createElement("main", {
              id: "main"
            }, tmp);
}

var make = App;

export {
  setRatio ,
  reducer ,
  make ,
  
}
/* react Not a pure module */
