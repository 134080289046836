// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as RestoreButton$CTEM from "../../../RestoreButton.bs.js";
import * as InputComponent$CTEM from "../../../InputComponent.bs.js";
import Grid from "@material-ui/core/Grid";

function LAITargetedSchizophrenia(Props) {
  var targetedSchizophrenia = Props.targetedSchizophrenia;
  var defaultData = Props.defaultData;
  var loading = Props.loading;
  var onSave = Props.onSave;
  var match = React.useReducer((function (_state, action) {
          return {
                  currentTargets: action._0
                };
        }), {
        currentTargets: "0"
      });
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(dispatch, /* UpdateTargets */{
                _0: String(targetedSchizophrenia.value)
              });
          
        }), []);
  React.useEffect((function () {
          if (targetedSchizophrenia.value !== Caml_format.caml_int_of_string(state.currentTargets)) {
            Curry._1(dispatch, /* UpdateTargets */{
                  _0: String(targetedSchizophrenia.value)
                });
          }
          
        }), [targetedSchizophrenia]);
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                "lai-targeted-patients-wrapper",
                                Caml_option.some(null),
                                undefined,
                                undefined
                              ]), React.createElement("div", {
                                className: "margin-h3"
                              }, Utils$CTEM.textWithNewLine(targetedSchizophrenia.title)), React.createElement("div", {
                                style: {
                                  display: "contents"
                                }
                              }, React.createElement(InputComponent$CTEM.make, {
                                    mode: /* ModifyData */1,
                                    fullWidth: false,
                                    value: state.currentTargets,
                                    disabled: loading,
                                    onChange: (function ($$event) {
                                        return Curry._1(dispatch, /* UpdateTargets */{
                                                    _0: String(Utils$CTEM.getValueFromReactEventForm($$event))
                                                  });
                                      }),
                                    onBlur: (function ($$event) {
                                        var newTargets = Utils$CTEM.getIntFromString(Utils$CTEM.getValueFromReactEventForm($$event), targetedSchizophrenia.value);
                                        Curry._1(dispatch, /* UpdateTargets */{
                                              _0: String(newTargets)
                                            });
                                        return Curry._1(onSave, newTargets);
                                      })
                                  })), React.createElement("div", {
                                style: {
                                  display: "inline-block",
                                  margin: "5px 5px"
                                }
                              }, React.createElement(RestoreButton$CTEM.make, {
                                    mode: String(defaultData.targetedSchizophrenia.value) === state.currentTargets ? /* Infos */({
                                          _0: targetedSchizophrenia.source
                                        }) : /* Restore */0,
                                    onRestore: (function (param) {
                                        return Curry._1(onSave, defaultData.targetedSchizophrenia.value);
                                      })
                                  })))),
                  undefined,
                  undefined
                ]));
}

var make = LAITargetedSchizophrenia;

export {
  make ,
  
}
/* react Not a pure module */
