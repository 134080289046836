// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";

var controlTooltipPosition = (function() {
    setTimeout(function(){
      var tooltip = document.getElementsByClassName("tooltip-infos");
      var mainElement = document.getElementById("main");
      var elHeight = mainElement.offsetHeight;
      var elWidth = mainElement.offsetWidth;

      if (tooltip[0]) {
        if ((tooltip[0].getBoundingClientRect().x + tooltip[0].getBoundingClientRect().width) > document.documentElement.offsetWidth) {
          tooltip[0].classList.add("tooltip-infos-left");
        } else {
          tooltip[0].classList.add("tooltip-infos-right");
        }
      }
    }, 0);
  });

function CtemTooltip(Props) {
  var dynamicPositionOpt = Props.dynamicPosition;
  var tooltipClassNameOpt = Props.tooltipClassName;
  var onCancel = Props.onCancel;
  var children = Props.children;
  var dynamicPosition = dynamicPositionOpt !== undefined ? dynamicPositionOpt : true;
  var tooltipClassName = tooltipClassNameOpt !== undefined ? tooltipClassNameOpt : "";
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement("div", {
                  className: "tooltip-bg",
                  onClick: onCancel
                }), React.createElement("div", {
                  className: "tooltip-infos " + tooltipClassName,
                  style: {
                    textAlign: "left"
                  }
                }, React.createElement("div", {
                      className: "tooltip-arrow-"
                    }), children), dynamicPosition ? Curry._1(controlTooltipPosition, undefined) : null);
}

var make = CtemTooltip;

export {
  controlTooltipPosition ,
  make ,
  
}
/* react Not a pure module */
