// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../../../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as SideBar$CTEM from "../../SideBar.bs.js";
import * as TemplateUtils$CTEM from "../TemplateUtils.bs.js";
import * as IMBRUVICA_Overview$CTEM from "./ManagementMenus/IMBRUVICA_Overview.bs.js";
import * as IMBRUVICA_AdverseEvent$CTEM from "./ManagementMenus/IMBRUVICA_AdverseEvent.bs.js";
import * as IMBRUVICA_IndirectCost$CTEM from "./ManagementMenus/IMBRUVICA_IndirectCost.bs.js";
import * as IMBRUVICA_DirectMedical$CTEM from "./ManagementMenus/IMBRUVICA_DirectMedical.bs.js";
import * as IMBRUVICA_DirectNonMedical$CTEM from "./ManagementMenus/IMBRUVICA_DirectNonMedical.bs.js";
import * as CurrencyExchangeRatesContext$CTEM from "../../../Contexts/CurrencyExchangeRatesContext.bs.js";

var allMenuType = TemplateUtils$CTEM.Imbruvica.managementTopics;

var menus = Belt_List.map(allMenuType, (function (menu) {
        return TemplateUtils$CTEM.Imbruvica.managementTopicToString(menu);
      }));

function IMBRUVICA_ManagementWrapper(Props) {
  var work = Props.work;
  var defaultData = Props.defaultData;
  var currentData = Props.currentData;
  var match = React.useState(function () {
        return 0;
      });
  var setCurrentMenuIndex = match[1];
  var currentMenuIndex = match[0];
  var match$1 = CurrencyExchangeRatesContext$CTEM.useExchangeRatesResults(undefined);
  var currencyExchangesResults = match$1.value;
  var tmp;
  tmp = typeof currencyExchangesResults === "number" || currencyExchangesResults.TAG !== /* LoadSuccess */0 ? undefined : Utils$CTEM.Currency.getRateTargetCurrencyByContryCode(currencyExchangesResults._0.rates, work.currency.code);
  var realtimeExchangeRate = Belt_Option.getWithDefault(tmp, 1);
  var match$2 = Belt_List.get(allMenuType, currentMenuIndex);
  var tmp$1;
  var exit = 0;
  if (match$2 !== undefined) {
    switch (match$2) {
      case /* ModelOverview */0 :
          exit = 1;
          break;
      case /* DirectMedical */1 :
          tmp$1 = React.createElement(IMBRUVICA_DirectMedical$CTEM.make, {
                work: work,
                currentData: currentData,
                defaultData: defaultData,
                exchangeRate: realtimeExchangeRate
              });
          break;
      case /* DirectNonMedical */2 :
          tmp$1 = React.createElement(IMBRUVICA_DirectNonMedical$CTEM.make, {
                work: work,
                currentData: currentData,
                defaultData: defaultData,
                exchangeRate: realtimeExchangeRate
              });
          break;
      case /* DirectAdverseEvent */3 :
          tmp$1 = React.createElement(IMBRUVICA_AdverseEvent$CTEM.make, {
                work: work,
                currentData: currentData,
                defaultData: defaultData,
                exchangeRate: realtimeExchangeRate
              });
          break;
      case /* IndirectCosts */4 :
          tmp$1 = React.createElement(IMBRUVICA_IndirectCost$CTEM.make, {
                work: work,
                currentData: currentData,
                defaultData: defaultData,
                exchangeRate: realtimeExchangeRate
              });
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = React.createElement(IMBRUVICA_Overview$CTEM.make, {
          work: work,
          currentData: currentData,
          defaultData: defaultData,
          realtimeExchangeRate: realtimeExchangeRate
        });
  }
  return React.createElement(SideBar$CTEM.make, {
              className: "sidebar-container-imb " + (
                currentMenuIndex === 0 ? "sidebar-overview" : ""
              ),
              menuSide: /* Left */0,
              currentMenu: currentMenuIndex,
              menus: menus,
              onToggleStep: (function (menuIdx) {
                  return Curry._1(setCurrentMenuIndex, (function (param) {
                                return menuIdx;
                              }));
                }),
              titleBoxWithFading: currentMenuIndex !== 0,
              children: tmp$1
            });
}

var make = IMBRUVICA_ManagementWrapper;

export {
  allMenuType ,
  menus ,
  make ,
  
}
/* menus Not a pure module */
