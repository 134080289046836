// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as SideBar$CTEM from "../../../SideBar.bs.js";
import * as LAIATAndMS$CTEM from "./LAIATAndMS.bs.js";
import * as LAICIAndHRU$CTEM from "./LAICIAndHRU.bs.js";
import * as LAIOverview$CTEM from "./LAIOverview.bs.js";
import * as TemplateUtils$CTEM from "../../TemplateUtils.bs.js";
import * as LAIDirectCosts$CTEM from "./LAIDirectCosts.bs.js";
import * as LAIIndirectCosts$CTEM from "./LAIIndirectCosts.bs.js";

var menus = Belt_List.map(TemplateUtils$CTEM.laiManagementTopics, TemplateUtils$CTEM.laiManagementTopicToString);

function LAIManagementWrapper(Props) {
  var workId = Props.workId;
  var currency = Props.currency;
  var defaultData = Props.defaultData;
  var currentData = Props.currentData;
  var match = React.useState(function () {
        return 0;
      });
  var setCurrentMenuIndex = match[1];
  var currentMenuIndex = match[0];
  var match$1 = Belt_List.get(TemplateUtils$CTEM.laiManagementTopics, currentMenuIndex);
  var tmp;
  if (match$1 !== undefined) {
    switch (match$1) {
      case /* LAIModelOverview */0 :
          tmp = React.createElement(LAIOverview$CTEM.make, {});
          break;
      case /* TargetedAndMS */1 :
          tmp = React.createElement(LAIATAndMS$CTEM.make, {
                workId: workId,
                defaultData: defaultData,
                currentData: currentData
              });
          break;
      case /* CIAndHRU */2 :
          tmp = React.createElement(LAICIAndHRU$CTEM.make, {
                workId: workId,
                defaultData: defaultData,
                currentData: currentData
              });
          break;
      case /* DirectCosts */3 :
          tmp = React.createElement(LAIDirectCosts$CTEM.make, {
                workId: workId,
                currency: currency,
                drugs: currentData.drugs,
                defaultDrugs: defaultData.drugs
              });
          break;
      case /* IndirectCosts */4 :
          tmp = React.createElement(LAIIndirectCosts$CTEM.make, {
                workId: workId,
                currency: currency,
                defaultData: defaultData,
                currentData: currentData.indirectCosts
              });
          break;
      
    }
  } else {
    tmp = React.createElement(LAIOverview$CTEM.make, {});
  }
  return React.createElement(SideBar$CTEM.make, {
              className: currentMenuIndex === 0 ? "sidebar-overview" : "",
              menuSide: /* Left */0,
              currentMenu: currentMenuIndex,
              menus: menus,
              onToggleStep: (function (menuIdx) {
                  return Curry._1(setCurrentMenuIndex, (function (param) {
                                return menuIdx;
                              }));
                }),
              titleBoxWithFading: currentMenuIndex !== 0,
              children: tmp
            });
}

var allMenuType = TemplateUtils$CTEM.laiManagementTopics;

var make = LAIManagementWrapper;

export {
  allMenuType ,
  menus ,
  make ,
  
}
/* menus Not a pure module */
