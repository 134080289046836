// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as TopicButton$CTEM from "../../TopicButton.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";

function LAIIntro(Props) {
  var onChangeSlide = Props.onChangeSlide;
  return React.createElement("div", {
              className: "lai-intro-section"
            }, React.createElement("div", {
                  className: "intro-detail",
                  style: {
                    paddingTop: "2%"
                  }
                }, React.createElement("div", {
                      style: {
                        display: "inline-block"
                      }
                    }, React.createElement("h3", {
                          style: {
                            color: "#C2592B"
                          }
                        }, "Illustration of Economic Impact When Introducing")), React.createElement("div", {
                      style: {
                        display: "inline-block",
                        marginLeft: "10px"
                      }
                    }, React.createElement("h3", {
                          style: {
                            color: "#60A7CF"
                          }
                        }, "LAIs"))), React.createElement("div", {
                  className: "lai-intro-topics"
                }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          "topic-buttons-container",
                          Caml_option.some(null),
                          undefined,
                          undefined
                        ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(TopicButton$CTEM.make, {
                                        title: "OUTCOMES",
                                        subTitle: "COMPARISON",
                                        topicIcon: /* Person */37,
                                        onClick: (function (param) {
                                            return Curry._1(onChangeSlide, /* OutcomeOne */1);
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(TopicButton$CTEM.make, {
                                        title: "COSTS",
                                        subTitle: "COMPARISON",
                                        topicIcon: /* DollarSign */36,
                                        onClick: (function (param) {
                                            return Curry._1(onChangeSlide, /* CostComparison */3);
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(TopicButton$CTEM.make, {
                                        title: "OVERALL COST",
                                        subTitle: "BREAKDOWN",
                                        topicIcon: /* TwoBags */35,
                                        onClick: (function (param) {
                                            return Curry._1(onChangeSlide, /* CostBreakdown */4);
                                          })
                                      })),
                              undefined,
                              undefined
                            ])))));
}

var make = LAIIntro;

export {
  make ,
  
}
/* react Not a pure module */
