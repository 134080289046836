// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function initFetchData(param, param$1) {
  
}

var initValue = {
  value: /* NotLoaded */0,
  fetchData: initFetchData
};

var context = React.createContext(initValue);

function useExchangeRatesResults(param) {
  return React.useContext(context);
}

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make,
  makeProps: makeProps
};

export {
  initFetchData ,
  initValue ,
  context ,
  useExchangeRatesResults ,
  Provider ,
  
}
/* context Not a pure module */
