// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apis$CTEM from "../../../../Effects/Apis.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Encoder$CTEM from "../../../../Effects/Encoder.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as SectionBox$CTEM from "../../SectionBox.bs.js";
import * as LAIInputForm$CTEM from "../../LAI/ManagementMenus/LAIInputForm.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as TemplateUtils$CTEM from "../../TemplateUtils.bs.js";
import Box from "@material-ui/core/Box";
import * as ModelInputHelper$CTEM from "../../../../Effects/ModelInputHelper.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

var getTreatmentName = TemplateUtils$CTEM.Imbruvica.getTreatmentName;

var columns = {
  hd: "Item",
  tl: {
    hd: "AE Rate per cohort in 6 months",
    tl: {
      hd: "Cost per AE per patient",
      tl: {
        hd: "Total per AE per cohort",
        tl: /* [] */0
      }
    }
  }
};

function totalPerAEPerCohort(numberOfPatients, cost, rate) {
  return cost.value * numberOfPatients * (rate.value / 100);
}

function sumTotalPerAEPerCohort(numberOfPatients, adverseEventDataList) {
  return Belt_List.reduce(adverseEventDataList, 0, (function (acc, param) {
                var match = param.current;
                return acc + totalPerAEPerCohort(numberOfPatients, match.cost, match.rate);
              }));
}

function sumTotalPerAEPerCohort2(numberOfPatients, ae) {
  return Belt_List.reduce({
              hd: ae.fatigue,
              tl: {
                hd: ae.anemia,
                tl: {
                  hd: ae.atrialFibrillation,
                  tl: {
                    hd: ae.hypertension,
                    tl: {
                      hd: ae.lymphopenia,
                      tl: {
                        hd: ae.haematologicalToxicity,
                        tl: {
                          hd: ae.infections,
                          tl: {
                            hd: ae.infusionReactions,
                            tl: {
                              hd: ae.leucocytopenia,
                              tl: {
                                hd: ae.neutropenia,
                                tl: {
                                  hd: ae.pneumonia,
                                  tl: {
                                    hd: ae.thrombocytopenia,
                                    tl: {
                                      hd: ae.others01,
                                      tl: {
                                        hd: ae.others02,
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }, 0, (function (acc, param) {
                return acc + totalPerAEPerCohort(numberOfPatients, param.cost, param.rate);
              }));
}

function getAdverseEventData(adverseEvent, defaultAdverseEvent) {
  return {
          hd: {
            current: adverseEvent.fatigue,
            default: defaultAdverseEvent.fatigue,
            encoder: Encoder$CTEM.Imbruvica.AdverseEvent.fatigue,
            titleEncoder: undefined
          },
          tl: {
            hd: {
              current: adverseEvent.anemia,
              default: defaultAdverseEvent.anemia,
              encoder: Encoder$CTEM.Imbruvica.AdverseEvent.anemia,
              titleEncoder: undefined
            },
            tl: {
              hd: {
                current: adverseEvent.atrialFibrillation,
                default: defaultAdverseEvent.atrialFibrillation,
                encoder: Encoder$CTEM.Imbruvica.AdverseEvent.atrialFibrillation,
                titleEncoder: undefined
              },
              tl: {
                hd: {
                  current: adverseEvent.hypertension,
                  default: defaultAdverseEvent.hypertension,
                  encoder: Encoder$CTEM.Imbruvica.AdverseEvent.hypertension,
                  titleEncoder: undefined
                },
                tl: {
                  hd: {
                    current: adverseEvent.lymphopenia,
                    default: defaultAdverseEvent.lymphopenia,
                    encoder: Encoder$CTEM.Imbruvica.AdverseEvent.lymphopenia,
                    titleEncoder: undefined
                  },
                  tl: {
                    hd: {
                      current: adverseEvent.haematologicalToxicity,
                      default: defaultAdverseEvent.haematologicalToxicity,
                      encoder: Encoder$CTEM.Imbruvica.AdverseEvent.haematologicalToxicity,
                      titleEncoder: undefined
                    },
                    tl: {
                      hd: {
                        current: adverseEvent.infections,
                        default: defaultAdverseEvent.infections,
                        encoder: Encoder$CTEM.Imbruvica.AdverseEvent.infections,
                        titleEncoder: undefined
                      },
                      tl: {
                        hd: {
                          current: adverseEvent.infusionReactions,
                          default: defaultAdverseEvent.infusionReactions,
                          encoder: Encoder$CTEM.Imbruvica.AdverseEvent.infusionReactions,
                          titleEncoder: undefined
                        },
                        tl: {
                          hd: {
                            current: adverseEvent.leucocytopenia,
                            default: defaultAdverseEvent.leucocytopenia,
                            encoder: Encoder$CTEM.Imbruvica.AdverseEvent.leucocytopenia,
                            titleEncoder: undefined
                          },
                          tl: {
                            hd: {
                              current: adverseEvent.neutropenia,
                              default: defaultAdverseEvent.neutropenia,
                              encoder: Encoder$CTEM.Imbruvica.AdverseEvent.neutropenia,
                              titleEncoder: undefined
                            },
                            tl: {
                              hd: {
                                current: adverseEvent.pneumonia,
                                default: defaultAdverseEvent.pneumonia,
                                encoder: Encoder$CTEM.Imbruvica.AdverseEvent.pneumonia,
                                titleEncoder: undefined
                              },
                              tl: {
                                hd: {
                                  current: adverseEvent.thrombocytopenia,
                                  default: defaultAdverseEvent.thrombocytopenia,
                                  encoder: Encoder$CTEM.Imbruvica.AdverseEvent.thrombocytopenia,
                                  titleEncoder: undefined
                                },
                                tl: {
                                  hd: {
                                    current: adverseEvent.others01,
                                    default: defaultAdverseEvent.others01,
                                    encoder: Encoder$CTEM.Imbruvica.AdverseEvent.others01,
                                    titleEncoder: Encoder$CTEM.Imbruvica.AdverseEvent.titleOthers01
                                  },
                                  tl: {
                                    hd: {
                                      current: adverseEvent.others02,
                                      default: defaultAdverseEvent.others02,
                                      encoder: Encoder$CTEM.Imbruvica.AdverseEvent.others02,
                                      titleEncoder: Encoder$CTEM.Imbruvica.AdverseEvent.titleOthers02
                                    },
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        };
}

function createAdverseEventTableRow($staropt$star, currency, requestUpdateData, inputDisabled, exchangeRate, numberOfPatients, requestUpdateTitle, param, param$1) {
  var defaultRate = param$1.rate;
  var cost = param.cost;
  var rate = param.rate;
  var key = $staropt$star !== undefined ? $staropt$star : "";
  return React.createElement(TableRow, {
              children: null,
              key: key
            }, React.createElement(TableCell, {
                  className: "table-content-name-cell",
                  children: requestUpdateTitle !== undefined ? React.createElement(LAIInputForm$CTEM.TextInputForm.make, {
                          title: rate.title,
                          inputDisabled: inputDisabled,
                          onTitleUpdate: Curry.__1(requestUpdateTitle),
                          onTitleRestore: (function (param) {
                              return Curry._1(requestUpdateTitle, defaultRate.title);
                            }),
                          infoTitleMode: rate.title !== defaultRate.title ? /* Restore */0 : /* Infos */({
                                _0: undefined
                              }),
                          titlePlaceholder: "Other Adverse Event"
                        }) : React.createElement(React.Fragment, {
                          children: rate.title
                        })
                }), ModelInputHelper$CTEM.Table.createFloatInputCell(inputDisabled, 1, "input-price input-ae-rate", undefined, "%", (function (newValue) {
                    return Curry._2(requestUpdateData, /* Rate */0, newValue);
                  }), defaultRate, undefined, rate), ModelInputHelper$CTEM.Table.createFloatInputCell(inputDisabled, 0, undefined, currency, undefined, (function (newValue) {
                    return Curry._2(requestUpdateData, /* Cost */1, newValue);
                  }), param$1.cost, exchangeRate, cost), ModelInputHelper$CTEM.Table.createCalculateFloatInputCell(undefined, undefined, undefined, currency, undefined, totalPerAEPerCohort(numberOfPatients, cost, rate)));
}

function createAdverseEventTable(requestUpdateData, inputDisabled, currency, exchangeRate, numberOfPatients, param, param$1) {
  var id = param.id;
  var prefixKey = "imb-adverse-event-treatment-" + (String(id) + param.treatmentName.value);
  var dataList = getAdverseEventData(param.adverseEvent, param$1.adverseEvent);
  return React.createElement(TableContainer, {
              className: "imb-table-adverse-event",
              children: React.createElement(Table, {
                    className: "imb-table-adverse-event",
                    children: null
                  }, ModelInputHelper$CTEM.Table.createHeaders(prefixKey, undefined, columns), React.createElement(TableBody, {
                        children: null
                      }, $$Array.of_list(Belt_List.mapWithIndex(dataList, (function (idx, param) {
                                  var current = param.current;
                                  var encoder = param.encoder;
                                  var key = prefixKey + (String(idx) + current.rate.title);
                                  var titleEncoder = param.titleEncoder;
                                  var defaultData = param.default;
                                  return createAdverseEventTableRow(key, currency, (function (param, param$1) {
                                                return Curry._1(requestUpdateData, {
                                                            hd: Curry._3(encoder, id, param, param$1),
                                                            tl: /* [] */0
                                                          });
                                              }), inputDisabled, exchangeRate, numberOfPatients, Belt_Option.map(titleEncoder, (function (_encoder) {
                                                    return function (param) {
                                                      return Curry._1(requestUpdateData, Curry._1(_encoder, param));
                                                    };
                                                  })), current, defaultData);
                                }))), React.createElement(TableRow, {
                            children: null
                          }, React.createElement(TableCell, {}), React.createElement(TableCell, {}), React.createElement(TableCell, {}), ModelInputHelper$CTEM.Table.createCalculateFloatInputCell(undefined, undefined, undefined, currency, undefined, sumTotalPerAEPerCohort(numberOfPatients, dataList)))))
            });
}

function IMBRUVICA_AdverseEvent(Props) {
  var work = Props.work;
  var currentData = Props.currentData;
  var defaultData = Props.defaultData;
  var exchangeRate = Props.exchangeRate;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useState(function () {
        return /* NotLoaded */0;
      });
  var setApiState = match$1[1];
  var requestUpdateData = function (normalInputs) {
    Curry._1(setApiState, (function (param) {
            return /* Loading */1;
          }));
    return Apis$CTEM.requestUpdateSavedWorkNormalInput(work.id, normalInputs, (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadSuccess */2;
                              }));
                }), (function (json) {
                  var errMsg = RequestUtils$CTEM.getResponseMsgFromJson(json);
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: errMsg
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadFailed */{
                                        _0: errMsg
                                      };
                              }));
                }));
  };
  var inputDisabled = match$1[0] === /* Loading */1;
  return React.createElement(Box, {
              className: "height-100",
              alignItems: MaterialUIDataType.AlignItems.center,
              children: React.createElement(SectionBox$CTEM.make, {
                    title: "",
                    subtitle: "Total cost per AE per cohort in 6 months = AE rate per cohort in 6 months x Cost per AE per patient x No. of patients in cohort",
                    children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some($$Array.of_list(Belt_List.keepMap(Belt_List.sort(currentData.treatments, (function (a, b) {
                                                  return Caml_primitive.caml_int_compare(a.id, b.id);
                                                })), (function (treatment) {
                                              return Belt_Option.map(Belt_List.getBy(defaultData.treatments, (function (defaultTreatment) {
                                                                return defaultTreatment.id === treatment.id;
                                                              })), (function (defaultTreatment) {
                                                            return React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
                                                                        title: getTreatmentName(treatment),
                                                                        className: ModelInputHelper$CTEM.treatmentBgClassName(treatment),
                                                                        gridSize: 12,
                                                                        children: createAdverseEventTable(requestUpdateData, inputDisabled, work.currency, exchangeRate, currentData.numberOfPatients.value, treatment, defaultTreatment),
                                                                        key: "imb-ae-" + (String(treatment.id) + ("-" + treatment.treatmentName.title))
                                                                      });
                                                          }));
                                            })))),
                              undefined,
                              undefined
                            ]))
                  })
            });
}

var createFloatInputEl = ModelInputHelper$CTEM.createFloatInputEl;

var bgClassName = ModelInputHelper$CTEM.treatmentBgClassName;

var make = IMBRUVICA_AdverseEvent;

export {
  createFloatInputEl ,
  bgClassName ,
  getTreatmentName ,
  columns ,
  totalPerAEPerCohort ,
  sumTotalPerAEPerCohort ,
  sumTotalPerAEPerCohort2 ,
  getAdverseEventData ,
  createAdverseEventTableRow ,
  createAdverseEventTable ,
  make ,
  
}
/* react Not a pure module */
