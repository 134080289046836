// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as CtemIcon$CTEM from "./CtemIcon.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

function TopicButton(Props) {
  var title = Props.title;
  var subTitleOpt = Props.subTitle;
  var topicIcon = Props.topicIcon;
  var onClick = Props.onClick;
  var subTitle = subTitleOpt !== undefined ? subTitleOpt : "";
  return React.createElement(Button, {
              children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        Caml_option.some(null),
                        undefined,
                        undefined
                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(CtemIcon$CTEM.make, {
                                      iconType: topicIcon,
                                      className: "topic-icon"
                                    })),
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(null),
                            undefined,
                            undefined
                          ]), React.createElement("h3", undefined, title), React.createElement("p", undefined, subTitle))),
              className: "topic-button",
              onClick: onClick
            });
}

var make = TopicButton;

export {
  make ,
  
}
/* react Not a pure module */
