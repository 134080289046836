// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as CtemIcon$CTEM from "./CtemIcon.bs.js";
import * as IconButton2$CTEM from "./MuiComponents/IconButton2.bs.js";
import * as ModalLogout$CTEM from "./ModalLogout.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as ModalItemGroup$CTEM from "./ModalItemGroup.bs.js";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

function UserMenu$V2(Props) {
  var match = React.useState(function () {
        return false;
      });
  var setShowUserMenu = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowLogoutModal = match$1[1];
  var onClick = function (e) {
    e.stopPropagation();
    e.preventDefault();
    return Curry._1(setShowUserMenu, (function (param) {
                  return true;
                }));
  };
  var onTouchEnd = function (e) {
    e.stopPropagation();
    e.preventDefault();
    return Curry._1(setShowUserMenu, (function (param) {
                  return true;
                }));
  };
  var modalItems_0 = {
    title: "Choose Model",
    color: "black",
    onClick: (function (param) {
        Curry._1(setShowUserMenu, (function (param) {
                return false;
              }));
        return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath(/* SelectModel */0));
      })
  };
  var modalItems_1 = {
    hd: {
      title: "Logout",
      color: "black",
      onClick: (function (param) {
          Curry._1(setShowUserMenu, (function (param) {
                  return false;
                }));
          return Curry._1(setShowLogoutModal, (function (param) {
                        return true;
                      }));
        })
    },
    tl: /* [] */0
  };
  var modalItems = {
    hd: modalItems_0,
    tl: modalItems_1
  };
  return React.createElement("div", undefined, React.createElement(ClickAwayListener, {
                  children: React.createElement(IconButton, IconButton2$CTEM.makeProps(undefined, undefined, undefined, undefined, undefined, onClick, onTouchEnd, undefined, undefined, undefined, Caml_option.some(React.createElement(CtemIcon$CTEM.make, {
                                    iconType: /* UserProfile */19,
                                    className: "icon-outline-text-color"
                                  })), "popper-user-menu", undefined)),
                  onClickAway: (function (param) {
                      return Curry._1(setShowUserMenu, (function (param) {
                                    return false;
                                  }));
                    })
                }), match[0] ? React.createElement("div", {
                    className: "user-menu-popper user-menu-popper-2"
                  }, React.createElement(ModalItemGroup$CTEM.make, {
                        modalItems: modalItems,
                        onCancel: (function (param) {
                            return Curry._1(setShowUserMenu, (function (param) {
                                          return false;
                                        }));
                          })
                      })) : null, match$1[0] ? React.createElement(ModalLogout$CTEM.make, {
                    onHandleClose: (function (param) {
                        return Curry._1(setShowLogoutModal, (function (param) {
                                      return false;
                                    }));
                      }),
                    onConfirm: (function (param) {
                        localStorage.removeItem("token");
                        return ReasonReactRouter.push(UrlPath$CTEM.Route.getNonMemberPath(/* Login */0));
                      })
                  }) : null);
}

var V2 = {
  make: UserMenu$V2
};

function UserMenu(Props) {
  var match = React.useState(function () {
        return null;
      });
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowLogoutModal = match$1[1];
  var popperId = "popper-user-menu";
  var onClick = function (e) {
    e.stopPropagation();
    var target = e.currentTarget;
    if (anchorEl == null) {
      return Curry._1(setAnchorEl, (function (param) {
                    return target;
                  }));
    } else {
      return Curry._1(setAnchorEl, (function (param) {
                    return null;
                  }));
    }
  };
  var onTouchEnd = function (e) {
    e.stopPropagation();
    e.preventDefault();
    var target = e.currentTarget;
    if (anchorEl == null) {
      return Curry._1(setAnchorEl, (function (param) {
                    return target;
                  }));
    } else {
      return Curry._1(setAnchorEl, (function (param) {
                    return null;
                  }));
    }
  };
  var modalItems_0 = {
    title: "Choose Model",
    color: "black",
    onClick: (function (param) {
        Curry._1(setAnchorEl, (function (param) {
                return null;
              }));
        return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath(/* SelectModel */0));
      })
  };
  var modalItems_1 = {
    hd: {
      title: "Logout",
      color: "black",
      onClick: (function (param) {
          Curry._1(setAnchorEl, (function (param) {
                  return null;
                }));
          return Curry._1(setShowLogoutModal, (function (param) {
                        return true;
                      }));
        })
    },
    tl: /* [] */0
  };
  var modalItems = {
    hd: modalItems_0,
    tl: modalItems_1
  };
  return React.createElement("div", undefined, React.createElement(ClickAwayListener, {
                  children: React.createElement(IconButton, IconButton2$CTEM.makeProps(undefined, undefined, undefined, undefined, undefined, onClick, onTouchEnd, undefined, undefined, undefined, Caml_option.some(React.createElement(CtemIcon$CTEM.make, {
                                    iconType: /* UserProfile */19,
                                    className: "icon-outline-text-color"
                                  })), popperId, undefined)),
                  onClickAway: (function (param) {
                      if (!(anchorEl == null)) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      return null;
                                    }));
                      }
                      
                    })
                }), React.createElement(Popper, {
                  id: popperId,
                  className: "user-menu-popper",
                  anchorEl: anchorEl,
                  children: React.createElement(ModalItemGroup$CTEM.make, {
                        modalItems: modalItems,
                        onCancel: (function (param) {
                            return Curry._1(setAnchorEl, (function (param) {
                                          return null;
                                        }));
                          })
                      }),
                  open: !(anchorEl == null),
                  placement: "bottom-start"
                }), match$1[0] ? React.createElement(ModalLogout$CTEM.make, {
                    onHandleClose: (function (param) {
                        return Curry._1(setShowLogoutModal, (function (param) {
                                      return false;
                                    }));
                      }),
                    onConfirm: (function (param) {
                        localStorage.removeItem("token");
                        return ReasonReactRouter.push(UrlPath$CTEM.Route.getNonMemberPath(/* Login */0));
                      })
                  }) : null);
}

var make = UserMenu;

export {
  V2 ,
  make ,
  
}
/* react Not a pure module */
