// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TopBar$CTEM from "../Components/TopBar.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as ModelsContext$CTEM from "../Contexts/ModelsContext.bs.js";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

function uniqModelByMID(models) {
  return $$Array.to_list(Js_dict.values(Js_dict.fromList(Belt_List.map(models, (function (model) {
                            return [
                                    model.id,
                                    model
                                  ];
                          })))));
}

function gridWorkEl(key, model) {
  var onClick = function (param) {
    return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath({
                    TAG: /* Dashboard */0,
                    _0: model.modelType
                  }));
  };
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(React.createElement("div", {
                            className: "select-model-box-container ",
                            onClick: onClick
                          }, React.createElement("div", {
                                className: "model-box-name text-center"
                              }, Utils$CTEM.textWithNewLine(model.name)))),
                  key,
                  undefined
                ]));
}

function SelectModelPage(Props) {
  var match = ModelsContext$CTEM.useModelsResults(undefined);
  var loadModels = match[1];
  var modelsContext = match[0];
  React.useEffect((function () {
          Curry._1(loadModels, undefined);
          
        }), []);
  var tmp;
  tmp = typeof modelsContext === "number" ? (
      modelsContext === /* NotLoaded */0 ? null : React.createElement("div", {
              className: "loading-wrapper_expand"
            }, React.createElement(CircularProgress, {
                  color: MaterialUIDataType.MainWithInheritColor.inherit_
                }))
    ) : (
      modelsContext.TAG === /* LoadSuccess */0 ? React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some($$Array.of_list(Belt_List.mapWithIndex(uniqModelByMID(modelsContext._0), (function (idx, model) {
                                  var onClick = function (param) {
                                    return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath({
                                                    TAG: /* Dashboard */0,
                                                    _0: model.modelType
                                                  }));
                                  };
                                  var bgClassName = (
                                    model.modelType === /* LAI */0 ? "lai" : "imb"
                                  ) + "-select-model-box";
                                  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                  undefined,
                                                  Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  Caml_option.some(React.createElement("div", {
                                                            className: "select-model-box-container " + bgClassName,
                                                            onClick: onClick
                                                          }, React.createElement("div", {
                                                                className: "model-box-name text-center"
                                                              }, Utils$CTEM.textWithNewLine(model.name)))),
                                                  "select-model-type-" + idx + "-" + model.id,
                                                  undefined
                                                ]));
                                })))),
                  undefined,
                  undefined
                ])) : React.createElement("p", {
              style: {
                marginTop: "3rem",
                textAlign: "start",
                width: "100%"
              }
            }, Utils$CTEM.textWithNewLine(modelsContext._0))
    );
  return React.createElement("div", {
              className: "dashboard-page"
            }, React.createElement(TopBar$CTEM.make, {
                  mode: /* Default */0
                }), React.createElement("div", {
                  className: "homepage-content"
                }, React.createElement("h1", {
                      className: "font-weight-light"
                    }, "Choose Model"), tmp));
}

var make = SelectModelPage;

export {
  uniqModelByMID ,
  gridWorkEl ,
  make ,
  
}
/* react Not a pure module */
