// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as LAIIntro$CTEM from "./LAIIntro.bs.js";
import * as ModelHeader$CTEM from "../ModelHeader.bs.js";
import * as CostBreakdown$CTEM from "./CostBreakdown.bs.js";
import * as LAIOutcomeOne$CTEM from "./LAIOutcomeOne.bs.js";
import * as LAIOutcomeTwo$CTEM from "./LAIOutcomeTwo.bs.js";
import * as CostComparison$CTEM from "./CostComparison.bs.js";
import * as PresentationTemplate$CTEM from "../PresentationTemplate.bs.js";

var laiSlides = {
  hd: /* LAIIntro */0,
  tl: {
    hd: /* OutcomeOne */1,
    tl: {
      hd: /* OutcomeTwo */2,
      tl: {
        hd: /* CostComparison */3,
        tl: {
          hd: /* CostBreakdown */4,
          tl: /* [] */0
        }
      }
    }
  }
};

function findCurrentSlide(slideIdx) {
  return Belt_Option.getWithDefault(Belt_List.get(laiSlides, slideIdx), /* LAIIntro */0);
}

function currentSlideToNumber(targetSlide) {
  return Belt_Option.getWithDefault(Belt_Array.getIndexBy($$Array.of_list(laiSlides), (function (slide) {
                    return slide === targetSlide;
                  })), 0);
}

var allSlides = Belt_List.map(laiSlides, currentSlideToNumber);

function TemplateLAI2(Props) {
  var work = Props.work;
  var workNameOpt = Props.workName;
  var data = Props.data;
  var workName = workNameOpt !== undefined ? workNameOpt : "";
  return React.createElement(PresentationTemplate$CTEM.make, {
              work: work,
              allSlides: allSlides,
              initSlide: Belt_List.headExn(allSlides),
              children: (function (currentSlide, onChangeSlide) {
                  var match = findCurrentSlide(currentSlide);
                  switch (match) {
                    case /* LAIIntro */0 :
                        return React.createElement("div", {
                                    className: "slide-content"
                                  }, React.createElement("div", {
                                        className: "title main-title"
                                      }, React.createElement(ModelHeader$CTEM.make, {
                                            title: work.modelName,
                                            subTitle: workName
                                          })), React.createElement("div", {
                                        className: "content text-center",
                                        style: {
                                          height: "calc(80% - 175px)"
                                        }
                                      }, React.createElement(LAIIntro$CTEM.make, {
                                            onChangeSlide: (function (slide) {
                                                return Curry._1(onChangeSlide, currentSlideToNumber(slide));
                                              })
                                          })));
                    case /* OutcomeOne */1 :
                        return PresentationTemplate$CTEM.slideContent("", "OUTCOMES COMPARISON 1/2", React.createElement(LAIOutcomeOne$CTEM.make, {
                                        data: data
                                      }), "10px 2%");
                    case /* OutcomeTwo */2 :
                        return PresentationTemplate$CTEM.slideContent("", "OUTCOMES COMPARISON 2/2", React.createElement(LAIOutcomeTwo$CTEM.make, {
                                        data: data
                                      }), "10px 2%");
                    case /* CostComparison */3 :
                        return PresentationTemplate$CTEM.slideContent("", "COSTS COMPARISON", React.createElement(CostComparison$CTEM.make, {
                                        data: data,
                                        currencyCode: work.currency.code
                                      }), "10px 2%");
                    case /* CostBreakdown */4 :
                        return PresentationTemplate$CTEM.slideContent("", "OVERALL COST BREAKDOWN", React.createElement(CostBreakdown$CTEM.make, {
                                        countryName: work.countryName,
                                        data: data,
                                        currencyCode: work.currency.code
                                      }), "10px 2%");
                    
                  }
                })
            });
}

var make = TemplateLAI2;

export {
  laiSlides ,
  findCurrentSlide ,
  currentSlideToNumber ,
  allSlides ,
  make ,
  
}
/* allSlides Not a pure module */
