// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as CtemTooltip$CTEM from "./CtemTooltip.bs.js";
import Info from "@material-ui/icons/Info";
import Replay from "@material-ui/icons/Replay";

function RestoreButton(Props) {
  var dynamicTooltipPositionOpt = Props.dynamicTooltipPosition;
  var tooltipTitle = Props.tooltipTitle;
  var tooltipClassNameOpt = Props.tooltipClassName;
  var mode = Props.mode;
  var onRestore = Props.onRestore;
  var dynamicTooltipPosition = dynamicTooltipPositionOpt !== undefined ? dynamicTooltipPositionOpt : true;
  var tooltipClassName = tooltipClassNameOpt !== undefined ? tooltipClassNameOpt : "";
  var match = React.useReducer((function (state, action) {
          return {
                  isActive: !state.isActive
                };
        }), {
        isActive: false
      });
  var dispatch = match[1];
  var tmp;
  if (mode) {
    var infos = mode._0;
    tmp = infos !== undefined && infos !== "" ? React.createElement(React.Fragment, {
            children: null
          }, React.createElement("div", {
                style: {
                  display: "contents"
                },
                onClick: (function (param) {
                    return Curry._1(dispatch, /* ToggleInfo */0);
                  })
              }, React.createElement(Info, {})), match[0].isActive ? React.createElement(CtemTooltip$CTEM.make, {
                  dynamicPosition: dynamicTooltipPosition,
                  tooltipClassName: tooltipClassName,
                  onCancel: (function (param) {
                      return Curry._1(dispatch, /* ToggleInfo */0);
                    }),
                  children: null
                }, tooltipTitle !== undefined ? React.createElement("p", undefined, tooltipTitle) : null, React.createElement("small", undefined, Utils$CTEM.textWithNewLine(infos))) : null) : null;
  } else {
    var tmp$1 = {};
    if (onRestore !== undefined) {
      tmp$1.onClick = Caml_option.valFromOption(onRestore);
    }
    tmp = React.createElement("div", tmp$1, React.createElement(Replay, {}));
  }
  return React.createElement("div", {
              className: "restore-component"
            }, tmp);
}

var make = RestoreButton;

export {
  make ,
  
}
/* react Not a pure module */
