// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as TemplateLAI2$CTEM from "../Components/ModelTemplates/LAI/TemplateLAI2.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";
import * as IMBRUVICA_Presentation$CTEM from "../Components/ModelTemplates/IMBRUVICA/Presentation/IMBRUVICA_Presentation.bs.js";
import * as CurrencyExchangeRatesContext$CTEM from "../Contexts/CurrencyExchangeRatesContext.bs.js";

function PresentationPage(Props) {
  var work = Props.work;
  var match = CurrencyExchangeRatesContext$CTEM.useExchangeRatesResults(undefined);
  var fetchExchangeRates = match.fetchData;
  React.useEffect((function () {
          var match = work.templateData;
          if (match !== undefined && match.TAG !== /* TemplateLAI */0) {
            Curry._2(fetchExchangeRates, undefined, undefined);
          }
          
        }), []);
  var match$1 = work.templateData;
  var tmp;
  tmp = match$1 !== undefined ? (
      match$1.TAG === /* TemplateLAI */0 ? React.createElement(TemplateLAI2$CTEM.make, {
              work: work,
              workName: work.fileName,
              data: match$1._0
            }) : React.createElement(IMBRUVICA_Presentation$CTEM.make, {
              work: work,
              data: match$1._0
            })
    ) : null;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "presentation-container",
                  Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(tmp),
                                undefined,
                                undefined
                              ]))),
                  undefined,
                  undefined
                ]));
}

var make = PresentationPage;

export {
  make ,
  
}
/* react Not a pure module */
