// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apis$CTEM from "../../../../Effects/Apis.bs.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as EncodeLAI$CTEM from "../EncodeLAI.bs.js";
import * as FloatInput$CTEM from "../../../FloatInput.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as RestoreButton$CTEM from "../../../RestoreButton.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";

var oralColumns = {
  hd: "ORAL",
  tl: {
    hd: "Defined Daily Dose (DDD; mg)",
    tl: {
      hd: "Price/mg",
      tl: {
        hd: "Price/Day",
        tl: {
          hd: "Price/Year",
          tl: /* [] */0
        }
      }
    }
  }
};

var laiColumns = {
  hd: "LAI",
  tl: {
    hd: "",
    tl: {
      hd: "",
      tl: {
        hd: "",
        tl: {
          hd: "",
          tl: /* [] */0
        }
      }
    }
  }
};

function isOriginalDrug(drugA, drugB) {
  if (drugA.dailyDose === drugB.dailyDose) {
    return drugA.costPerUnit === drugB.costPerUnit;
  } else {
    return false;
  }
}

function updateDrugOfDirectCost(newDrugData, drugs, updateDrugs) {
  return Curry._1(updateDrugs, List.map((function (drug) {
                    if (drug.id === newDrugData.id) {
                      return newDrugData;
                    } else {
                      return drug;
                    }
                  }), drugs));
}

function drugFloatInput(disabledOpt, digitOpt, priceSource, classNameOpt, currency, onPriceRetore, onPriceUpdate, defaultPrice, price) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var className = classNameOpt !== undefined ? classNameOpt : "input-price";
  var tmp = {
    value: price,
    className: className,
    disabled: disabled,
    digit: digit
  };
  if (onPriceUpdate !== undefined) {
    tmp.onValueUpdate = Caml_option.valFromOption(onPriceUpdate);
  }
  if (currency !== undefined) {
    tmp.currency = Caml_option.valFromOption(currency);
  }
  var tmp$1;
  if (defaultPrice !== undefined) {
    var tmp$2 = {
      mode: defaultPrice !== price ? /* Restore */0 : /* Infos */({
            _0: priceSource
          })
    };
    if (onPriceRetore !== undefined) {
      tmp$2.onRestore = Caml_option.valFromOption(onPriceRetore);
    }
    tmp$1 = React.createElement(RestoreButton$CTEM.make, tmp$2);
  } else {
    tmp$1 = null;
  }
  return React.createElement(TableCell, {
              align: "left",
              children: React.createElement("div", {
                    className: "table-info-content"
                  }, React.createElement(FloatInput$CTEM.make, tmp), tmp$1)
            });
}

function makeDrugTable(currency, isOralDrug, drugs, defaultDrugs, updateDrugs, disabled) {
  var allDrugs = List.filter(function (drug) {
          if (isOralDrug) {
            return drug.drugType === /* OralDrug */0;
          } else {
            return drug.drugType !== /* OralDrug */0;
          }
        })(drugs);
  var match = List.length(allDrugs);
  var columns = isOralDrug ? oralColumns : laiColumns;
  return React.createElement(TableContainer, {
              className: "table-direct-cost",
              children: React.createElement(Table, {
                    className: "table-direct-cost",
                    children: null
                  }, match !== 0 ? null : React.createElement("caption", {
                          style: {
                            textAlign: "center"
                          }
                        }, "No oral medications selected"), React.createElement(TableHead, {
                        children: React.createElement(TableRow, {
                              children: $$Array.of_list(List.mapi((function (index, columnName) {
                                          return React.createElement(TableCell, {
                                                      align: "left",
                                                      style: {
                                                        width: String(Caml_int32.div(100, List.length(columns))) + "%"
                                                      },
                                                      children: React.createElement("b", undefined, columnName),
                                                      key: "table-head-direct-cost" + String(index)
                                                    });
                                        }), columns))
                            })
                      }), React.createElement(TableBody, {
                        children: $$Array.of_list(List.mapi((function (index, drug) {
                                    var originalDrug = List.find_opt((function (defaultDrug) {
                                            if (isOralDrug ? defaultDrug.drugType === /* OralDrug */0 : defaultDrug.drugType !== /* OralDrug */0) {
                                              return defaultDrug.drugNameKey === drug.drugNameKey;
                                            } else {
                                              return false;
                                            }
                                          }), defaultDrugs);
                                    var match = drug.description;
                                    var tmp = match === "" ? null : React.createElement(RestoreButton$CTEM.make, {
                                            mode: /* Infos */{
                                              _0: drug.description
                                            }
                                          });
                                    var timePerYear = drug.drugType;
                                    return React.createElement(TableRow, {
                                                children: null,
                                                key: "drug-cost-row-" + index + "-" + drug.id.toString()
                                              }, React.createElement(TableCell, {
                                                    style: {
                                                      display: "flex",
                                                      width: "170px",
                                                      alignItems: "center",
                                                      justifyContent: "space-between"
                                                    },
                                                    children: null
                                                  }, React.createElement("p", undefined, drug.name), tmp), drugFloatInput(disabled, 0, undefined, "input-unit", undefined, (function (param) {
                                                      Belt_Option.map(originalDrug, (function (d) {
                                                              var init = drug.dailyDose;
                                                              return updateDrugOfDirectCost({
                                                                          id: drug.id,
                                                                          name: drug.name,
                                                                          drugNameKey: drug.drugNameKey,
                                                                          description: drug.description,
                                                                          dailyDose: {
                                                                            title: init.title,
                                                                            value: d.dailyDose.value,
                                                                            source: init.source
                                                                          },
                                                                          costPerUnit: drug.costPerUnit,
                                                                          marketShare: drug.marketShare,
                                                                          drugType: drug.drugType
                                                                        }, drugs, updateDrugs);
                                                            }));
                                                      
                                                    }), (function (value) {
                                                      var init = drug.dailyDose;
                                                      return updateDrugOfDirectCost({
                                                                  id: drug.id,
                                                                  name: drug.name,
                                                                  drugNameKey: drug.drugNameKey,
                                                                  description: drug.description,
                                                                  dailyDose: {
                                                                    title: init.title,
                                                                    value: value,
                                                                    source: init.source
                                                                  },
                                                                  costPerUnit: drug.costPerUnit,
                                                                  marketShare: drug.marketShare,
                                                                  drugType: drug.drugType
                                                                }, drugs, updateDrugs);
                                                    }), Belt_Option.mapWithDefault(originalDrug, 0, (function (d) {
                                                          return d.dailyDose.value;
                                                        })), drug.dailyDose.value), drugFloatInput(disabled, 3, undefined, undefined, currency.code, (function (param) {
                                                      Belt_Option.map(originalDrug, (function (d) {
                                                              var init = drug.costPerUnit;
                                                              return updateDrugOfDirectCost({
                                                                          id: drug.id,
                                                                          name: drug.name,
                                                                          drugNameKey: drug.drugNameKey,
                                                                          description: drug.description,
                                                                          dailyDose: drug.dailyDose,
                                                                          costPerUnit: {
                                                                            title: init.title,
                                                                            value: d.costPerUnit.value,
                                                                            source: init.source
                                                                          },
                                                                          marketShare: drug.marketShare,
                                                                          drugType: drug.drugType
                                                                        }, drugs, updateDrugs);
                                                            }));
                                                      
                                                    }), (function (value) {
                                                      var init = drug.costPerUnit;
                                                      return updateDrugOfDirectCost({
                                                                  id: drug.id,
                                                                  name: drug.name,
                                                                  drugNameKey: drug.drugNameKey,
                                                                  description: drug.description,
                                                                  dailyDose: drug.dailyDose,
                                                                  costPerUnit: {
                                                                    title: init.title,
                                                                    value: value,
                                                                    source: init.source
                                                                  },
                                                                  marketShare: drug.marketShare,
                                                                  drugType: drug.drugType
                                                                }, drugs, updateDrugs);
                                                    }), Belt_Option.mapWithDefault(originalDrug, 0, (function (d) {
                                                          return d.costPerUnit.value;
                                                        })), drug.costPerUnit.value), drugFloatInput(true, undefined, undefined, undefined, currency.code, undefined, undefined, undefined, Utils$CTEM.getNumberFormat(drug.costPerUnit.value * drug.dailyDose.value)), drugFloatInput(true, undefined, undefined, undefined, currency.code, undefined, undefined, undefined, Utils$CTEM.getNumberFormat(drug.costPerUnit.value * drug.dailyDose.value * (
                                                        timePerYear ? timePerYear._0 : 365
                                                      ))));
                                  }), allDrugs))
                      }))
            });
}

function LAIDirectCosts(Props) {
  var workId = Props.workId;
  var currency = Props.currency;
  var drugs = Props.drugs;
  var defaultDrugs = Props.defaultDrugs;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useReducer((function (state, action) {
          if (action.TAG === /* RequestState */0) {
            return {
                    currentDirectCost: state.currentDirectCost,
                    loadingData: action._0
                  };
          } else {
            return {
                    currentDirectCost: action._0,
                    loadingData: state.loadingData
                  };
          }
        }), {
        currentDirectCost: /* [] */0,
        loadingData: false
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var updateDrugs = function (drugs) {
    Curry._1(dispatch, {
          TAG: /* RequestState */0,
          _0: true
        });
    return Apis$CTEM.requestUpdateSavedWork(workId, EncodeLAI$CTEM.drugsData(drugs), (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }), (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }));
  };
  var initialData = function (param) {
    var drugsSelected = List.filter(function (drug) {
            if (drug.marketShare.withoutLAI.value > 0 && drug.marketShare.withLAI.value > 0) {
              return true;
            } else {
              return drug.drugType !== /* OralDrug */0;
            }
          })(drugs);
    return Curry._1(dispatch, {
                TAG: /* UpdateDirectCost */1,
                _0: drugsSelected
              });
  };
  React.useEffect((function () {
          initialData(undefined);
          
        }), []);
  React.useEffect((function () {
          if (drugs !== state.currentDirectCost) {
            initialData(undefined);
          }
          
        }), [drugs]);
  var inputDisabled = state.loadingData;
  return React.createElement("div", {
              style: {
                overflowX: "scroll"
              }
            }, makeDrugTable(currency, true, state.currentDirectCost, defaultDrugs, updateDrugs, inputDisabled), makeDrugTable(currency, false, state.currentDirectCost, defaultDrugs, updateDrugs, inputDisabled));
}

var make = LAIDirectCosts;

export {
  oralColumns ,
  laiColumns ,
  isOriginalDrug ,
  updateDrugOfDirectCost ,
  drugFloatInput ,
  makeDrugTable ,
  make ,
  
}
/* react Not a pure module */
