// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ModelHeader$CTEM from "../../ModelHeader.bs.js";
import * as TemplateUtils$CTEM from "../../TemplateUtils.bs.js";
import * as PresentationTemplate$CTEM from "../../PresentationTemplate.bs.js";
import * as IMBRUVICA_AdverseEvent$CTEM from "../ManagementMenus/IMBRUVICA_AdverseEvent.bs.js";
import * as IMBRUVICA_IndirectCost$CTEM from "../ManagementMenus/IMBRUVICA_IndirectCost.bs.js";
import * as IMBRUVICA_DirectMedical$CTEM from "../ManagementMenus/IMBRUVICA_DirectMedical.bs.js";
import * as IMBRUVICA_DirectNonMedical$CTEM from "../ManagementMenus/IMBRUVICA_DirectNonMedical.bs.js";
import * as IMBRUVICA_PresentationIntro$CTEM from "./IMBRUVICA_PresentationIntro.bs.js";
import * as IMBRUVICA_PresentationTotalCost$CTEM from "./IMBRUVICA_PresentationTotalCost.bs.js";
import * as IMBRUVICA_PresentationTotalCostChart$CTEM from "./IMBRUVICA_PresentationTotalCostChart.bs.js";

var adverseEventMonths = TemplateUtils$CTEM.Imbruvica.adverseEventMonths;

var getTreatmentName = TemplateUtils$CTEM.Imbruvica.getTreatmentName;

var slides = {
  hd: /* Intro */0,
  tl: {
    hd: /* TotalCostChartByCostType */1,
    tl: {
      hd: /* TotalCostPerCohortChart */2,
      tl: {
        hd: /* TotalCostPerCohortTable */3,
        tl: {
          hd: /* TotalCostPerPatientChart */4,
          tl: {
            hd: /* TotalCostPerPatientTable */5,
            tl: /* [] */0
          }
        }
      }
    }
  }
};

function findCurrentSlide(slideIdx) {
  return Belt_Option.getWithDefault(Belt_List.get(slides, slideIdx), /* Intro */0);
}

function currentSlideToNumber(targetSlide) {
  return Belt_Option.getWithDefault(Belt_Array.getIndexBy($$Array.of_list(slides), (function (slide) {
                    return slide === targetSlide;
                  })), 0);
}

var allSlides = Belt_List.map(slides, currentSlideToNumber);

function calAdverseEventSpecificMonths(numberOfPatients, months, ae) {
  return IMBRUVICA_AdverseEvent$CTEM.sumTotalPerAEPerCohort2(numberOfPatients, ae) / adverseEventMonths * months;
}

function createTreatmentTotalCost(months, patients, param, t) {
  var match = IMBRUVICA_IndirectCost$CTEM.getPatientCareGiverLossIncome(param.patientMonthlyIncome, param.caregiverMonthlyIncome, t);
  var totalIndirect = match[0] + match[1];
  var createObject = function (costName, cost) {
    return {
            title: costName + " cost",
            value: cost,
            source: ""
          };
  };
  var arg = t.cost.value + IMBRUVICA_DirectMedical$CTEM.totalCost(t.preMedication);
  var arg$1 = IMBRUVICA_DirectNonMedical$CTEM.totalCost(t.nonMedication);
  var arg$2 = calAdverseEventSpecificMonths(patients, months, t.adverseEvent);
  return {
          id: t.id,
          treatmentName: getTreatmentName(t),
          medical: createObject("Medical", arg),
          nonMedical: createObject("Other Direct Medical\nand Non-Medical", arg$1),
          adverseEvent: createObject("Adverse Events", arg$2),
          indirect: createObject("Indirect", totalIndirect)
        };
}

function createTotalCost(months, patients, data) {
  return {
          numberOfPatients: data.numberOfPatients.value,
          treatmentDurationMonths: data.treamentDurationMonths.value,
          treatments: Belt_List.map(data.treatments, (function (param) {
                  return createTreatmentTotalCost(months, patients, data, param);
                }))
        };
}

function perCohortTitle(monthStr) {
  return "PER COHORT FOR " + monthStr.toUpperCase();
}

var perPatientTitle = "PER PATIENT FOR 1 MONTH";

function createSlideContent(param, param$1) {
  return function (param$2) {
    var param$3 = "10px 2%";
    return PresentationTemplate$CTEM.slideContent(param, param$1, param$2, param$3);
  };
}

function IMBRUVICA_Presentation(Props) {
  var work = Props.work;
  var data = Props.data;
  var match = React.useState(function () {
        return createTotalCost(1, 1, data);
      });
  var setCohortTotalCost = match[1];
  var totalCostPerMonthPerPatient = match[0];
  React.useEffect((function () {
          Curry._1(setCohortTotalCost, (function (param) {
                  return createTotalCost(1, 1, data);
                }));
          
        }), [data]);
  var monthStr = Utils$CTEM.pluralWorldSimple(undefined, undefined, totalCostPerMonthPerPatient.treatmentDurationMonths, "month");
  return React.createElement(PresentationTemplate$CTEM.make, {
              work: work,
              allSlides: allSlides,
              initSlide: Belt_List.headExn(allSlides),
              className: "template-imb",
              children: (function (currentSlide, onChangeSlide) {
                  var onChangeSlide$1 = function (newSlide) {
                    return Curry._1(onChangeSlide, currentSlideToNumber(newSlide));
                  };
                  var match = findCurrentSlide(currentSlide);
                  switch (match) {
                    case /* Intro */0 :
                        return React.createElement("div", {
                                    className: "slide-content"
                                  }, React.createElement("div", {
                                        className: "title main-title"
                                      }, React.createElement(ModelHeader$CTEM.make, {
                                            title: work.modelName,
                                            subTitle: work.fileName
                                          })), React.createElement("div", {
                                        className: "content text-center",
                                        style: {
                                          height: "calc(80% - 175px)"
                                        }
                                      }, React.createElement(IMBRUVICA_PresentationIntro$CTEM.make, {
                                            patients: data.numberOfPatients.value,
                                            months: data.treamentDurationMonths.value,
                                            currency: work.currency.code,
                                            onChangeSlide: onChangeSlide$1
                                          })));
                    case /* TotalCostChartByCostType */1 :
                        return createSlideContent("", "PER COHORT FOR " + monthStr.toUpperCase())(React.createElement(IMBRUVICA_PresentationTotalCostChart$CTEM.make, {
                                        currency: work.currency,
                                        totalCostPerMonthPerPatient: totalCostPerMonthPerPatient,
                                        patients: totalCostPerMonthPerPatient.numberOfPatients,
                                        months: totalCostPerMonthPerPatient.treatmentDurationMonths,
                                        chart: /* Cost */1,
                                        showBarLabel: false,
                                        goToTableView: (function (param) {
                                            return Curry._1(onChangeSlide, currentSlideToNumber(/* TotalCostPerCohortTable */3));
                                          })
                                      }));
                    case /* TotalCostPerCohortChart */2 :
                        return createSlideContent("", "PER COHORT FOR " + monthStr.toUpperCase())(React.createElement(IMBRUVICA_PresentationTotalCostChart$CTEM.make, {
                                        currency: work.currency,
                                        totalCostPerMonthPerPatient: totalCostPerMonthPerPatient,
                                        patients: totalCostPerMonthPerPatient.numberOfPatients,
                                        months: totalCostPerMonthPerPatient.treatmentDurationMonths,
                                        goToTableView: (function (param) {
                                            return Curry._1(onChangeSlide, currentSlideToNumber(/* TotalCostPerCohortTable */3));
                                          })
                                      }));
                    case /* TotalCostPerCohortTable */3 :
                        return createSlideContent("", "PER COHORT FOR " + monthStr.toUpperCase())(React.createElement(IMBRUVICA_PresentationTotalCost$CTEM.make, {
                                        currency: work.currency,
                                        totalCostPerMonthPerPatient: totalCostPerMonthPerPatient,
                                        months: totalCostPerMonthPerPatient.treatmentDurationMonths,
                                        patients: totalCostPerMonthPerPatient.numberOfPatients,
                                        goToChartView: (function (param) {
                                            return Curry._1(onChangeSlide, currentSlideToNumber(/* TotalCostPerCohortChart */2));
                                          })
                                      }));
                    case /* TotalCostPerPatientChart */4 :
                        return createSlideContent("", perPatientTitle)(React.createElement(IMBRUVICA_PresentationTotalCostChart$CTEM.make, {
                                        currency: work.currency,
                                        totalCostPerMonthPerPatient: totalCostPerMonthPerPatient,
                                        patients: 1,
                                        months: 1,
                                        goToTableView: (function (param) {
                                            return Curry._1(onChangeSlide, currentSlideToNumber(/* TotalCostPerPatientTable */5));
                                          })
                                      }));
                    case /* TotalCostPerPatientTable */5 :
                        return createSlideContent("", perPatientTitle)(React.createElement(IMBRUVICA_PresentationTotalCost$CTEM.make, {
                                        currency: work.currency,
                                        totalCostPerMonthPerPatient: totalCostPerMonthPerPatient,
                                        months: 1,
                                        patients: 1,
                                        goToChartView: (function (param) {
                                            return Curry._1(onChangeSlide, currentSlideToNumber(/* TotalCostPerPatientChart */4));
                                          })
                                      }));
                    
                  }
                })
            });
}

var make = IMBRUVICA_Presentation;

export {
  adverseEventMonths ,
  getTreatmentName ,
  slides ,
  findCurrentSlide ,
  currentSlideToNumber ,
  allSlides ,
  calAdverseEventSpecificMonths ,
  createTreatmentTotalCost ,
  createTotalCost ,
  perCohortTitle ,
  perPatientTitle ,
  createSlideContent ,
  make ,
  
}
/* allSlides Not a pure module */
