// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as InputComponent$CTEM from "./InputComponent.bs.js";

function FloatInput(Props) {
  var displayTypeOpt = Props.displayType;
  var value = Props.value;
  var onValueUpdate = Props.onValueUpdate;
  var onTextUpdate = Props.onTextUpdate;
  var currency = Props.currency;
  var className = Props.className;
  var labelOpt = Props.label;
  var placeholderOpt = Props.placeholder;
  var fullWidthOpt = Props.fullWidth;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var digitOpt = Props.digit;
  var errorOpt = Props.error;
  var labelAsShortNumberOpt = Props.labelAsShortNumber;
  var displayType = displayTypeOpt !== undefined ? displayTypeOpt : /* Input */0;
  var label = labelOpt !== undefined ? labelOpt : "";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var error = errorOpt !== undefined ? errorOpt : false;
  var labelAsShortNumber = labelAsShortNumberOpt !== undefined ? labelAsShortNumberOpt : false;
  var match = React.useState(function () {
        return Utils$CTEM.getFloatWithDigit(digit, value);
      });
  var setValueText = match[1];
  var valueText = match[0];
  var match$1 = React.useState(function () {
        return valueText;
      });
  var setValueLabelText = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setOnFocus = match$2[1];
  var isError = !Utils$CTEM.strIsFloat(valueText) || error;
  var updateValueLabelText = function (v) {
    return Curry._1(setValueLabelText, (function (param) {
                  if (labelAsShortNumber) {
                    return Utils$CTEM.shortNumberFromStringRoundedNumber(v);
                  } else {
                    return v;
                  }
                }));
  };
  var shouldUpdateValue = function (e) {
    var valueText = Utils$CTEM.getFloatWithDigit(digit, Utils$CTEM.getValueFromReactEventForm(e));
    Belt_Option.map(onValueUpdate, (function (f) {
            var valueFloat = Number(valueText);
            if (!isNaN(valueFloat)) {
              return Curry._1(f, valueFloat);
            }
            
          }));
    if (Utils$CTEM.strIsFloat(valueText)) {
      Curry._1(setValueText, (function (param) {
              return valueText;
            }));
    }
    return Curry._1(setOnFocus, (function (param) {
                  return false;
                }));
  };
  var handleTextChange = function (e) {
    var valueTextEvent = Utils$CTEM.getValueFromReactEventForm(e);
    Curry._1(setValueText, (function (param) {
            return valueTextEvent;
          }));
    Belt_Option.map(onTextUpdate, (function (f) {
            return Curry._1(f, valueTextEvent);
          }));
    
  };
  React.useEffect((function () {
          return (function (param) {
                    return Curry._1(setValueText, (function (param) {
                                  return Utils$CTEM.getFloatWithDigit(digit, value);
                                }));
                  });
        }), []);
  React.useEffect((function () {
          Curry._1(setValueText, (function (param) {
                  return Utils$CTEM.getFloatWithDigit(digit, value);
                }));
          
        }), [value]);
  React.useEffect((function () {
          updateValueLabelText(valueText);
          
        }), [valueText]);
  var tmp;
  if (displayType) {
    var expandValue = displayType._0;
    var valueFloat = Utils$CTEM.getFloatFromString(digit, valueText, value);
    var longNumber = Belt_List.some({
          hd: expandValue && valueFloat >= Math.pow(10, 21),
          tl: {
            hd: !expandValue && valueFloat >= Math.pow(10, 9),
            tl: /* [] */0
          }
        }, (function (b) {
            return b;
          }));
    tmp = React.createElement("div", {
          className: "value-text"
        }, longNumber ? Utils$CTEM.shortNumberFromStringRoundedNumber(valueFloat.toString()) : Utils$CTEM.Float.toLocaleStringUS(valueFloat));
  } else {
    var tmp$1 = {
      mode: /* ModifyData */1,
      label: label,
      placeholder: placeholder,
      error: isError,
      fullWidth: fullWidth,
      size: size,
      value: match$2[0] ? valueText : match$1[0],
      disabled: disabled,
      onChange: handleTextChange,
      onBlur: shouldUpdateValue,
      onFocus: (function (param) {
          return Curry._1(setOnFocus, (function (param) {
                        return true;
                      }));
        })
    };
    if (className !== undefined) {
      tmp$1.className = Caml_option.valFromOption(className);
    }
    tmp = React.createElement(InputComponent$CTEM.make, tmp$1);
  }
  return React.createElement(React.Fragment, {
              children: null
            }, tmp, currency !== undefined ? React.createElement("p", undefined, " " + Caml_option.valFromOption(currency)) : null);
}

var make = FloatInput;

export {
  make ,
  
}
/* react Not a pure module */
