// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as InputComponent$CTEM from "./InputComponent.bs.js";

function TextInput(Props) {
  var value = Props.value;
  var onValueUpdate = Props.onValueUpdate;
  var onTextUpdate = Props.onTextUpdate;
  var className = Props.className;
  var labelOpt = Props.label;
  var placeholderOpt = Props.placeholder;
  var fullWidthOpt = Props.fullWidth;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var errorOpt = Props.error;
  var label = labelOpt !== undefined ? labelOpt : "";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var error = errorOpt !== undefined ? errorOpt : false;
  var match = React.useState(function () {
        return value;
      });
  var setValueText = match[1];
  var shouldUpdateValue = function (e) {
    var newValueText = Utils$CTEM.getValueFromReactEventForm(e);
    Belt_Option.map(onValueUpdate, (function (f) {
            return Curry._1(f, newValueText);
          }));
    return Curry._1(setValueText, (function (param) {
                  return newValueText;
                }));
  };
  var handleTextChange = function (e) {
    var valueTextEvent = Utils$CTEM.getValueFromReactEventForm(e);
    Curry._1(setValueText, (function (param) {
            return valueTextEvent;
          }));
    Belt_Option.map(onTextUpdate, (function (f) {
            return Curry._1(f, valueTextEvent);
          }));
    
  };
  React.useEffect((function () {
          Curry._1(setValueText, (function (param) {
                  return value;
                }));
          
        }), [value]);
  var tmp = {
    mode: /* ModifyData */1,
    label: label,
    placeholder: placeholder,
    error: error,
    fullWidth: fullWidth,
    size: size,
    value: match[0],
    disabled: disabled,
    onChange: handleTextChange,
    onBlur: shouldUpdateValue
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(InputComponent$CTEM.make, tmp);
}

var make = TextInput;

export {
  make ,
  
}
/* react Not a pure module */
