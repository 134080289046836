// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apis$CTEM from "../../../../Effects/Apis.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Encoder$CTEM from "../../../../Effects/Encoder.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as SectionBox$CTEM from "../../SectionBox.bs.js";
import * as LAIInputForm$CTEM from "../../LAI/ManagementMenus/LAIInputForm.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as TemplateUtils$CTEM from "../../TemplateUtils.bs.js";
import Box from "@material-ui/core/Box";
import * as ModelInputHelper$CTEM from "../../../../Effects/ModelInputHelper.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";

function createFloatInputWithTitleEl(param) {
  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11) {
    return ModelInputHelper$CTEM.createFloatInputWithTitleEl(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11);
  };
  return function (param) {
    var func$1 = Curry._1(func, param);
    return function (param) {
      var func$2 = Curry._1(func$1, param);
      return function (param) {
        var func$3 = Curry._1(func$2, param);
        return function (param) {
          return Curry._2(func$3, param, "Other Medication Name");
        };
      };
    };
  };
}

var getTreatmentName = TemplateUtils$CTEM.Imbruvica.getTreatmentName;

function totalCost(preMed) {
  return TemplateUtils$CTEM.sumFloatValue({
              hd: preMed.fosaprepitant,
              tl: {
                hd: preMed.ondem,
                tl: {
                  hd: preMed.pegylated,
                  tl: {
                    hd: preMed.flucon,
                    tl: {
                      hd: preMed.dexamethasone,
                      tl: {
                        hd: preMed.others01,
                        tl: {
                          hd: preMed.others02,
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function IMBRUVICA_DirectMedical(Props) {
  var work = Props.work;
  var currentData = Props.currentData;
  var defaultData = Props.defaultData;
  var exchangeRate = Props.exchangeRate;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useState(function () {
        return /* NotLoaded */0;
      });
  var setApiState = match$1[1];
  var requestUpdateData = function (normalInputs) {
    Curry._1(setApiState, (function (param) {
            return /* Loading */1;
          }));
    return Apis$CTEM.requestUpdateSavedWorkNormalInput(work.id, normalInputs, (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadSuccess */2;
                              }));
                }), (function (json) {
                  var errMsg = RequestUtils$CTEM.getResponseMsgFromJson(json);
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: errMsg
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadFailed */{
                                        _0: errMsg
                                      };
                              }));
                }));
  };
  var inputDisabled = match$1[0] === /* Loading */1;
  var arg = work.currency.code;
  var arg$1 = work.currency.code;
  var arg$2 = exchangeRate;
  var createInputEl = function (param) {
    return function (param$1) {
      var func = function (param$2, param$3, param$4, param$5, param$6, param$7) {
        return ModelInputHelper$CTEM.createFloatInputEl(param, inputDisabled, param$1, arg, arg$1, param$2, param$3, param$4, param$5, param$6, param$7);
      };
      return function (param) {
        return Curry._2(func, param, arg$2);
      };
    };
  };
  var arg$3 = work.currency.code;
  var arg$4 = work.currency.code;
  var arg$5 = exchangeRate;
  var createInputWithTitleEl = function (param) {
    var func = createFloatInputWithTitleEl(param)(inputDisabled);
    return function (param) {
      var func$1 = Curry._1(func, param);
      return function (param) {
        var func$2 = Curry._3(func$1, param, arg$3, arg$4);
        return function (param) {
          return Curry._2(func$2, param, arg$5);
        };
      };
    };
  };
  return React.createElement(Box, {
              className: "height-100",
              alignItems: MaterialUIDataType.AlignItems.center,
              children: null
            }, React.createElement(SectionBox$CTEM.make, {
                  title: "Treatment Options",
                  subtitle: "Total drug costs per patient per month",
                  children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some($$Array.of_list(Belt_List.keepMap(Belt_List.sort(currentData.treatments, (function (a, b) {
                                                return Caml_primitive.caml_int_compare(a.id, b.id);
                                              })), (function (treatment) {
                                            return Belt_Option.map(Belt_List.getBy(defaultData.treatments, (function (defaultTreatment) {
                                                              return defaultTreatment.id === treatment.id;
                                                            })), (function (defaultTreatment) {
                                                          var onValueUpdate = function (value) {
                                                            return requestUpdateData({
                                                                        hd: Encoder$CTEM.Imbruvica.treatmentOptionCost(treatment.id, value),
                                                                        tl: /* [] */0
                                                                      });
                                                          };
                                                          var tmp;
                                                          if (treatment.id !== 4) {
                                                            tmp = Curry._5(createInputEl("")(onValueUpdate), undefined, undefined, treatment.treatmentName.value, defaultTreatment.cost, treatment.cost);
                                                          } else {
                                                            var onTitleUpdate = function (value) {
                                                              return requestUpdateData({
                                                                          hd: Encoder$CTEM.Imbruvica.treatmentOptionName(treatment.id, value),
                                                                          tl: /* [] */0
                                                                        });
                                                            };
                                                            tmp = React.createElement(LAIInputForm$CTEM.make, {
                                                                  inputMode: {
                                                                    TAG: /* FloatInputWithTitle */2,
                                                                    _0: treatment.cost.value
                                                                  },
                                                                  title: treatment.treatmentName.value,
                                                                  titlePlaceholder: "Treatment 4",
                                                                  unit: work.currency.code,
                                                                  inputDisabled: inputDisabled,
                                                                  onValueUpdate: onValueUpdate,
                                                                  onTitleUpdate: onTitleUpdate,
                                                                  onTitleRestore: (function (param) {
                                                                      return onTitleUpdate(defaultTreatment.treatmentName.value);
                                                                    }),
                                                                  infoTitleMode: treatment.treatmentName.value !== defaultTreatment.treatmentName.value ? /* Restore */0 : /* Infos */({
                                                                        _0: undefined
                                                                      }),
                                                                  infoMode: treatment.cost.value !== defaultTreatment.cost.value * exchangeRate ? /* Restore */0 : /* Infos */({
                                                                        _0: treatment.treatmentName.source
                                                                      }),
                                                                  onRestore: (function (param) {
                                                                      return onValueUpdate(defaultTreatment.cost.value * exchangeRate);
                                                                    })
                                                                });
                                                          }
                                                          return React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
                                                                      title: treatment.treatmentName.title,
                                                                      className: ModelInputHelper$CTEM.treatmentBgClassName(treatment),
                                                                      children: tmp,
                                                                      key: "imb-direct-treatment-option-" + (String(treatment.id) + ("-" + treatment.treatmentName.title))
                                                                    });
                                                        }));
                                          })))),
                            undefined,
                            undefined
                          ]))
                }), React.createElement("br", undefined), React.createElement(SectionBox$CTEM.make, {
                  title: "Pre-medication",
                  subtitle: "Costs of premedication/other medications per patient per month",
                  children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some($$Array.of_list(Belt_List.keepMap(Belt_List.sort(currentData.treatments, (function (a, b) {
                                                return Caml_primitive.caml_int_compare(a.id, b.id);
                                              })), (function (treatment) {
                                            return Belt_Option.map(Belt_List.getBy(defaultData.treatments, (function (defaultTreatment) {
                                                              return defaultTreatment.id === treatment.id;
                                                            })), (function (defaultTreatment) {
                                                          var preMedKey = function (med) {
                                                            return "imb-pre-med-" + (med + ("-" + String(treatment.id)));
                                                          };
                                                          var preMed = treatment.preMedication;
                                                          var defaultPreMed = defaultTreatment.preMedication;
                                                          var onValueUpdate = function (encoder, value) {
                                                            return requestUpdateData({
                                                                        hd: Curry._2(encoder, treatment.id, value),
                                                                        tl: /* [] */0
                                                                      });
                                                          };
                                                          return React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
                                                                      title: getTreatmentName(treatment),
                                                                      className: ModelInputHelper$CTEM.treatmentBgClassName(treatment),
                                                                      children: null,
                                                                      key: "imb-direct-pre-med-" + (String(treatment.id) + ("-" + treatment.treatmentName.title))
                                                                    }, Curry._5(createInputEl(preMedKey("fosaprepitant"))(function (param) {
                                                                              return onValueUpdate(Encoder$CTEM.Imbruvica.PreMed.fosaprepitant, param);
                                                                            }), undefined, undefined, undefined, defaultPreMed.fosaprepitant, preMed.fosaprepitant), Curry._5(createInputEl(preMedKey("ondem"))(function (param) {
                                                                              return onValueUpdate(Encoder$CTEM.Imbruvica.PreMed.ondem, param);
                                                                            }), undefined, undefined, undefined, defaultPreMed.ondem, preMed.ondem), Curry._5(createInputEl(preMedKey("pegylated"))(function (param) {
                                                                              return onValueUpdate(Encoder$CTEM.Imbruvica.PreMed.pegylated, param);
                                                                            }), undefined, undefined, undefined, defaultPreMed.pegylated, preMed.pegylated), Curry._5(createInputEl(preMedKey("flucon"))(function (param) {
                                                                              return onValueUpdate(Encoder$CTEM.Imbruvica.PreMed.flucon, param);
                                                                            }), undefined, undefined, undefined, defaultPreMed.flucon, preMed.flucon), Curry._5(createInputEl(preMedKey("dexamethasone"))(function (param) {
                                                                              return onValueUpdate(Encoder$CTEM.Imbruvica.PreMed.dexamethasone, param);
                                                                            }), undefined, undefined, undefined, defaultPreMed.dexamethasone, preMed.dexamethasone), Curry._5(createInputWithTitleEl(preMedKey("others01"))(function (param) {
                                                                              return onValueUpdate(Encoder$CTEM.Imbruvica.PreMed.others01, param);
                                                                            }), (function (param) {
                                                                            return requestUpdateData(Curry._1(Encoder$CTEM.Imbruvica.PreMed.titleOthers01, param));
                                                                          }), undefined, undefined, defaultPreMed.others01, preMed.others01), Curry._5(createInputWithTitleEl(preMedKey("others02"))(function (param) {
                                                                              return onValueUpdate(Encoder$CTEM.Imbruvica.PreMed.others02, param);
                                                                            }), (function (param) {
                                                                            return requestUpdateData(Curry._1(Encoder$CTEM.Imbruvica.PreMed.titleOthers02, param));
                                                                          }), undefined, undefined, defaultPreMed.others02, preMed.others02), React.createElement("br", undefined), ModelInputHelper$CTEM.createSumInputEl(preMedKey("cost-sum"), undefined, undefined, undefined, undefined, work.currency, totalCost(preMed)));
                                                        }));
                                          })))),
                            undefined,
                            undefined
                          ]))
                }));
}

var createFloatInputEl = ModelInputHelper$CTEM.createFloatInputEl;

var createSumInputEl = ModelInputHelper$CTEM.createSumInputEl;

var bgClassName = ModelInputHelper$CTEM.treatmentBgClassName;

var make = IMBRUVICA_DirectMedical;

export {
  createFloatInputEl ,
  createFloatInputWithTitleEl ,
  createSumInputEl ,
  bgClassName ,
  getTreatmentName ,
  totalCost ,
  make ,
  
}
/* react Not a pure module */
