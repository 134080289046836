// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apis$CTEM from "../../../../Effects/Apis.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Encoder$CTEM from "../../../../Effects/Encoder.bs.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as SectionBox$CTEM from "../../SectionBox.bs.js";
import * as LAIInputForm$CTEM from "../../LAI/ManagementMenus/LAIInputForm.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as OverviewChart$CTEM from "../../../OverviewChart.bs.js";
import Box from "@material-ui/core/Box";
import * as ModelInputHelper$CTEM from "../../../../Effects/ModelInputHelper.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";

function isTreatmentDurationError(duration) {
  if (duration > 6) {
    return true;
  } else {
    return duration <= 0;
  }
}

function innerChart(middleOpt, bottomOpt, top) {
  var middle = middleOpt !== undefined ? middleOpt : "Direct Cost";
  var bottom = bottomOpt !== undefined ? bottomOpt : "Indirect Cost";
  return {
          top: top,
          middle: middle,
          bottom: bottom
        };
}

var overviewChart_right = innerChart(undefined, undefined, "BR, FRC, and Other treatment");

var overviewChart_left = innerChart(undefined, undefined, "Ibrutinib");

var overviewChart_childrenBoxes = [[
    "Medical",
    "Other Medical",
    "Adverse Event"
  ]];

var overviewChart = {
  top: "Comparing Costs",
  bottom: "",
  right: overviewChart_right,
  left: overviewChart_left,
  childrenBoxes: overviewChart_childrenBoxes
};

function IMBRUVICA_Overview(Props) {
  var work = Props.work;
  var currentData = Props.currentData;
  var defaultData = Props.defaultData;
  var realtimeExchangeRate = Props.realtimeExchangeRate;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useState(function () {
        return /* NotLoaded */0;
      });
  var setApiState = match$1[1];
  var requestUpdateData = function (encoder, value) {
    Curry._1(setApiState, (function (param) {
            return /* Loading */1;
          }));
    return Apis$CTEM.requestUpdateSavedWorkNormalInput(work.id, {
                hd: Curry._1(encoder, value),
                tl: /* [] */0
              }, (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadSuccess */2;
                              }));
                }), (function (json) {
                  var errMsg = RequestUtils$CTEM.getResponseMsgFromJson(json);
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: errMsg
                      });
                  return Curry._1(setApiState, (function (param) {
                                return /* LoadFailed */{
                                        _0: errMsg
                                      };
                              }));
                }));
  };
  var inputDisabled = match$1[0] === /* Loading */1;
  var inputElements = React.createElement(React.Fragment, {
        children: null
      }, React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
            title: "",
            className: "bg-edit-form-1",
            children: null,
            key: "imb-chohort-input-currency"
          }, React.createElement(LAIInputForm$CTEM.make, {
                inputMode: {
                  TAG: /* TextInput */0,
                  _0: work.currency.code
                },
                title: "Currency",
                unit: "",
                inputDisabled: true,
                infoMode: /* Infos */{
                  _0: undefined
                },
                onRestore: (function (param) {
                    
                  })
              }), React.createElement(LAIInputForm$CTEM.make, {
                inputMode: {
                  TAG: /* FloatInput */1,
                  _0: realtimeExchangeRate
                },
                title: "Exchange Rate",
                unit: "",
                inputDisabled: true,
                infoMode: /* Infos */{
                  _0: undefined
                },
                onRestore: (function (param) {
                    
                  })
              })), React.createElement(SectionBox$CTEM.ChildBoxWithTitle.make, {
            title: "",
            className: "bg-edit-form-2",
            children: null,
            key: "imb-chohort-input"
          }, ModelInputHelper$CTEM.createIntInputEl("imb-overview-input-num-patients", inputDisabled, (function (param) {
                  return requestUpdateData(Encoder$CTEM.Imbruvica.numberOfPatients, param);
                }), undefined, undefined, defaultData.numberOfPatients, currentData.numberOfPatients), ModelInputHelper$CTEM.createIntInputEl("imb-overview-input-treatment-duration", true, (function (param) {
                  return requestUpdateData(Encoder$CTEM.Imbruvica.treatmentDurationMonths, param);
                }), "months", undefined, defaultData.treamentDurationMonths, currentData.treamentDurationMonths)));
  return React.createElement(Box, {
              className: "imb-model-overview height-100",
              alignItems: MaterialUIDataType.AlignItems.center,
              children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                        Caml_option.some(MaterialUIDataType.Justify.spaceBetween),
                        undefined,
                        Caml_option.some(MaterialUIDataType.AlignItems.center),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        Caml_option.some(null),
                        undefined,
                        undefined
                      ]), inputElements, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(OverviewChart$CTEM.make, {
                                      overviewChart: overviewChart
                                    })),
                            undefined,
                            undefined
                          ])))
            });
}

var treatmentDurationOptions = ModelInputHelper$CTEM.treatmentDurationOptions;

var make = IMBRUVICA_Overview;

export {
  treatmentDurationOptions ,
  isTreatmentDurationError ,
  innerChart ,
  overviewChart ,
  make ,
  
}
/* overviewChart Not a pure module */
