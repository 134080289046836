// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Json_decode from "@glennsl/bs-json/lib/es6/src/Json_decode.bs.js";
import * as TemplateUtils$CTEM from "./Components/ModelTemplates/TemplateUtils.bs.js";

var laiModel = {
  id: "0",
  modelType: /* LAI */0,
  name: "Economic Impact of Long-Acting Injectable Antipsychotics (LAI) in The Treatment of Schizophrenia",
  imageUrl: undefined,
  description: "Model description: This model is to illustrate overall changes in patients' outcomes when LAIs are introduced to the hospital in monetary value, compared to when there were only oral antipsychotics used in the hospital. The time horizon of this illustrati",
  countries: {
    hd: {
      name: "Thailand",
      code: "TH",
      currencies: {
        hd: {
          code: "THB",
          name: "Thai baht",
          symbol: "\xe0\xb8\xbf"
        },
        tl: /* [] */0
      }
    },
    tl: /* [] */0
  }
};

var mockupModels = {
  hd: laiModel,
  tl: /* [] */0
};

function optStringToString(defaultValueOpt, key, json) {
  var defaultValue = defaultValueOpt !== undefined ? defaultValueOpt : "";
  return Belt_Option.getWithDefault(Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                        return Json_decode.field(key, (function (param) {
                                      return Json_decode.optional(Json_decode.string, param);
                                    }), param);
                      }), json), undefined), defaultValue);
}

function midDecoder(param) {
  return Json_decode.field("mid", Json_decode.string, param);
}

function nameDecoder(param) {
  return optStringToString(undefined, "name", param);
}

function getKeys(param) {
  return Json_decode.map((function (prim) {
                return Object.keys(prim);
              }), (function (param) {
                return Json_decode.dict(Json_decode.id, param);
              }), param);
}

var decodeFromKey = Json_decode.field;

function userProfile(json) {
  return {
          token: Json_decode.field("token", Json_decode.string, json),
          created: Json_decode.field("created", Json_decode.string, json),
          username: Json_decode.field("username", Json_decode.string, json),
          first_name: Json_decode.field("first_name", Json_decode.string, json),
          last_name: Json_decode.field("last_name", Json_decode.string, json)
        };
}

function currency(json) {
  return {
          code: Json_decode.field("code", Json_decode.string, json),
          name: optStringToString(undefined, "name", json),
          symbol: Json_decode.field("symbol", Json_decode.string, json)
        };
}

function country(json) {
  return {
          name: optStringToString(undefined, "name", json),
          code: Json_decode.field("code", Json_decode.string, json),
          currencies: Json_decode.field("currencies", (function (param) {
                  return Json_decode.list(currency, param);
                }), json)
        };
}

function modelTemplate(json) {
  return {
          id: Json_decode.field("mid", Json_decode.string, json),
          modelType: TemplateUtils$CTEM.mapMIDStringToType(Json_decode.field("mid", Json_decode.string, json)),
          name: optStringToString(undefined, "name", json),
          imageUrl: undefined,
          description: optStringToString(undefined, "description", json),
          countries: Json_decode.field("countries", (function (param) {
                  return Json_decode.list(country, param);
                }), json)
        };
}

function modelTemplates(json) {
  return Json_decode.list(modelTemplate, json);
}

function objectDataString(json) {
  return {
          title: optStringToString(undefined, "name", json),
          value: Json_decode.field("value", Json_decode.string, json),
          source: Json_decode.withDefault("", (function (param) {
                  return Json_decode.field("source", Json_decode.string, param);
                }), json)
        };
}

function objectDataInt(json) {
  return {
          title: optStringToString(undefined, "name", json),
          value: Caml_format.caml_int_of_string(Json_decode.field("value", Json_decode.string, json)),
          source: Json_decode.withDefault("", (function (param) {
                  return Json_decode.field("source", Json_decode.string, param);
                }), json)
        };
}

function objectDataFloat(json) {
  return {
          title: optStringToString(undefined, "name", json),
          value: Caml_format.caml_float_of_string(Json_decode.field("value", Json_decode.string, json)),
          source: Json_decode.withDefault("", (function (param) {
                  return Json_decode.field("source", Json_decode.string, param);
                }), json)
        };
}

function drugType(drugName, json) {
  var match = Json_decode.field("drug_type", Json_decode.string, json).toLowerCase();
  if (match !== "lai") {
    return /* OralDrug */0;
  }
  var timePerYear = drugName.includes("pp1m") ? 12 : 4;
  return /* LaiDrug */{
          _0: timePerYear
        };
}

function drugMarketShare(json) {
  var drugName = optStringToString(undefined, "name", json);
  var drugType$1 = drugType(drugName.toLowerCase(), json);
  var msKey = Json_decode.field("key", Json_decode.string, json);
  return {
          id: 0,
          name: drugName,
          drugNameKey: Json_decode.field("drug_name_key", Json_decode.string, json),
          description: "",
          dailyDose: {
            title: "",
            value: 0,
            source: ""
          },
          costPerUnit: {
            title: "",
            value: 0,
            source: ""
          },
          marketShare: {
            oralKey: msKey,
            laiKey: msKey,
            withoutLAI: objectDataFloat(json),
            withLAI: objectDataFloat(json)
          },
          drugType: drugType$1
        };
}

function drug(json) {
  var drugName = Json_decode.field("drug_name", Json_decode.string, json);
  return {
          id: 0,
          name: drugName,
          drugNameKey: Json_decode.field("drug_name_key", Json_decode.string, json),
          description: Json_decode.field("desc", Json_decode.string, json),
          dailyDose: Json_decode.field("ddd", objectDataFloat, json),
          costPerUnit: Json_decode.field("price", objectDataFloat, json),
          marketShare: TemplateUtils$CTEM.drugMSDefault,
          drugType: drugType(drugName.toLowerCase(), json)
        };
}

function drugs(json) {
  return Json_decode.list(drug, json);
}

function laiData(json) {
  var drugsWithMarketShare = Json_decode.field("market_shares", (function (param) {
          return Json_decode.list(drugMarketShare, param);
        }), json);
  var getDrugMS = function (drugWithoutId, isOral) {
    var drugMSOral = List.find_opt((function (drugWithMS) {
            if (isOral ? drugWithMS.drugType === /* OralDrug */0 : drugWithMS.drugType !== /* OralDrug */0) {
              return drugWithoutId.drugNameKey === drugWithMS.drugNameKey;
            } else {
              return false;
            }
          }), drugsWithMarketShare);
    if (drugMSOral !== undefined) {
      return drugMSOral.marketShare;
    } else {
      return TemplateUtils$CTEM.drugMSDefault;
    }
  };
  var allDrugsWithId = List.mapi((function (index, drugWithoutId) {
          var drugMSOral = getDrugMS(drugWithoutId, true);
          var drugMSLAI = getDrugMS(drugWithoutId, false);
          var marketShare_oralKey = drugMSOral.oralKey;
          var marketShare_laiKey = drugMSLAI.laiKey;
          var marketShare_withoutLAI = drugMSOral.withoutLAI;
          var marketShare_withLAI = drugMSLAI.withLAI;
          var marketShare = {
            oralKey: marketShare_oralKey,
            laiKey: marketShare_laiKey,
            withoutLAI: marketShare_withoutLAI,
            withLAI: marketShare_withLAI
          };
          return {
                  id: index,
                  name: drugWithoutId.name,
                  drugNameKey: drugWithoutId.drugNameKey,
                  description: drugWithoutId.description,
                  dailyDose: drugWithoutId.dailyDose,
                  costPerUnit: drugWithoutId.costPerUnit,
                  marketShare: marketShare,
                  drugType: drugWithoutId.drugType
                };
        }), Json_decode.field("drugs_data", drugs, json));
  return {
          targetedSchizophrenia: Json_decode.field("normal_input_data", (function (param) {
                  return Json_decode.field("targeted_patients", objectDataInt, param);
                }), json),
          drugs: allDrugsWithId,
          marketShare: {
            lai: Json_decode.field("normal_input_data", (function (param) {
                    return Json_decode.field("lai_exp_usage", objectDataFloat, param);
                  }), json),
            oral: Json_decode.field("normal_input_data", (function (param) {
                    return Json_decode.field("oral_exp_usage", objectDataFloat, param);
                  }), json),
            pp1m: Json_decode.field("normal_input_data", (function (param) {
                    return Json_decode.field("lai_pp1m_exp_usage", objectDataFloat, param);
                  }), json),
            pp3m: Json_decode.field("normal_input_data", (function (param) {
                    return Json_decode.field("lai_pp3m_exp_usage", objectDataFloat, param);
                  }), json)
          },
          overallHealthcare: {
            oral: {
              hospitalizationRate: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_hospitalization_rate", objectDataFloat, param);
                    }), json),
              lengthOfStay: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_hospitalization_length_of_stay", objectDataFloat, param);
                    }), json),
              relapseRate: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_hospitalization_relapse_rate", objectDataFloat, param);
                    }), json),
              avgRelapseRate: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_avg_relapse_rate", objectDataFloat, param);
                    }), json)
            },
            lais: {
              hospitalizationRate: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_hospitalization_rate", objectDataFloat, param);
                    }), json),
              lengthOfStay: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_hospitalization_length_of_stay", objectDataFloat, param);
                    }), json),
              relapseRate: {
                title: "",
                value: 0,
                source: ""
              },
              avgRelapseRate: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_avg_relapse_rate", objectDataFloat, param);
                    }), json)
            }
          },
          indirectCosts: {
            otherMedicalCost: {
              hospitalizationCost: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("hospitalization_cost", objectDataFloat, param);
                    }), json),
              erAndPhysicianVisitCost: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("er_cost", objectDataFloat, param);
                    }), json),
              erAndPhysicianVisitPP1M: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_pp1m_total_physician_visit", objectDataInt, param);
                    }), json),
              erAndPhysicianVisitPP3M: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("pp3m_total_physician_visit", objectDataInt, param);
                    }), json),
              costPerAdministrationOfLAI: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("admin_cost_lai", objectDataFloat, param);
                    }), json)
            },
            indirectCostPatient: {
              unemploymentRate: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_unemployment_rate", objectDataFloat, param);
                    }), json),
              absentHours: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_absent_hours", objectDataFloat, param);
                    }), json),
              minimumDailyWage: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_min_daily_wage", objectDataFloat, param);
                    }), json),
              workingDaysPerMonth: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_working_days_per_month", objectDataInt, param);
                    }), json),
              improvementRateWithLAIs: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_unemployment_rate", objectDataFloat, param);
                    }), json),
              oralCostPerMonth: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_patient_indirect_cost_per_month", objectDataFloat, param);
                    }), json),
              laiCostPerMonth: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_patient_indirect_cost_per_month", objectDataFloat, param);
                    }), json)
            },
            indirectCostCaregiver: {
              workHour: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_caregiver_work_hours", objectDataInt, param);
                    }), json),
              improvementInProductivity: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_caregiver_work_hours", objectDataFloat, param);
                    }), json),
              oralCostPerMonth: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_caregiver_cost_per_month", objectDataFloat, param);
                    }), json),
              laiCostPerMonth: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_caregiver_cost_per_month", objectDataFloat, param);
                    }), json)
            },
            incarceration: {
              oralPatients: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("oral_incarcerated_patients_rate", objectDataFloat, param);
                    }), json),
              pp1mPatients: Json_decode.field("normal_input_data", (function (param) {
                      return Json_decode.field("lai_incarcerated_patients_rate", objectDataFloat, param);
                    }), json)
            }
          }
        };
}

function normalInputs(json, decoder, key) {
  return Json_decode.field("normal_input_data", (function (param) {
                return Json_decode.field(key, decoder, param);
              }), json);
}

function treatmentKey(id, key) {
  return "treatment_0" + (String(id) + ("_" + key));
}

function indirectCost(json, key) {
  return {
          patientReducedWorkdays: normalInputs(json, objectDataInt, Curry._1(key, "patient_reduced_workdays")),
          caregiverReducedWorkdays: normalInputs(json, objectDataInt, Curry._1(key, "caregiver_reduced_workdays"))
        };
}

function preMedication(json, key) {
  return {
          fosaprepitant: normalInputs(json, objectDataFloat, Curry._1(key, "med_fosaprepitant")),
          ondem: normalInputs(json, objectDataFloat, Curry._1(key, "med_ondem")),
          pegylated: normalInputs(json, objectDataFloat, Curry._1(key, "med_pegylated")),
          flucon: normalInputs(json, objectDataFloat, Curry._1(key, "med_flucon")),
          dexamethasone: normalInputs(json, objectDataFloat, Curry._1(key, "med_dexamethasone")),
          others01: normalInputs(json, objectDataFloat, Curry._1(key, "med_others_01")),
          others02: normalInputs(json, objectDataFloat, Curry._1(key, "med_others_02"))
        };
}

function nonMedication(json, key) {
  return {
          infusion: normalInputs(json, objectDataFloat, Curry._1(key, "nmed_infusion")),
          hospitalVisits: normalInputs(json, objectDataFloat, Curry._1(key, "nmed_hospital_visits")),
          transport: normalInputs(json, objectDataFloat, Curry._1(key, "nmed_transport")),
          accomodation: normalInputs(json, objectDataFloat, Curry._1(key, "nmed_accomodation")),
          labTest: normalInputs(json, objectDataFloat, Curry._1(key, "nmed_lab_tests_pre_post_infusion")),
          others01: normalInputs(json, objectDataFloat, Curry._1(key, "nmed_others_01")),
          others02: normalInputs(json, objectDataFloat, Curry._1(key, "nmed_others_02"))
        };
}

function adverseEventValue(json, prefixKey) {
  return {
          rate: normalInputs(json, objectDataFloat, prefixKey + "_rate"),
          cost: normalInputs(json, objectDataFloat, prefixKey + "_cost")
        };
}

function adverseEvent(json, key) {
  return {
          fatigue: adverseEventValue(json, Curry._1(key, "ae_fatigue")),
          anemia: adverseEventValue(json, Curry._1(key, "ae_anemia")),
          atrialFibrillation: adverseEventValue(json, Curry._1(key, "ae_atrial_fibrillation")),
          hypertension: adverseEventValue(json, Curry._1(key, "ae_hypertension")),
          lymphopenia: adverseEventValue(json, Curry._1(key, "ae_lymphopenia")),
          haematologicalToxicity: adverseEventValue(json, Curry._1(key, "ae_haematological_toxicity")),
          infections: adverseEventValue(json, Curry._1(key, "ae_infections")),
          infusionReactions: adverseEventValue(json, Curry._1(key, "ae_infusion_reactions")),
          leucocytopenia: adverseEventValue(json, Curry._1(key, "ae_leucocytopenia")),
          neutropenia: adverseEventValue(json, Curry._1(key, "ae_neutropenia")),
          pneumonia: adverseEventValue(json, Curry._1(key, "ae_pneumonia")),
          thrombocytopenia: adverseEventValue(json, Curry._1(key, "ae_thrombocytopenia")),
          others01: adverseEventValue(json, Curry._1(key, "ae_others_01")),
          others02: adverseEventValue(json, Curry._1(key, "ae_others_02"))
        };
}

function treatmentDataInput(json, id) {
  var key = function (param) {
    return treatmentKey(id, param);
  };
  return {
          id: id,
          treatmentName: normalInputs(json, objectDataString, treatmentKey(id, "name")),
          cost: normalInputs(json, objectDataFloat, treatmentKey(id, "treatment_cost")),
          indirectCost: indirectCost(json, key),
          preMedication: preMedication(json, key),
          nonMedication: nonMedication(json, key),
          adverseEvent: adverseEvent(json, key)
        };
}

function decoder(json) {
  return {
          numberOfPatients: normalInputs(json, objectDataInt, "number_patients"),
          treamentDurationMonths: normalInputs(json, objectDataInt, "treatment_duration_months"),
          patientMonthlyIncome: normalInputs(json, objectDataFloat, "patient_monthly_income"),
          caregiverMonthlyIncome: normalInputs(json, objectDataFloat, "caregiver_monthly_income"),
          treatments: Belt_List.makeBy(4, (function (id) {
                  return treatmentDataInput(json, id + 1 | 0);
                }))
        };
}

var Imbruvica = {
  normalInputs: normalInputs,
  treatmentKey: treatmentKey,
  indirectCost: indirectCost,
  preMedication: preMedication,
  nonMedication: nonMedication,
  adverseEventValue: adverseEventValue,
  adverseEvent: adverseEvent,
  treatmentDataInput: treatmentDataInput,
  decoder: decoder
};

function work(json) {
  var match = TemplateUtils$CTEM.mapMIDStringToType(Json_decode.field("mid", Json_decode.string, json));
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          mid: Json_decode.field("mid", Json_decode.string, json),
          country: Json_decode.field("country", Json_decode.string, json),
          countryName: Json_decode.field("country_name", Json_decode.string, json),
          fileName: Json_decode.withDefault("", (function (param) {
                  return Json_decode.field("save_name", Json_decode.string, param);
                }), json),
          modelName: optStringToString(undefined, "name", json),
          lastUpdate: new Date(Json_decode.field("updated_at", Json_decode.string, json)),
          currency: Json_decode.field("currency_data", currency, json),
          exchangeRate: Json_decode.optional((function (param) {
                  return Json_decode.field("exchange_rate", Json_decode.$$float, param);
                }), json),
          templateData: match ? ({
                TAG: /* TemplateIMBRUVICA */1,
                _0: decoder(json)
              }) : ({
                TAG: /* TemplateLAI */0,
                _0: laiData(json)
              })
        };
}

function works(json) {
  return Json_decode.list(work, json);
}

function currencyExchangeRate(json) {
  return {
          code: Json_decode.field("code", Json_decode.string, json),
          rate: Json_decode.field("rate", Json_decode.$$float, json)
        };
}

function decoder$1(json) {
  return {
          fetchedAt: new Date(),
          rates: Belt_Array.keepMap(getKeys(json), (function (key) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field(key, currencyExchangeRate, param);
                              }), json);
                }))
        };
}

var CurrencyExchangeRate = {
  currencyExchangeRate: currencyExchangeRate,
  decoder: decoder$1
};

var Decode = {
  optStringToString: optStringToString,
  midDecoder: midDecoder,
  nameDecoder: nameDecoder,
  getKeys: getKeys,
  decodeFromKey: decodeFromKey,
  userProfile: userProfile,
  currency: currency,
  country: country,
  modelTemplate: modelTemplate,
  modelTemplates: modelTemplates,
  objectDataString: objectDataString,
  objectDataInt: objectDataInt,
  objectDataFloat: objectDataFloat,
  drugType: drugType,
  drugMarketShare: drugMarketShare,
  drug: drug,
  drugs: drugs,
  laiData: laiData,
  Imbruvica: Imbruvica,
  work: work,
  works: works,
  CurrencyExchangeRate: CurrencyExchangeRate
};

var mockupCountries = {
  hd: "Thailand",
  tl: /* [] */0
};

export {
  laiModel ,
  mockupModels ,
  mockupCountries ,
  Decode ,
  
}
/* No side effect */
