// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Float from "bs-platform/lib/es6/belt_Float.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";

function getValueFromReactEventForm($$event) {
  return $$event.target.value;
}

function getIntFromString(stringValue, defaultValue) {
  try {
    return Caml_format.caml_int_of_string(stringValue);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Failure") {
      return defaultValue;
    }
    throw exn;
  }
}

function strIsFloat(str) {
  return !isNaN(Number(str));
}

function roundedFloat(digitOpt, f) {
  var digit = digitOpt !== undefined ? digitOpt : 1;
  var pow = Math.pow(10, digit);
  return Math.round(f * pow) / pow;
}

function roundedFloatToString(digitOpt, f) {
  var digit = digitOpt !== undefined ? digitOpt : 1;
  return String(roundedFloat(digit, f));
}

function roundedValueString(digit, stringNumber) {
  return Belt_Option.mapWithDefault(Belt_Float.fromString(stringNumber), stringNumber, (function (param) {
                return roundedFloatToString(digit, param);
              }));
}

function getFloatWithDigit(digitOpt, value) {
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var valueSplited = value.toString().split(".");
  var getZero = function (digit) {
    return Caml_array.caml_make_vect(digit, "0").join("");
  };
  var match = valueSplited.length;
  if (digit === 0) {
    return Caml_array.get(valueSplited, 0);
  }
  if (match <= 1) {
    if (match === 1) {
      return Caml_array.get(valueSplited, 0) + ("." + getZero(digit));
    } else {
      return "0." + getZero(digit);
    }
  }
  var length = Caml_array.get(valueSplited, 1).length;
  if (length >= digit) {
    return Caml_array.get(valueSplited, 0) + ("." + Caml_array.get(valueSplited, 1).slice(0, digit));
  } else if (length <= 0) {
    return Caml_array.get(valueSplited, 0) + ("." + getZero(digit));
  } else {
    return Caml_array.get(valueSplited, 0) + ("." + (Caml_array.get(valueSplited, 1) + getZero(digit - 1 | 0)));
  }
}

function getFloatWithDigitReturnFloat(digitOpt, value) {
  var digit = digitOpt !== undefined ? digitOpt : 2;
  return Belt_Option.getWithDefault(Belt_Float.fromString(getFloatWithDigit(digit, value)), value);
}

function shortCurrencyWithAlphabet(value, length) {
  var backNumber = function (valueSliced) {
    return "." + value.slice(valueSliced.length, valueSliced.length + 2 | 0);
  };
  var match;
  if (length >= 13) {
    var valueSliced = value.slice(0, length - 12 | 0);
    match = [
      valueSliced + backNumber(valueSliced),
      "T"
    ];
  } else if (length >= 10 && length < 13) {
    var valueSliced$1 = value.slice(0, length - 9 | 0);
    match = [
      valueSliced$1 + backNumber(valueSliced$1),
      "B"
    ];
  } else if (length >= 7 && length < 10) {
    var valueSliced$2 = value.slice(0, length - 6 | 0);
    match = [
      valueSliced$2 + backNumber(valueSliced$2),
      "M"
    ];
  } else if (length >= 4 && length < 7) {
    var valueSliced$3 = value.slice(0, length - 3 | 0);
    match = [
      valueSliced$3 + backNumber(valueSliced$3),
      "K"
    ];
  } else {
    match = [
      value,
      ""
    ];
  }
  return match[0] + match[1];
}

function shortCurrencyWithAlphabetRoundedNumber(value, length) {
  var backNumber = function (valueSliced) {
    return "." + value.slice(valueSliced.length, valueSliced.length + 2 | 0);
  };
  var finalValue = function (digit, unit, to_) {
    var valueSliced = value.slice(0, to_);
    return [
            roundedValueString(digit, valueSliced + backNumber(valueSliced)),
            unit
          ];
  };
  var match = length >= 13 ? finalValue(1, "T", length - 12 | 0) : (
      length >= 10 && length < 13 ? finalValue(1, "B", length - 9 | 0) : (
          length >= 7 && length < 10 ? finalValue(1, "M", length - 6 | 0) : (
              length >= 4 && length < 7 ? finalValue(0, "K", length - 3 | 0) : [
                  value,
                  ""
                ]
            )
        )
    );
  return match[0] + match[1];
}

function getFloatFromString(digitOpt, stringValue, defaultValue) {
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var value;
  try {
    value = Caml_format.caml_float_of_string(stringValue);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Failure") {
      return defaultValue;
    }
    throw exn;
  }
  return Caml_format.caml_float_of_string(getFloatWithDigit(digit, value));
}

function validStringFromStringCurrency(maxDigitOpt, value) {
  var maxDigit = maxDigitOpt !== undefined ? maxDigitOpt : 2;
  var valueSplited = value.split(".");
  var x = valueSplited.length;
  if (x > 1) {
    return Caml_array.get(valueSplited, 0) + ("." + Caml_array.get(valueSplited, 1).slice(0, Caml_array.get(valueSplited, 1).length > 2 ? Caml_array.get(valueSplited, 1).length : maxDigit));
  } else if (x === 1) {
    return Caml_array.get(valueSplited, 0);
  } else {
    return "";
  }
}

var getNumberFormat = (function(number) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(number).slice(1, formatter.format(number).length) + "";
  });

var getNumberFormat2 = (function(number, currencyCode) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
    });
    return formatter.format(number);
  });

var getNumberFormat3 = (function(number, currencyCode) {
    var formatter = new Intl.NumberFormat('en-US');
    return formatter.format(number);
  });

function isStringEmpty(str) {
  if (str.length <= 0) {
    return true;
  } else {
    return Belt_Option.isSome(Caml_option.null_to_opt(str.match(new RegExp("^\\W+$", "g"))));
  }
}

function shortNumberFromString(value) {
  var intNumber = value.split(".");
  var length = Caml_array.get(intNumber, 0).length;
  if (length < 4) {
    return value;
  }
  var intNumber$1 = Caml_array.get(intNumber, 0).split(".");
  return shortCurrencyWithAlphabet(Caml_array.get(intNumber$1, 0), length);
}

function shortNumberFromStringRoundedNumber(value) {
  var intNumber = value.split(".");
  var length = Caml_array.get(intNumber, 0).length;
  if (length >= 4) {
    return shortCurrencyWithAlphabetRoundedNumber(Caml_array.get(intNumber, 0), length);
  } else {
    return roundedValueString(undefined, value);
  }
}

var getKeysFromObject = (function(obj) {
    return Object.keys(obj);
  });

function loadDataIfNone(results, reload, handle) {
  if (typeof results === "number") {
    if (results === /* NotLoaded */0 || reload) {
      return Curry._1(handle, undefined);
    } else {
      console.log("Loading");
      return ;
    }
  } else if (results.TAG === /* LoadSuccess */0 && !reload) {
    console.log("Already loaded data");
    return ;
  } else {
    return Curry._1(handle, undefined);
  }
}

function textWithNewLineRaw(text) {
  var createEl = function (text, regex) {
    var arrayText = text.split(regex);
    return Belt_Array.mapWithIndex(arrayText, (function (index, t) {
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: "text-new-line-" + (String(index) + Caml_array.get(arrayText, 0))
                            }, t, React.createElement("br", undefined));
                }));
  };
  if (text.includes("\n")) {
    return createEl(text, "\n");
  } else if (text.includes("\\n")) {
    return createEl(text, "\\n");
  } else {
    return [text];
  }
}

var textWithNewLine = textWithNewLineRaw;

function ignoreTextNewLine(text) {
  var result = [""];
  var match = text.includes("\n");
  var match$1 = text.includes("\\n");
  if (match) {
    if (match$1) {
      return text;
    }
    var textLine = text.split("\n");
    List.map((function (t) {
            return Caml_array.set(result, 0, Caml_array.get(result, 0) + t);
          }), $$Array.to_list(textLine));
    return Caml_array.get(result, 0);
  }
  if (!match$1) {
    return text;
  }
  var textLine$1 = text.split("\\n");
  List.map((function (t) {
          return Caml_array.set(result, 0, Caml_array.get(result, 0) + t);
        }), $$Array.to_list(textLine$1));
  return Caml_array.get(result, 0);
}

var refreshPage = (function() {
    location.reload();
  });

function pluralWorldSimple(addTotalOpt, wordPlural, total, word) {
  var addTotal = addTotalOpt !== undefined ? addTotalOpt : true;
  var unit = total <= 1 ? word : Belt_Option.getWithDefault(wordPlural, word + "s");
  if (addTotal) {
    return String(total) + (" " + unit);
  } else {
    return unit;
  }
}

function diffInHours(date1, date2) {
  return (date1.valueOf() - date2.valueOf() | 0) / 3600000 | 0;
}

var $$Date = {
  diffInHours: diffInHours
};

function getRateTargetCurrencyByContryCode(rates, countryCode) {
  var currencyCode = countryCode.toLowerCase();
  if (currencyCode === "usd") {
    return 1;
  } else {
    return Belt_Option.map(Belt_Array.getBy(rates, (function (param) {
                      return param.code.toLowerCase() === currencyCode;
                    })), (function (param) {
                  return param.rate;
                }));
  }
}

function getRateTargetCurrencyByContryObject(rates, country) {
  return getRateTargetCurrencyByContryCode(rates, Belt_List.headExn(country.currencies).code);
}

function covertPriceWithRate(rate, price) {
  return rate * price;
}

function covertPriceWithRateWithDigit(digitOpt, rate, price) {
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var value = rate * price;
  return Belt_Option.getWithDefault(Belt_Float.fromString(getFloatWithDigit(digit, value)), value);
}

var Currency = {
  getRateTargetCurrencyByContryCode: getRateTargetCurrencyByContryCode,
  getRateTargetCurrencyByContryObject: getRateTargetCurrencyByContryObject,
  covertPriceWithRate: covertPriceWithRate,
  covertPriceWithRateWithDigit: covertPriceWithRateWithDigit
};

function toLocaleStringUS(number) {
  return number.toLocaleString("en-US");
}

var Float = {
  toLocaleStringUS: toLocaleStringUS
};

var defaultErrorMessage = "\nSomething went wrong. Please try the following steps.\n\n1. Check if you are connected to the internet, and refresh the page.\n\n2. Clear your browser cache, and refresh the page.\n\n3. If the problem still persists, please contact our system admin for further support.\n\n";

export {
  defaultErrorMessage ,
  getValueFromReactEventForm ,
  getIntFromString ,
  strIsFloat ,
  roundedFloat ,
  roundedFloatToString ,
  roundedValueString ,
  getFloatWithDigit ,
  getFloatWithDigitReturnFloat ,
  shortCurrencyWithAlphabet ,
  shortCurrencyWithAlphabetRoundedNumber ,
  getFloatFromString ,
  validStringFromStringCurrency ,
  getNumberFormat ,
  getNumberFormat2 ,
  getNumberFormat3 ,
  isStringEmpty ,
  shortNumberFromString ,
  shortNumberFromStringRoundedNumber ,
  getKeysFromObject ,
  loadDataIfNone ,
  textWithNewLineRaw ,
  textWithNewLine ,
  ignoreTextNewLine ,
  refreshPage ,
  pluralWorldSimple ,
  $$Date ,
  Currency ,
  Float ,
  
}
/* react Not a pure module */
