// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var path = "/sw.js";

function unregisterUnwantedServiceWorker(sw) {
  sw.getRegistrations().then(function (registrations) {
          return Promise.resolve(Belt_Array.forEach(registrations, (function (registration) {
                            Belt_Option.map(Caml_option.nullable_to_opt(registration.active), (function (regActive) {
                                    var regex = new RegExp("/sw.js$", "g");
                                    var swScriptUrl = regActive.scriptURL;
                                    if (swScriptUrl !== "" && Belt_Option.isNone(Caml_option.null_to_opt(swScriptUrl.match(regex)))) {
                                      registration.unregister().then(function (param) {
                                              return Promise.resolve((console.log(">>> [SW] unregister success: ", swScriptUrl), undefined));
                                            }).catch(function (err) {
                                            return Promise.resolve((console.log(">>> [SW] unrgister failed: ", swScriptUrl, " with err: ", err), undefined));
                                          });
                                      return ;
                                    }
                                    
                                  }));
                            
                          })));
        }).catch(function (err) {
        return Promise.resolve((console.log(">>> [SW] unre registration service worker error: ", err), undefined));
      });
  
}

function initServiceWorker(param) {
  var sw = self.navigator.serviceWorker;
  if (sw !== undefined) {
    Caml_option.valFromOption(sw).register(path).then(function (registration) {
            console.log(">>> [SW] registration.scope: ", registration.scope);
            return Promise.resolve((console.log(">>> [SW]  registration: ", registration), undefined));
          }).catch(function (err) {
          return Promise.resolve((console.log(">>> [SW] registration service worker error: ", err), undefined));
        });
  } else {
    console.log(">>> [SW] service worker cannot be found.");
  }
  
}

export {
  path ,
  unregisterUnwantedServiceWorker ,
  initServiceWorker ,
  
}
/* No side effect */
