// Generated by ReScript, PLEASE EDIT WITH CARE


var GroupMode = {
  grouped: "grouped",
  stacked: "stacked"
};

function defaultLegend(dataFrom) {
  return {
          dataFrom: dataFrom,
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 22,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 22,
          effects: []
        };
}

export {
  GroupMode ,
  defaultLegend ,
  
}
/* No side effect */
