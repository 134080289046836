// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Bar from "@nivo/bar";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Float from "bs-platform/lib/es6/belt_Float.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as Chart_Bar$CTEM from "../NivoCharts/Chart_Bar.bs.js";

var emptyVerticalChartData_icons = [];

var emptyVerticalChartData_bars = [];

var emptyVerticalChartData = {
  topic: "",
  subTitle: "",
  unitName: "",
  tooltipElement: undefined,
  icons: emptyVerticalChartData_icons,
  bars: emptyVerticalChartData_bars
};

function getBarDataValue(data) {
  return Belt_Option.getWithDefault(Belt_Float.fromString(data.value), 0);
}

function createBarData(barData) {
  var bars = [[
      "title",
      barData.title
    ]];
  var keys = [];
  var colors = [];
  var values = [];
  Belt_Array.forEach(barData.data, (function (data) {
          var stackedValue = getBarDataValue(data);
          bars.push([
                data.title,
                stackedValue
              ]);
          values.push(stackedValue);
          keys.push(data.title);
          colors.push(data.color);
          
        }));
  return [
          Js_dict.fromArray(bars),
          keys,
          colors,
          values
        ];
}

function createGroupedBar(verticalChartData) {
  var chartBars = [];
  var chartKeys = [];
  var chartColors = [];
  var chartValues = [];
  var totalValuePerBar = [];
  Belt_Array.forEachWithIndex(verticalChartData.bars, (function (idx, barData) {
          var match = createBarData(barData);
          var values = match[3];
          chartBars.push(match[0]);
          chartColors.push(match[2]);
          chartValues.push(Belt_Array.reduce(values, 0, (function (acc, v) {
                      return acc + v;
                    })));
          totalValuePerBar.push(values);
          if (idx === 1) {
            chartKeys.push(match[1]);
            return ;
          }
          
        }));
  return {
          bars: chartBars,
          keys: Belt_Array.concatMany(chartKeys),
          colors: Belt_Array.concatMany(chartColors),
          totalValue: chartValues,
          totalValuePerBar: Belt_Array.concatMany(totalValuePerBar)
        };
}

function createStackedBar(verticalChartData) {
  var chartBars = [];
  var chartKeys = [];
  var chartColors = [];
  var chartValues = [];
  Belt_Array.forEachWithIndex(verticalChartData.bars, (function (idx, barData) {
          var match = createBarData(barData);
          chartValues.push(Belt_Array.reduce(match[3], 0, (function (acc, v) {
                      return acc + v;
                    })));
          chartBars.push(match[0]);
          chartColors.push(match[2]);
          if (idx === 1) {
            chartKeys.push(match[1]);
            return ;
          }
          
        }));
  return {
          bars: chartBars,
          keys: Belt_Array.concatMany(chartKeys),
          colors: Belt_Array.concatMany(chartColors),
          totalValue: chartValues,
          totalValuePerBar: chartValues
        };
}

var getLabel = (function(data, maxValue) {
    return (data.value * 100 / maxValue).toFixed(2) + "%";
  });

function createCustomValueAxisX(idx, key, isGroupedBar, numberOfGroupedBar, bar, customXAxisType, showLegends, totalValues) {
  var wrapper = function (showTitleOpt, showTotalOpt, param) {
    var showTitle = showTitleOpt !== undefined ? showTitleOpt : true;
    var showTotal = showTotalOpt !== undefined ? showTotalOpt : true;
    var actualWidth = Math.imul(numberOfGroupedBar, bar.width);
    var sumValueTransform = "translate(" + (String(bar.x + (actualWidth / 2 | 0) | 0) + (", " + (String((bar.y + bar.height | 0) + 50 | 0) + ")")));
    var diff = typeof showLegends === "number" ? (
        showLegends !== 0 || isGroupedBar ? 5 : 20
      ) : (
        isGroupedBar ? 5 : 20
      );
    var textDiff = String(idx === 0 ? diff : -diff | 0);
    var textAnchor = isGroupedBar ? "middle" : (
        idx === 0 ? "end" : "start"
      );
    return React.createElement("g", {
                key: key,
                transform: sumValueTransform
              }, showTotal ? React.createElement("text", {
                      key: "total-text-" + String(idx),
                      className: "bar-value",
                      fill: "black",
                      textAnchor: bar.width <= 110 ? textAnchor : "middle",
                      x: bar.width <= 110 ? textDiff : "0",
                      y: "15"
                    }, Utils$CTEM.shortNumberFromStringRoundedNumber(String(Belt_Option.getWithDefault(Belt_Array.get(totalValues, idx), 0)))) : null, showTitle ? React.createElement(React.Fragment, {
                      children: Belt_Array.mapWithIndex(Utils$CTEM.textWithNewLineRaw(bar.data.indexValue), (function (titleIdx, titleEl) {
                              var y = -20 - Math.imul(-15, titleIdx) | 0;
                              return React.createElement("text", {
                                          key: "index-value-text-" + (String(idx) + ("-title-" + String(titleIdx))),
                                          className: "bar-index-value",
                                          fill: "black",
                                          textAnchor: textAnchor,
                                          x: textDiff,
                                          y: String(y)
                                        }, titleEl);
                            }))
                    }) : null);
  };
  switch (customXAxisType) {
    case /* Hide */0 :
        return null;
    case /* Show */1 :
        return wrapper(undefined, undefined, undefined);
    case /* ShowOnlyTitle */2 :
        return wrapper(undefined, false, undefined);
    case /* ShowOnlyTotal */3 :
        return wrapper(false, undefined, undefined);
    
  }
}

function StackedBarChartCommon(Props) {
  var verticalChartData = Props.verticalChartData;
  var classNameOpt = Props.className;
  var layoutOpt = Props.layout;
  var groupMode = Props.groupMode;
  var colorByOpt = Props.colorBy;
  var enableLabelOpt = Props.enableLabel;
  var enableGridYOpt = Props.enableGridY;
  var unitGridYValueOpt = Props.unitGridYValue;
  var customXAxisTypeOpt = Props.customXAxisType;
  var showLegendsOpt = Props.showLegends;
  var showBarLabelOpt = Props.showBarLabel;
  var borderRadiusOpt = Props.borderRadius;
  var heightOpt = Props.height;
  var width = Props.width;
  var paddingOpt = Props.padding;
  var marginOpt = Props.margin;
  var maxValueOutsideOpt = Props.maxValueOutside;
  var isInteractiveOpt = Props.isInteractive;
  var onClick = Props.onClick;
  var className = classNameOpt !== undefined ? classNameOpt : "stacked-bar-chart";
  var layout = layoutOpt !== undefined ? layoutOpt : "horizontal";
  var colorBy = colorByOpt !== undefined ? colorByOpt : "index";
  var enableLabel = enableLabelOpt !== undefined ? enableLabelOpt : false;
  var enableGridY = enableGridYOpt !== undefined ? enableGridYOpt : false;
  var unitGridYValue = unitGridYValueOpt !== undefined ? unitGridYValueOpt : "";
  var customXAxisType = customXAxisTypeOpt !== undefined ? customXAxisTypeOpt : /* Show */1;
  var showLegends = showLegendsOpt !== undefined ? showLegendsOpt : /* InVisible */1;
  var showBarLabel = showBarLabelOpt !== undefined ? showBarLabelOpt : true;
  var borderRadius = borderRadiusOpt !== undefined ? borderRadiusOpt : "0px";
  var height = heightOpt !== undefined ? heightOpt : "200px";
  var padding = paddingOpt !== undefined ? paddingOpt : 0.5;
  var margin = marginOpt !== undefined ? Caml_option.valFromOption(marginOpt) : ({
        top: 5,
        right: 70,
        bottom: 80,
        left: 0
      });
  var maxValueOutside = maxValueOutsideOpt !== undefined ? maxValueOutsideOpt : 0;
  var isInteractive = isInteractiveOpt !== undefined ? isInteractiveOpt : true;
  var isGroupedBar = groupMode === Caml_option.some(Chart_Bar$CTEM.GroupMode.grouped);
  var rawVerticalData = (
      isGroupedBar ? createGroupedBar : createStackedBar
    )(Belt_Option.getWithDefault(verticalChartData, emptyVerticalChartData));
  var mulipleBarVerticalLayer = function (datas) {
    var numberOfBar = Belt_Option.mapWithDefault(verticalChartData, 0, (function (verticalChart) {
            return verticalChart.bars.length;
          }));
    var numberOfGroupedBar = isGroupedBar ? Caml_int32.div(datas.bars.length, numberOfBar) : 1;
    return Belt_Array.mapWithIndex(datas.bars, (function (index, bar) {
                  var tmp;
                  var exit = 0;
                  var idx;
                  if (typeof showLegends === "number") {
                    if (showLegends !== 0) {
                      tmp = null;
                    } else {
                      idx = index;
                      exit = 1;
                    }
                  } else if (showLegends._0) {
                    idx = index;
                    exit = 1;
                  } else {
                    tmp = null;
                  }
                  if (exit === 1) {
                    if (Caml_int32.mod_(idx, numberOfBar) === 0) {
                      var legendHeightDistance = datas.height * 0.115 | 0;
                      var legendWidthDistance = datas.width | 0;
                      var rectSize = datas.height * 0.07 | 0;
                      var legendY = Caml_array.get(datas.bars, 0).y + Caml_array.get(datas.bars, 0).height | 0;
                      var posY = legendY + Math.imul(-Caml_int32.div(idx, numberOfBar) | 0, legendHeightDistance) | 0;
                      tmp = React.createElement(React.Fragment, {
                            children: null,
                            key: "tooltip-vertical-" + String(index)
                          }, React.createElement("rect", {
                                height: String(rectSize),
                                width: String(rectSize),
                                fill: bar.color,
                                x: String(15 + legendWidthDistance | 0),
                                y: String(posY - rectSize | 0)
                              }), Belt_Array.mapWithIndex(Utils$CTEM.textWithNewLineRaw(bar.data.id), (function (legendIdx, legend) {
                                  var y = ((posY - (rectSize / 2 | 0) | 0) + 4 | 0) - Math.imul(-15, legendIdx) | 0;
                                  return React.createElement("text", {
                                              key: "legend-text-" + String(legendIdx),
                                              className: "legend-text",
                                              x: String((30 + legendWidthDistance | 0) + rectSize | 0),
                                              y: String(y)
                                            }, React.createElement("tspan", {
                                                  fill: bar.color
                                                }, legend));
                                })));
                    } else {
                      tmp = null;
                    }
                  }
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: "stacked-bar-common-tooltip-vertical-" + String(index)
                            }, tmp, index < numberOfBar ? createCustomValueAxisX(index, "custom-v-x-" + String(index), isGroupedBar, numberOfGroupedBar, bar, customXAxisType, showLegends, rawVerticalData.totalValue) : null);
                }));
  };
  var singleBarVerticalLayer = function (datas) {
    return Belt_Array.mapWithIndex(datas.bars, (function (index, bar) {
                  var x = String(bar.x);
                  var y = String(bar.y);
                  var transform = "translate(" + (x + (", " + (y + ")")));
                  var isLeft = index % 2 === 0;
                  var startX = isLeft ? -100 : bar.width;
                  var textAnchor = isLeft ? "end" : "start";
                  var textX = isLeft ? -5 : bar.width + 5 | 0;
                  return React.createElement("g", {
                              key: "tooltip-vertical-" + String(index),
                              transform: transform
                            }, React.createElement("line", {
                                  style: {
                                    stroke: bar.color,
                                    strokeWidth: "2px"
                                  },
                                  x1: String(startX),
                                  x2: isLeft ? "0" : String(bar.width + 100 | 0),
                                  y1: String(bar.height),
                                  y2: String(bar.height)
                                }), React.createElement("text", {
                                  style: {
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                  },
                                  fill: bar.color,
                                  textAnchor: textAnchor,
                                  x: String(textX),
                                  y: String(bar.height - 30 | 0)
                                }, bar.data.id), React.createElement("text", {
                                  style: {
                                    fontSize: "14px",
                                    fontWeight: "bold"
                                  },
                                  fill: bar.color,
                                  textAnchor: textAnchor,
                                  x: String(textX),
                                  y: String(bar.height - 10 | 0)
                                }, Utils$CTEM.shortNumberFromStringRoundedNumber(bar.data.value.toString())));
                }));
  };
  var isSigleBar = Belt_Option.mapWithDefault(verticalChartData, false, (function (param) {
          return param.bars.length === 0;
        }));
  var layers = isSigleBar ? [
      "",
      "",
      "bars",
      singleBarVerticalLayer
    ] : (
      enableGridY ? [
          "grid",
          "axes",
          "bars",
          mulipleBarVerticalLayer
        ] : [
          "",
          "",
          "bars",
          mulipleBarVerticalLayer
        ]
    );
  var maxValue = maxValueOutside === 0 ? Belt_Option.getWithDefault(Belt_List.head(Belt_List.sort($$Array.to_list(rawVerticalData.totalValuePerBar), (function (a, b) {
                    return Caml_primitive.caml_float_compare(b, a);
                  }))), 0) : maxValueOutside;
  var tmp = {
    className: className,
    style: {
      height: height,
      userSelect: "none"
    }
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  var tmp$1 = {
    data: rawVerticalData.bars,
    keys: rawVerticalData.keys,
    layout: layout,
    maxValue: maxValue,
    valueScale: {
      type: "linear"
    },
    indexScale: {
      type: "band",
      round: true
    },
    margin: margin,
    padding: padding,
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    axisLeft: {
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendPosition: "middle",
      legendOffset: 0,
      format: (function (data) {
          return unitGridYValue + (" " + Utils$CTEM.shortNumberFromStringRoundedNumber(String(data)));
        })
    },
    enableGridY: enableGridY,
    gridXValues: false,
    layers: layers,
    enableLabel: enableLabel,
    label: (function (data) {
        if (showBarLabel) {
          return getLabel(data, maxValue);
        }
        
      }),
    labelTextColor: "#ffffff",
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    borderRadius: borderRadius,
    isInteractive: isInteractive,
    tooltipFormat: (function (value) {
        return Utils$CTEM.Float.toLocaleStringUS(value);
      }),
    legends: [Chart_Bar$CTEM.defaultLegend("keys")],
    animate: false,
    motionStiffness: 90,
    motionDamping: 15,
    indexBy: "title",
    colors: rawVerticalData.colors,
    borderColor: {
      from: "color",
      modifiers: [[
          "darker",
          1.6
        ]]
    },
    colorBy: colorBy
  };
  if (groupMode !== undefined) {
    tmp$1.groupMode = Caml_option.valFromOption(groupMode);
  }
  if (width !== undefined) {
    tmp$1.width = Caml_option.valFromOption(width);
  }
  return React.createElement("div", tmp, React.createElement(Bar.ResponsiveBar, tmp$1), verticalChartData !== undefined ? React.createElement(React.Fragment, {
                    children: null
                  }, React.createElement("h2", {
                        style: {
                          display: "inline-block"
                        }
                      }, Utils$CTEM.textWithNewLine(verticalChartData.topic)), React.createElement("p", undefined, React.createElement("i", {
                            className: "sub-title"
                          }, verticalChartData.subTitle)), verticalChartData.unitName !== "" ? React.createElement("p", {
                          style: {
                            fontSize: "12px",
                            marginLeft: "5px"
                          }
                        }, React.createElement("i", undefined, "(" + (verticalChartData.unitName + ")"))) : null) : null);
}

var legendColors = {
  hd: "#EA7E21",
  tl: {
    hd: "#2380B4",
    tl: {
      hd: "#6DBDEB",
      tl: {
        hd: "#144F70",
        tl: /* [] */0
      }
    }
  }
};

var make = StackedBarChartCommon;

export {
  emptyVerticalChartData ,
  legendColors ,
  getBarDataValue ,
  createBarData ,
  createGroupedBar ,
  createStackedBar ,
  getLabel ,
  createCustomValueAxisX ,
  make ,
  
}
/* react Not a pure module */
