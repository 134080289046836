// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Chart_Bar$CTEM from "../../../../NivoCharts/Chart_Bar.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as SectionBox$CTEM from "../../SectionBox.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as TemplateUtils$CTEM from "../../TemplateUtils.bs.js";
import Box from "@material-ui/core/Box";
import * as ModelInputHelper$CTEM from "../../../../Effects/ModelInputHelper.bs.js";
import Grid from "@material-ui/core/Grid";
import * as StackedBarChartCommon$CTEM from "../../../StackedBarChartCommon.bs.js";
import Equalizer from "@material-ui/icons/Equalizer";
import TableChart from "@material-ui/icons/TableChart";

function generateDataCostToStackedBarDataGroupByTreatment(cal, idx, param) {
  return {
          title: param.title,
          value: String(Curry._1(cal, param.value)),
          unitName: "",
          color: Belt_Option.getWithDefault(Belt_List.get(StackedBarChartCommon$CTEM.legendColors, idx), Belt_List.headExn(StackedBarChartCommon$CTEM.legendColors))
        };
}

function costToStackedBarDataGroupByTreatment(cal, t) {
  return Belt_Array.mapWithIndex([
              t.medical,
              t.nonMedical,
              t.adverseEvent,
              t.indirect
            ], (function (idx, cost) {
                return generateDataCostToStackedBarDataGroupByTreatment(cal, idx, cost);
              }));
}

function costToStackedBarDataGroupByCost(topic, cal, treatments) {
  return $$Array.of_list(Belt_List.map(treatments, (function (t) {
                    var value;
                    switch (topic) {
                      case /* ModelOverview */0 :
                          value = {
                            title: "",
                            value: 0,
                            source: ""
                          };
                          break;
                      case /* DirectMedical */1 :
                          value = t.medical;
                          break;
                      case /* DirectNonMedical */2 :
                          value = t.nonMedical;
                          break;
                      case /* DirectAdverseEvent */3 :
                          value = t.adverseEvent;
                          break;
                      case /* IndirectCosts */4 :
                          value = t.indirect;
                          break;
                      
                    }
                    return {
                            title: t.treatmentName,
                            value: String(Curry._1(cal, value.value)),
                            unitName: "",
                            color: Belt_Option.getWithDefault(Belt_List.get(TemplateUtils$CTEM.Imbruvica.Presentation.colors, t.id - 1 | 0), Belt_List.headExn(StackedBarChartCommon$CTEM.legendColors))
                          };
                  })));
}

function calculateValue(targetMonths, targetPatients, value) {
  return Utils$CTEM.getFloatWithDigitReturnFloat(0, value * targetPatients * targetMonths);
}

function createBarDataGroupByCost(months, patients, totalCost) {
  return Belt_Array.map([
              /* DirectMedical */1,
              /* DirectNonMedical */2,
              /* DirectAdverseEvent */3,
              /* IndirectCosts */4
            ], (function (topic) {
                var title;
                switch (topic) {
                  case /* ModelOverview */0 :
                      title = "";
                      break;
                  case /* DirectMedical */1 :
                      title = "Medical cost";
                      break;
                  case /* DirectNonMedical */2 :
                      title = "Other Direct Medical\nand Non-Medical cost";
                      break;
                  case /* DirectAdverseEvent */3 :
                      title = "Adverse Events cost";
                      break;
                  case /* IndirectCosts */4 :
                      title = "Indirect cost";
                      break;
                  
                }
                return {
                        title: title,
                        data: costToStackedBarDataGroupByCost(topic, (function (param) {
                                return calculateValue(months, patients, param);
                              }), totalCost.treatments)
                      };
              }));
}

function createBarDataGroupByTreatment(months, patients, totalCost) {
  return Belt_Array.map($$Array.of_list(totalCost.treatments), (function (treatment) {
                return {
                        title: treatment.treatmentName,
                        data: costToStackedBarDataGroupByTreatment((function (param) {
                                return calculateValue(months, patients, param);
                              }), treatment)
                      };
              }));
}

function getSubTitle(patients, months) {
  if (patients === 1 && months === 1) {
    return "Total costs for 1 patient in 1 month";
  }
  return "Total costs for a cohort of " + (Utils$CTEM.pluralWorldSimple(undefined, undefined, patients, "patient") + (" in " + Utils$CTEM.pluralWorldSimple(undefined, undefined, months, "month")));
}

function viewAsOtherViewType(illustration, onClick) {
  var match = illustration ? [
      "Chart",
      React.createElement(Equalizer, {})
    ] : [
      "Table",
      React.createElement(TableChart, {})
    ];
  return React.createElement("div", {
              className: "view-as-other-view-type",
              onClick: onClick
            }, match[1], React.createElement("u", undefined, "(View as a " + (match[0] + ")")));
}

function IMBRUVICA_PresentationTotalCostChart(Props) {
  var currency = Props.currency;
  var totalCostPerMonthPerPatient = Props.totalCostPerMonthPerPatient;
  var patients = Props.patients;
  var monthsOpt = Props.months;
  var titleOpt = Props.title;
  var chartOpt = Props.chart;
  var showBarLabelOpt = Props.showBarLabel;
  var goToTableView = Props.goToTableView;
  var months = monthsOpt !== undefined ? monthsOpt : 6;
  var title = titleOpt !== undefined ? titleOpt : "";
  var chart = chartOpt !== undefined ? chartOpt : /* Treatment */0;
  var showBarLabel = showBarLabelOpt !== undefined ? showBarLabelOpt : true;
  var subtitle = getSubTitle(patients, months);
  var match = chart ? [
      Chart_Bar$CTEM.GroupMode.grouped,
      0.2,
      createBarDataGroupByCost(months, patients, totalCostPerMonthPerPatient)
    ] : [
      Chart_Bar$CTEM.GroupMode.stacked,
      undefined,
      createBarDataGroupByTreatment(months, patients, totalCostPerMonthPerPatient)
    ];
  var totalCostVerticalChartData_icons = [];
  var totalCostVerticalChartData_bars = match[2];
  var totalCostVerticalChartData = {
    topic: "",
    subTitle: "",
    unitName: "",
    tooltipElement: undefined,
    icons: totalCostVerticalChartData_icons,
    bars: totalCostVerticalChartData_bars
  };
  var tmp = {
    verticalChartData: totalCostVerticalChartData,
    className: " single-bar-chart",
    layout: "vertical",
    groupMode: match[0],
    colorBy: "id",
    enableLabel: true,
    enableGridY: true,
    unitGridYValue: currency.code,
    customXAxisType: chart === /* Cost */1 ? /* ShowOnlyTitle */2 : /* Show */1,
    showLegends: /* Visible */0,
    showBarLabel: showBarLabel,
    height: "100%",
    margin: {
      top: 30,
      right: 70,
      bottom: 70,
      left: 30
    }
  };
  var tmp$1 = match[1];
  if (tmp$1 !== undefined) {
    tmp.padding = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Box, {
              className: "height-100",
              alignItems: MaterialUIDataType.AlignItems.center,
              children: React.createElement(SectionBox$CTEM.make, {
                    title: title,
                    subtitle: subtitle,
                    className: "lai-cost-breakdown text-center",
                    wrapperChildrenclassName: "relative",
                    children: null
                  }, goToTableView !== undefined ? viewAsOtherViewType(/* Table */0, goToTableView) : null, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            "lai-cost-breakdown",
                            Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                          undefined,
                                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                          undefined,
                                          undefined,
                                          undefined,
                                          "cost-breakdown-col",
                                          Caml_option.some(React.createElement("div", {
                                                    style: {
                                                      height: "90%",
                                                      width: "100%"
                                                    }
                                                  }, React.createElement(StackedBarChartCommon$CTEM.make, tmp))),
                                          undefined,
                                          undefined
                                        ]))),
                            undefined,
                            undefined
                          ])))
            });
}

var bgClassName = ModelInputHelper$CTEM.treatmentBgClassNameRaw;

var make = IMBRUVICA_PresentationTotalCostChart;

export {
  bgClassName ,
  generateDataCostToStackedBarDataGroupByTreatment ,
  costToStackedBarDataGroupByTreatment ,
  costToStackedBarDataGroupByCost ,
  calculateValue ,
  createBarDataGroupByCost ,
  createBarDataGroupByTreatment ,
  getSubTitle ,
  viewAsOtherViewType ,
  make ,
  
}
/* react Not a pure module */
