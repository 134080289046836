// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Section$CTEM from "../Section.bs.js";
import * as UrlPath$CTEM from "../../UrlPath.bs.js";
import * as CtemIcon$CTEM from "../CtemIcon.bs.js";
import * as BottomBar$CTEM from "../BottomBar.bs.js";
import * as ModelHeader$CTEM from "./ModelHeader.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as TemplateUtils$CTEM from "./TemplateUtils.bs.js";

function topicElement(title, subTitle, showLogo) {
  return React.createElement("div", {
              className: "topic-slide"
            }, showLogo ? React.createElement(CtemIcon$CTEM.make, {
                    iconType: /* LogoShort */38,
                    className: "mini-logo"
                  }) : null, React.createElement("h1", undefined, title), subTitle !== undefined ? React.createElement("p", undefined, subTitle) : null);
}

function slideContent(subTitle, title, contentElement, padding) {
  var tmp = {
    title: title
  };
  if (subTitle !== undefined) {
    tmp.subTitle = Caml_option.valFromOption(subTitle);
  }
  return React.createElement("div", {
              className: "slide-content"
            }, React.createElement("div", {
                  className: "title"
                }, React.createElement(ModelHeader$CTEM.make, tmp)), React.createElement("div", {
                  className: "content",
                  style: {
                    padding: padding
                  }
                }, contentElement));
}

function PresentationTemplate(Props) {
  var work = Props.work;
  var allSlides = Props.allSlides;
  var initSlide = Props.initSlide;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useReducer((function (state, action) {
          if (action.TAG === /* OnChangeSlide */0) {
            return {
                    allSlides: state.allSlides,
                    currentSlide: action._0,
                    startX: state.startX
                  };
          } else {
            return {
                    allSlides: state.allSlides,
                    currentSlide: state.currentSlide,
                    startX: action._0
                  };
          }
        }), {
        allSlides: allSlides,
        currentSlide: initSlide,
        startX: 0
      });
  var dispatch = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "template-container template-lai " + className,
              onTouchEnd: (function (touchEvent) {
                  var nextSlide = TemplateUtils$CTEM.slideController(false, state.currentSlide, state.startX, touchEvent);
                  Curry._1(dispatch, {
                        TAG: /* OnSetTouchStart */1,
                        _0: 0
                      });
                  if (nextSlide < List.length(state.allSlides)) {
                    return Curry._1(dispatch, {
                                TAG: /* OnChangeSlide */0,
                                _0: nextSlide
                              });
                  }
                  
                }),
              onTouchStart: (function (touchEvent) {
                  var startX = TemplateUtils$CTEM.slideController(false, state.currentSlide, state.startX, touchEvent);
                  if (startX >= 0) {
                    return Curry._1(dispatch, {
                                TAG: /* OnSetTouchStart */1,
                                _0: startX
                              });
                  }
                  
                })
            }, $$Array.of_list(Belt_List.mapWithIndex(state.allSlides, (function (idx, slide) {
                        return React.createElement(Section$CTEM.make, {
                                    className: state.currentSlide === slide ? "slide-fade" : "slide-disable",
                                    padding: "0px",
                                    children: Curry._2(children, slide, (function (nextSlide) {
                                            return Curry._1(dispatch, {
                                                        TAG: /* OnChangeSlide */0,
                                                        _0: nextSlide
                                                      });
                                          })),
                                    key: "template-section-" + String(idx)
                                  });
                      }))), React.createElement(BottomBar$CTEM.make, {
                  currentSlide: state.currentSlide,
                  lastSlide: List.length(state.allSlides),
                  goToEditPage: (function (param) {
                      return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath({
                                      TAG: /* EditWork */2,
                                      _0: TemplateUtils$CTEM.mapMIDStringToType(work.mid),
                                      _1: String(work.id)
                                    }));
                    }),
                  goToHomePage: (function (param) {
                      return ReasonReactRouter.push(UrlPath$CTEM.Route.getMemberPath({
                                      TAG: /* Dashboard */0,
                                      _0: TemplateUtils$CTEM.mapMIDStringToType(work.mid)
                                    }));
                    }),
                  onNextSlide: (function (nextSlide) {
                      return Curry._1(dispatch, {
                                  TAG: /* OnChangeSlide */0,
                                  _0: nextSlide
                                });
                    })
                }));
}

var make = PresentationTemplate;

export {
  topicElement ,
  slideContent ,
  make ,
  
}
/* react Not a pure module */
